// src/components/SearchBar/SearchBar.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { FaCrosshairs, FaLayerGroup, FaTag, FaSearch, FaCamera } from 'react-icons/fa';
import styles from './SearchBar.module.css';

const SearchBar = ({
  searchInput,
  setSearchInput,
  handleSearch,
  toggleLabels,
  toggleSatelliteView,
  focusOnUserLocation,
  toggleTilt, // Novo botão de inclinação
  labelsVisible,
  tiltEnabled,
  mapType,
}) => {
  return (
    <div className={styles.searchContainer}>
      <FaSearch className={styles.searchIcon} />
      <input
        type="text"
        placeholder="Buscar pelo ID do Poste ou Dispositivo"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className={styles.searchInput}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch();
          }
        }}
      />
      <div className={styles.buttonsContainer}>
        <button onClick={handleSearch} className={styles.iconButton} aria-label="Buscar">
          <FaSearch />
        </button>
        <button
          onClick={toggleLabels}
          className={styles.iconButton}
          aria-label="Alternar Nomes dos Postes"
          style={{ color: labelsVisible ? '#000' : '#555' }}
        >
          <FaTag />
        </button>
        <button
          onClick={toggleSatelliteView}
          className={styles.iconButton}
          aria-label="Alternar Visão do Mapa"
        >
          <FaLayerGroup />
        </button>
        {mapType === 'satellite' && ( // Condicional para mostrar apenas no modo satélite
          <button
            onClick={toggleTilt}
            className={styles.iconButton}
            aria-label="Alternar Inclinação da Câmera"
            style={{ color: tiltEnabled ? '#000' : '#555' }}
          >
            <FaCamera />
          </button>
        )}
        <button
          onClick={focusOnUserLocation}
          className={styles.iconButton}
          aria-label="Focar na Minha Localização"
        >
          <FaCrosshairs />
        </button>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  searchInput: PropTypes.string.isRequired,
  setSearchInput: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  toggleLabels: PropTypes.func.isRequired,
  toggleSatelliteView: PropTypes.func.isRequired,
  focusOnUserLocation: PropTypes.func.isRequired,
  toggleTilt: PropTypes.func.isRequired, // Prop do botão de inclinação
  labelsVisible: PropTypes.bool.isRequired,
  tiltEnabled: PropTypes.bool.isRequired, // Controle para estilização do botão
  mapType: PropTypes.string.isRequired, // Tipo de mapa (mapa/satélite)
};

export default SearchBar;
