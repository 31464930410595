// src/pages/GruposPostes/GruposPostes.js

import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'; 
import {
  GoogleMap,
  Marker,
  Polygon,
  OverlayView,
} from '@react-google-maps/api';
import { GiStreetLight } from 'react-icons/gi';
import { GrSelect } from 'react-icons/gr';
import { FaDrawPolygon, FaSearch, FaTrash, FaCog, FaEdit, FaTimes, FaPlus } from 'react-icons/fa';
import ReactDOMServer from 'react-dom/server';
import styles from './GruposPostes.module.css';
import SatelliteToggleButton from '../../pages/Mapa/SatelliteToggleButton';
import { lightMapStyles } from '../../config/mapStyles';
import PosteConfigurationModal from '../../components/PosteConfigurationModal/PosteConfigurationModal';
import GroupModal from './GroupModal'; // Importando o componente unificado
import PageLayout from '../../components/PageLayout/PageLayout';
import useDebounce from '../../hooks/useDebounce'; // Hook personalizado para debounce

const containerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: -8.072, // Latitude central do mapa
  lng: -34.945, // Longitude central do mapa
};

const LOCAL_STORAGE_KEY = 'gruposPostes'; // Chave para armazenar grupos no localStorage

const GruposPostes = () => {
  const mapRef = useRef(null);
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [markers, setMarkers] = useState([]); // Lista de postes carregados da API
  const [searchInput, setSearchInput] = useState('');
  const debouncedSearchInput = useDebounce(searchInput, 300); // Debounce para a busca
  const [groups, setGroups] = useState([]); // Lista de grupos
  const [selectedGroup, setSelectedGroup] = useState(null); // Grupo atualmente selecionado
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false); // Controle do modal de configuração
  const [isEditMode, setIsEditMode] = useState(false); // Indica se está no modo de edição
  const [editingGroupId, setEditingGroupId] = useState(null); // ID do grupo em edição
  const [newlyAddedPostes, setNewlyAddedPostes] = useState([]); // Postes recém-adicionados ao grupo
  const [searchGroupPostes, setSearchGroupPostes] = useState(''); // Input para filtrar postes dentro do grupo

  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false); // Controle do GroupModal
  const [groupModalMode, setGroupModalMode] = useState(''); // 'create' ou 'edit'

  // Estado para gerenciar a ferramenta ativa
  const [activeTool, setActiveTool] = useState(null); // 'select' | 'draw-polygon' | null

  // Estado para armazenar os vértices do polígono
  const [polygonPath, setPolygonPath] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false); // Indica se o usuário está desenhando o polígono

  // Estados para undo/redo
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  // Estado para controlar a visibilidade da lista de postes no modo de edição
  const [isPostListVisible, setIsPostListVisible] = useState(true);

  // Estado para controlar a animação de pulsação nas ferramentas
  const [showToolPulse, setShowToolPulse] = useState(false);

  // Função para alternar a visibilidade da lista de postes
  const togglePostListVisibility = () => {
    setIsPostListVisible((prev) => !prev);
  };

  // Função para alternar a visualização do mapa entre Satélite e Mapa Padrão
  const toggleSatelliteView = () => {
    setIsSatelliteView((prev) => !prev);
  };

  // Função para gerar os data URLs dos ícones com a cor do grupo
  const getMarkerIcon = (markerId) => {
    let color = '#808080'; // Cor padrão (cinza)

    // Verifica se o marcador pertence a algum grupo e escolhe a cor do grupo
    if (selectedGroup || isEditMode) {
      const group = groups.find(g => g.postes.includes(markerId));
      const isNewlyAdded = newlyAddedPostes.includes(markerId);
      if (group) {
        color = group.color || '#000000'; // Cor do grupo ou preto
      } else if (isNewlyAdded && selectedGroup) {
        color = selectedGroup.color || '#000000'; // Cor do grupo para novos postes
      }
    }

    // Gera o SVG do ícone com a cor apropriada
    const svgString = ReactDOMServer.renderToStaticMarkup(
      <GiStreetLight size={32} color={color} />
    );
    const encoded = encodeURIComponent(svgString)
      .replace(/'/g, '%27')
      .replace(/"/g, '%22');
    return `data:image/svg+xml;charset=UTF-8,${encoded}`;
  };

  // Função para buscar postes da API
  const fetchPostes = async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const response = await fetch('https://api.ads10.com.br/api/poste', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Erro ao buscar postes');
      }
      const postes = await response.json();
      const formattedMarkers = postes
        .filter((poste) => poste.lat && poste.lng) // Apenas postes com latitude e longitude válidas
        .map((poste) => ({
          id: poste.id,
          lat: parseFloat(poste.lat),
          lng: parseFloat(poste.lng),
        }));
      setMarkers(formattedMarkers);
    } catch (error) {
      console.error('Erro ao buscar postes:', error);
    }
  };

  // Função para carregar grupos do localStorage
  const loadGroupsFromLocalStorage = () => {
    const storedGroups = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (storedGroups) {
      try {
        const parsedGroups = JSON.parse(storedGroups);
        setGroups(parsedGroups);
      } catch (error) {
        console.error('Erro ao parsear grupos do localStorage:', error);
        setGroups([]);
      }
    }
  };

  // Função para salvar grupos no localStorage
  const saveGroupsToLocalStorage = (grupos) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(grupos));
  };

  // Função de busca, agora usando useCallback
  const handleSearch = useCallback(() => {
    const cleanedSearchInput = debouncedSearchInput.replace(/[^0-9]/g, '');
    const targetMarker = markers.find((marker) => marker.id.toString() === cleanedSearchInput);
    if (targetMarker && mapRef.current) {
      const latLng = new window.google.maps.LatLng(targetMarker.lat, targetMarker.lng);
      mapRef.current.panTo(latLng);
      mapRef.current.setZoom(18);
    } else {
      alert('Poste não encontrado');
    }
  }, [debouncedSearchInput, markers]);

  // Efeito para buscar postes e carregar grupos ao montar o componente
  useEffect(() => {
    fetchPostes();
    loadGroupsFromLocalStorage();
  }, []);

  // Efeito para salvar grupos no localStorage sempre que houver alterações
  useEffect(() => {
    saveGroupsToLocalStorage(groups);
  }, [groups]);

  // Efeito para realizar a busca com debounce
  useEffect(() => {
    if (debouncedSearchInput) {
      handleSearch();
    }
  }, [debouncedSearchInput, handleSearch]);

  // Efeito para parar a animação de pulsação após 3 segundos
  useEffect(() => {
    if (showToolPulse) {
      const timer = setTimeout(() => {
        setShowToolPulse(false);
      }, 3000); // A animação de pulsação dura 3 segundos
      return () => clearTimeout(timer);
    }
  }, [showToolPulse]);

  // Função para selecionar um grupo
  const handleSelectGroup = (groupId) => {
    const group = groups.find((group) => group.id === groupId);
    setSelectedGroup(group);
    setIsEditMode(false);
    setEditingGroupId(null);
    setNewlyAddedPostes([]);
    setActiveTool(null); // Desativa qualquer ferramenta ativa
    setPolygonPath([]); // Limpa qualquer polígono desenhado anteriormente
    setUndoStack([]);
    setRedoStack([]);
  };

  // Função para deletar um grupo
  const handleDeleteGroup = () => {
    if (selectedGroup) {
      const updatedGroups = groups.filter((group) => group.id !== selectedGroup.id);
      setGroups(updatedGroups);
      setSelectedGroup(null);
      setIsEditMode(false);
      setEditingGroupId(null);
      setNewlyAddedPostes([]);
      setUndoStack([]);
      setRedoStack([]);
    }
  };

  // Função para remover um poste do grupo
  const removePosteFromGroup = (posteId) => {
    if (!selectedGroup) return;

    const updatedGroups = groups.map((group) => {
      if (group.id === selectedGroup.id) {
        return {
          ...group,
          postes: group.postes.filter((id) => id !== posteId),
        };
      }
      return group;
    });

    setGroups(updatedGroups);
    setSelectedGroup((prevGroup) => ({
      ...prevGroup,
      postes: prevGroup.postes.filter((id) => id !== posteId),
    }));
  };

  // Funções para abrir e fechar o modal de configuração
  const openConfigModal = () => {
    if (selectedGroup) {
      setIsConfigModalOpen(true);
    }
  };

  const closeConfigModal = () => {
    setIsConfigModalOpen(false);
  };

  // Funções para abrir e fechar o GroupModal
  const openGroupModal = (mode) => {
    setGroupModalMode(mode);
    setIsGroupModalOpen(true);
  };

  const closeGroupModal = () => {
    setIsGroupModalOpen(false);
    setGroupModalMode('');
  };

  // Função para lidar com a criação ou atualização de grupo
  const handleGroupSubmit = (groupData) => {
    if (groupModalMode === 'create') {
      // Criar um novo grupo
      const newGroupId = Date.now(); // Gera um ID único
      const groupToAdd = { id: newGroupId, ...groupData, postes: [] };
      const updatedGroups = [...groups, groupToAdd];
      setGroups(updatedGroups);
      setSelectedGroup(groupToAdd);
      setIsEditMode(true);
      setEditingGroupId(newGroupId);
      setNewlyAddedPostes([]);
      setActiveTool('select'); // Ferramenta padrão é a de ponto a ponto
      setShowToolPulse(true); // Iniciar animação de pulsação
    } else if (groupModalMode === 'edit') {
      // Atualizar grupo existente
      const updatedGroups = groups.map((group) => {
        if (group.id === groupData.id) {
          return groupData;
        }
        return group;
      });
      setGroups(updatedGroups);
      setSelectedGroup(groupData);
    }
  };

  // Função para entrar no modo de edição de um grupo
  const enterEditMode = (groupId) => {
    setIsEditMode(true);
    setEditingGroupId(groupId);
    setNewlyAddedPostes([]);
    setActiveTool('select'); // Ferramenta padrão é a de ponto a ponto
    setUndoStack([]);
    setRedoStack([]);
    setShowToolPulse(true); // Iniciar animação de pulsação
  };

  // Função para sair do modo de edição e salvar as mudanças
  const exitEditMode = () => {
    if (isDrawing && polygonPath.length > 0) {
      const applyPolygonSelection = window.confirm(
        'Você está em uma seleção de polígonos. Deseja aplicar a seleção antes de salvar?'
      );
      if (applyPolygonSelection) {
        finishPolygon();
      } else {
        cancelPolygon();
      }
    }

    if (selectedGroup && newlyAddedPostes.length > 0) {
      const updatedGroups = groups.map((group) => {
        if (group.id === editingGroupId) {
          return {
            ...group,
            postes: Array.from(new Set([...group.postes, ...newlyAddedPostes])), // Evita duplicatas
          };
        }
        return group;
      });
      setGroups(updatedGroups);

      // Atualiza o selectedGroup para refletir as mudanças
      const updatedGroup = updatedGroups.find((group) => group.id === editingGroupId);
      setSelectedGroup(updatedGroup);
    }

    // Limpa o estado de postes recém-adicionados e sai do modo de edição
    setIsEditMode(false);
    setEditingGroupId(null);
    setNewlyAddedPostes([]);
    setActiveTool(null); // Desativa qualquer ferramenta ativa
    setUndoStack([]);
    setRedoStack([]);
  };

  // Função para cancelar a edição de um grupo
  const cancelEditMode = () => {
    let confirmMessage = 'Você tem certeza que deseja cancelar a edição?';
  
    if (isDrawing && polygonPath.length > 0) {
      confirmMessage += ' Todos os polígonos serão removidos e todos os dados não salvos serão perdidos.';
    } else {
      confirmMessage += ' Todos os dados não salvos serão perdidos.';
    }

    const confirmCancel = window.confirm(confirmMessage);

    if (confirmCancel) {
      if (isDrawing && polygonPath.length > 0) {
        cancelPolygon();
      }
      setIsEditMode(false);
      setEditingGroupId(null);
      setNewlyAddedPostes([]);
      setActiveTool(null);
    }
  };

  // Função para adicionar ou remover um poste do grupo em edição
  const togglePosteInGroup = (posteId) => {
    const groupIndex = groups.findIndex((group) => group.id === editingGroupId);
    if (groupIndex === -1) return;

    const group = groups[groupIndex];
    const isPosteInGroup = group.postes.includes(posteId);
    const isPosteNewlyAdded = newlyAddedPostes.includes(posteId);

    if (isPosteInGroup) {
      // Remove o poste do grupo
      const updatedPostes = group.postes.filter((id) => id !== posteId);
      const updatedGroup = { ...group, postes: updatedPostes };
      const updatedGroups = [...groups];
      updatedGroups[groupIndex] = updatedGroup;
      setGroups(updatedGroups);

      // Remove o poste da lista de recém-adicionados, se estiver presente
      setNewlyAddedPostes((prev) => prev.filter((id) => id !== posteId));
    } else {
      if (!isPosteNewlyAdded && !isPosteInGroup) {
        // Adiciona o poste à lista de recém-adicionados no topo
        setNewlyAddedPostes((prev) => [posteId, ...prev]); // Adiciona no topo
      } else {
        // Remove o poste da lista de recém-adicionados
        setNewlyAddedPostes((prev) => prev.filter((id) => id !== posteId));
      }
    }
  };

  // Função para obter apenas os postes pertencentes ao grupo selecionado
  const getPostesInGroup = useMemo(() => {
    if (!selectedGroup) return [];
    return selectedGroup.postes;
  }, [selectedGroup]);

  // Função para filtrar os postes do grupo com base na pesquisa
  const filterGroupPostes = useMemo(() => {
    const combinedPostes = Array.from(new Set([...getPostesInGroup, ...newlyAddedPostes]));

    if (!searchGroupPostes) return combinedPostes;

    return combinedPostes.filter((posteId) =>
      posteId.toString().includes(searchGroupPostes)
    );
  }, [searchGroupPostes, getPostesInGroup, newlyAddedPostes]);

  // Função para lidar com cliques no mapa para desenhar o polígono
  const handleMapClick = (event) => {
    if (activeTool === 'draw-polygon' && isEditMode && isDrawing) {
      const newPath = [...polygonPath, { lat: event.latLng.lat(), lng: event.latLng.lng() }];
      setPolygonPath(newPath);
      setUndoStack([...undoStack, polygonPath]); // Salva o estado atual para undo
      setRedoStack([]); // Limpa o redoStack
    }
  };

  // Função para finalizar o desenho do polígono
  const finishPolygon = () => {
    if (polygonPath.length < 3) {
      alert('O polígono precisa de pelo menos 3 vértices.');
      return;
    }

    // Criar uma instância de polígono para usar a função containsLocation
    const polygon = new window.google.maps.Polygon({
      paths: polygonPath,
    });

    // Encontrar postes dentro do polígono
    const postesDentro = markers
      .filter((marker) => {
        const latLng = new window.google.maps.LatLng(marker.lat, marker.lng);
        return window.google.maps.geometry.poly.containsLocation(latLng, polygon);
      })
      .map((marker) => marker.id);

    // Filtrar os postes que ainda não estão no grupo ou na lista de recém-adicionados
    const novosPostes = postesDentro.filter(
      (id) =>
        !groups.find((g) => g.id === selectedGroup.id)?.postes.includes(id) &&
        !newlyAddedPostes.includes(id)
    );

    if (novosPostes.length > 0 && selectedGroup) {
      // Adicionar os novos postes ao grupo
      const updatedGroups = groups.map((group) => {
        if (group.id === selectedGroup.id) {
          return {
            ...group,
            postes: Array.from(new Set([...group.postes, ...novosPostes])), // Evita duplicatas
          };
        }
        return group;
      });
      setGroups(updatedGroups);

      // Adicionar os novos postes à lista de recém-adicionados no topo
      setNewlyAddedPostes((prev) => Array.from(new Set([...novosPostes, ...prev])));

      // Atualiza o selectedGroup para refletir as mudanças
      const updatedGroup = updatedGroups.find((group) => group.id === selectedGroup.id);
      setSelectedGroup(updatedGroup);
    }

    // Limpar o polígono desenhado
    setPolygonPath([]);
    setActiveTool(null);
    setIsDrawing(false);
    setUndoStack([]);
    setRedoStack([]);

    // Opcional: Exibir uma notificação ao usuário
    alert(`${novosPostes.length} postes adicionados ao grupo ${selectedGroup.name}.`);
  };

  // Função para cancelar o desenho do polígono
  const cancelPolygon = () => {
    setPolygonPath([]);
    setActiveTool(null);
    setIsDrawing(false);
    setUndoStack([]);
    setRedoStack([]);
  };

  // Função para desfazer a última ação de desenho
  const handleUndo = () => {
    if (undoStack.length === 0) return;

    const previousPath = undoStack[undoStack.length - 1];
    setUndoStack(undoStack.slice(0, -1));
    setRedoStack([...redoStack, polygonPath]);
    setPolygonPath(previousPath);
  };

  // Função para refazer a última ação desfeita
  const handleRedo = () => {
    if (redoStack.length === 0) return;

    const nextPath = redoStack[redoStack.length - 1];
    setRedoStack(redoStack.slice(0, -1));
    setUndoStack([...undoStack, polygonPath]);
    setPolygonPath(nextPath);
  };

  // Função para renderizar círculos nos vértices do polígono em desenho
  const renderVertexCircles = () => {
    if (polygonPath.length === 0) return null;

    return polygonPath.map((vertex, index) => {
      const overlayStyle = {
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        width: '10px',
        height: '10px',
        backgroundColor: '#2196F3',
        border: '2px solid white',
        borderRadius: '50%',
        pointerEvents: 'none', // Impede que os círculos capturem eventos de mouse
      };

      return (
        <OverlayView
          key={index}
          position={{ lat: vertex.lat, lng: vertex.lng }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <div style={overlayStyle} />
        </OverlayView>
      );
    });
  };

  // Função para lidar com a mudança de ferramenta com verificação de polígono em andamento
  const handleToolChange = (tool) => {
    if (isDrawing && polygonPath.length > 0) {
      const proceed = window.confirm(
        'Você está em uma seleção de polígono. Deseja aplicar a seleção antes de mudar de ferramenta?'
      );
      if (proceed) {
        finishPolygon();
      } else {
        cancelPolygon();
      }
    }
    setActiveTool(tool);
    if (tool === 'draw-polygon') {
      setIsDrawing(true);
    } else {
      setIsDrawing(false);
    }
  };

  return (
    <PageLayout title="Grupos de Postes">
      <div className={styles.mapPage}>
        {/* Texto "Modo de Edição" Centralizado no Topo */}
        {isEditMode && (
          <div className={styles.editModeIndicator}>
            Modo de Edição
          </div>
        )}

        <div className={styles.mapContainer}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={17}
            mapTypeId={isSatelliteView ? 'satellite' : 'roadmap'}
            options={{
              styles: isSatelliteView ? [] : lightMapStyles,
              disableDefaultUI: true, // Desativa controles padrão
              zoomControl: true,
              draggable: !isDrawing, // Permite arrastar o mapa somente se não estiver desenhando
              gestureHandling: isDrawing ? 'none' : 'auto', // Desativa gestos enquanto desenha
              keyboardShortcuts: false, // Desativa atalhos de teclado do Google Maps
            }}
            onLoad={(map) => (mapRef.current = map)}
            onClick={handleMapClick}
          >
            {/* Renderizar o polígono sendo desenhado */}
            {polygonPath.length > 0 && (
              <Polygon
                paths={polygonPath}
                options={{
                  fillColor: '#2196F3',
                  fillOpacity: 0.2,
                  strokeColor: '#2196F3',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  clickable: false,
                  editable: false,
                  zIndex: 1,
                }}
              />
            )}

            {/* Renderizar círculos nos vértices do polígono em desenho */}
            {isDrawing && renderVertexCircles()}

            {/* Renderizar os marcadores */}
            {markers.map((marker) => (
              <Marker
                key={marker.id}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={getMarkerIcon(marker.id)} // Aplicando a cor ao ícone padrão
                onClick={() => {
                  // Se estiver no modo de edição e a ferramenta de seleção ponto a ponto estiver ativa
                  if (isEditMode && activeTool === 'select') {
                    if (selectedGroup) {
                      togglePosteInGroup(marker.id);
                    } else {
                      alert('Selecione um grupo primeiro.');
                    }
                  }
                }}
              />
            ))}
          </GoogleMap>
        </div>

        {/* Botão de Alternar Visão Satélite */}
        <SatelliteToggleButton
          isSatelliteView={isSatelliteView}
          toggleSatelliteView={toggleSatelliteView}
        />

        {/* Barra de Ferramentas (Apenas no Modo de Edição) */}
        {isEditMode && (
          <div className={styles.toolBar}>
            {/* Ferramenta de Seleção Ponto a Ponto */}
            <button
              className={`${styles.toolButton} ${
                activeTool === 'select' ? styles.activeToolButton : ''
              } ${showToolPulse ? styles.pulseAnimation : ''}`}
              onClick={() => handleToolChange(activeTool === 'select' ? null : 'select')}
              title="Seleção Ponto a Ponto"
            >
              <GrSelect />
            </button>

            {/* Ferramenta de Seleção Poligonal */}
            <button
              className={`${styles.toolButton} ${
                activeTool === 'draw-polygon' ? styles.activeToolButton : ''
              } ${showToolPulse ? styles.pulseAnimation : ''}`}
              onClick={() => handleToolChange(activeTool === 'draw-polygon' ? null : 'draw-polygon')}
              title="Seleção Poligonal"
            >
              <FaDrawPolygon />
            </button>

            {/* Botões para Finalizar ou Cancelar o Polígono */}
            {activeTool === 'draw-polygon' && isDrawing && (
              <>
                <div className={styles.polygonControls}>
                  <button className={styles.finishPolygonButton} onClick={finishPolygon}>
                    Aplicar Seleção
                  </button>
                  <button className={styles.cancelPolygonButton} onClick={cancelPolygon}>
                    Cancelar Seleção
                  </button>
                </div>

                {/* Botões de Desfazer e Refazer */}
                <div className={styles.undoRedoControls}>
                  <button
                    className={styles.undoButton}
                    onClick={handleUndo}
                    disabled={undoStack.length === 0}
                  >
                    Desfazer
                  </button>
                  <button
                    className={styles.redoButton}
                    onClick={handleRedo}
                    disabled={redoStack.length === 0}
                  >
                    Refazer
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {/* Seção de busca por ID do poste */}
        <div className={styles.searchContainer}>
          <FaSearch className={styles.searchIcon} />
          <input
            type="text"
            placeholder="Digite o ID do poste"
            className={styles.searchInput}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button className={styles.searchButton} onClick={handleSearch}>
            Buscar
          </button>
        </div>

        {/* Barra lateral com lista de grupos e controles */}
        <div className={styles.sidebar}>
          <div className={styles.groupSection}>
            <h3>Grupos</h3>
            <div className={styles.groupControls}>
              <button className={styles.createGroupButton} onClick={() => openGroupModal('create')}>
                <FaPlus /> Criar Novo Grupo
              </button>
              <div className={styles.groupSelection}>
                <select
                  className={styles.groupDropdown}
                  value={selectedGroup ? selectedGroup.id : ''}
                  onChange={(e) => handleSelectGroup(Number(e.target.value))}
                >
                  <option value="">Nenhum grupo selecionado</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
                {/* Ocultar o botão de editar se nenhum grupo estiver selecionado */}
                {selectedGroup && (
                  <button className={styles.editGroupButton} onClick={() => openGroupModal('edit')}>
                    <FaEdit />
                  </button>
                )}
              </div>
            </div>

            {selectedGroup ? (
              <>
                {/* Se não estiver no modo de edição, exibe os botões de configuração, edição e exclusão */}
                {!isEditMode ? (
                  <div className={styles.groupActions}>
                    <button className={styles.configButton} onClick={openConfigModal}>
                      <FaCog /> Configurar
                    </button>
                    <button
                      className={styles.editButton}
                      onClick={() => enterEditMode(selectedGroup.id)}
                    >
                      <FaEdit /> Editar
                    </button>
                    <button className={styles.deleteButton} onClick={handleDeleteGroup}>
                      <FaTrash /> Excluir
                    </button>
                  </div>
                ) : (
                  /* Se estiver no modo de edição, exibe os botões de salvar e cancelar */
                  <div className={styles.editActions}>
                    <button className={styles.saveButton} onClick={exitEditMode}>
                      Salvar
                    </button>
                    <button className={styles.cancelButton} onClick={cancelEditMode}>
                      Cancelar
                    </button>
                  </div>
                )}

                {/* Pesquisa e Lista de postes pertencentes ao grupo selecionado */}
                <div className={styles.groupPostes}>
                  <div className={styles.postesHeader}>
                    <h4>Postes no Grupo</h4>
                    {/* Botão para ocultar/expandir a lista de postes */}
                    {isEditMode && (
                      <button
                        className={styles.togglePostListButton}
                        onClick={togglePostListVisibility}
                        title={isPostListVisible ? 'Ocultar Postes' : 'Mostrar Postes'}
                      >
                        {isPostListVisible ? 'Ocultar' : 'Mostrar'}
                      </button>
                    )}
                  </div>

                  {/* Barra de pesquisa para postes dentro do grupo */}
                  <input
                    type="text"
                    placeholder="Pesquisar por ID do poste"
                    className={styles.searchInput}
                    value={searchGroupPostes}
                    onChange={(e) => setSearchGroupPostes(e.target.value)}
                  />

                  {/* Lista de Postes - Oculta ou Exibe com base no estado */}
                  {isPostListVisible && (
                    <div className={styles.postesList}>
                      {filterGroupPostes.length > 0 ? (
                        filterGroupPostes.map((posteId) => (
                          <div key={posteId} className={styles.posteItem}>
                            <span
                              style={{
                                color: selectedGroup.color || '#000000', // Aplica a cor do grupo ao texto do poste
                              }}
                            >
                              ID Poste: {posteId}
                            </span>
                            {/* Botão para remover o poste do grupo, visível apenas no modo de edição */}
                            {isEditMode && (
                              <button
                                className={styles.removePosteButton}
                                onClick={() => removePosteFromGroup(posteId)}
                                title="Remover Poste"
                              >
                                <FaTimes />
                              </button>
                            )}
                          </div>
                        ))
                      ) : (
                        <p>Nenhum poste encontrado.</p>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <p>Nenhum grupo selecionado</p>
            )}
          </div>
        </div>
      </div>

      {/* Modal de Configuração de Poste */}
      {isConfigModalOpen && (
        <PosteConfigurationModal
          isOpen={isConfigModalOpen}
          onRequestClose={closeConfigModal}
          poste={selectedGroup}
        />
      )}

      {/* Modal de Criação/Edição de Grupo */}
      {isGroupModalOpen && (
        <GroupModal
          isOpen={isGroupModalOpen}
          onRequestClose={closeGroupModal}
          onSubmit={handleGroupSubmit}
          group={groupModalMode === 'edit' ? selectedGroup : null}
        />
      )}
    </PageLayout>
  );
};

export default GruposPostes;
