import { useContext, useState, useCallback, useEffect } from 'react'; // Hooks do React
import { AuthContext } from '../context/AuthContext'; // Contexto de autenticação

const useAvailableDevices = () => {
  const { token, logout } = useContext(AuthContext);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDevices = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const fotocelulasResponse = await fetch('https://api.ads10.com.br/api/fotocelula', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      const contentType = fotocelulasResponse.headers.get('content-type');
      if (!fotocelulasResponse.ok) {
        if (fotocelulasResponse.status === 401) {
          logout();
          return;
        }
        throw new Error(`Erro ao buscar fotocélulas: ${fotocelulasResponse.statusText}`);
      }

      if (!contentType || !contentType.includes('application/json')) {
        const errorText = await fotocelulasResponse.text();
        throw new Error(`Resposta inesperada da API: ${errorText}`);
      }

      const fotocelulasData = await fotocelulasResponse.json();

      const postefotocelulaResponse = await fetch('https://api.ads10.com.br/api/postefotocelula', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!postefotocelulaResponse.ok) {
        if (postefotocelulaResponse.status === 401) {
          logout();
          return;
        }
        throw new Error(`Erro ao buscar associações: ${postefotocelulaResponse.statusText}`);
      }

      const postefotocelulaData = await postefotocelulaResponse.json();

      const fotocelulasAtivasIds = new Set(
        postefotocelulaData
          .filter((assoc) => assoc.ativo === 'S')
          .map((assoc) => assoc.fotocelulaId)
      );

      const available = fotocelulasData.filter((device) => {
        const fotocelulaId = device.fotocelulaId;
        return !fotocelulasAtivasIds.has(fotocelulaId);
      });

      setAvailableDevices(available);
    } catch (error) {
      console.error('Erro ao buscar dispositivos disponíveis:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [token, logout]);

  useEffect(() => {
    if (token) {
      fetchDevices();
    }
  }, [token, fetchDevices]);

  return { availableDevices, isLoading, error, refetch: fetchDevices };
};

export default useAvailableDevices;
