import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaSave } from 'react-icons/fa';
import styles from './GenericModal.module.css';

const GenericModal = ({ isOpen, onRequestClose, onSave, initialData }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    } else {
      setData({});
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSave = () => {
    onSave(data);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>{initialData ? 'Editar Item' : 'Adicionar Item'}</h2>
        
        <div className={styles.controlGroup}>
          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={data.name || ''}
            onChange={handleChange}
            className={styles.input}
          />
        </div>

        <div className={styles.controlGroup}>
          <label htmlFor="description">Descrição:</label>
          <textarea
            id="description"
            name="description"
            value={data.description || ''}
            onChange={handleChange}
            className={styles.input}
          />
        </div>

        <button onClick={handleSave} className={styles.saveButton}>
          <FaSave /> Salvar
        </button>
      </div>
    </Modal>
  );
};

export default GenericModal;
