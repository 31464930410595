// src/components/Sidebar/NavItem.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Sidebar.module.css';

const NavItem = ({ to, icon: Icon, label, notification, children, onClick }) => {
    return (
        <li className={styles.navItem}>
            {to ? (
                <Link to={to} className={styles.link} onClick={onClick}>
                    <Icon className={styles.icon} />
                    <span>{label}</span>
                    {notification > 0 && <span className={styles.notification}>{notification}</span>}
                </Link>
            ) : (
                <div className={styles.link} onClick={onClick} role="button" tabIndex={0}
                    onKeyPress={(e) => { if (e.key === 'Enter') onClick(); }}>
                    <Icon className={styles.icon} />
                    <span>{label}</span>
                    {notification > 0 && <span className={styles.notification}>{notification}</span>}
                </div>
            )}
            {children}
        </li>
    );
};

export default NavItem;
