import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useAlertasComOrdemServico = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [alertasComOS, setAlertasComOS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        cidadeId,
      };

      const [alertaResponse, osResponse] = await Promise.all([
        fetch('https://api.ads10.com.br/api/alerta', { headers }),
        fetch('https://api.ads10.com.br/api/os', { headers }),
      ]);

      if (alertaResponse.status === 401 || osResponse.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!alertaResponse.ok || !osResponse.ok) {
        throw new Error('Erro ao buscar dados dos alertas e ordens de serviço');
      }

      const [alertas, ordensServico] = await Promise.all([
        alertaResponse.json(),
        osResponse.json(),
      ]);

      const alertasComDetalhes = alertas.map(alerta => {
        const osCorrespondente = ordensServico.find(os => os.osAlertaId === alerta.alertaId);
        
        return {
          ...alerta,
          ordemServico: osCorrespondente || null,
        };
      });

      setAlertasComOS(alertasComDetalhes);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError(error.message);
      toast.error(`Erro ao buscar dados: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { alertasComOS, isLoading, error, refetch: fetchData };
};

export default useAlertasComOrdemServico;
