// Versão do Aplicativo
export const APP_VERSION = "0.07"; // Atualize este valor conforme a versão do package.json

// Localizações Centrais do Mapa e Logos para diferentes cidades
export const MAP_CENTERS = {
  3315: {
    lat: -8.072,
    lng: -34.945,
    extraLogo: require('./assets/CeasaLogo.png'), // Caminho para o logo extra (CEASA)
    coipLogo: require('./assets/coeLogo.png'), // Caminho para o logo COIP
    sigla: 'CEA' // Sigla da cidade
  },
  5060: {
    lat: -20.905466,
    lng: -48.643902,
    extraLogo: null, // Ainda não há logo extra
    coipLogo: null, // Ainda não há logo COIP, ficará o placeholder
    sigla: 'MAP' // Sigla da cidade
  },
  5201: {
    lat: -23.7449,
    lng: -46.3974,
    extraLogo: null, // Pode adicionar um logo aqui no futuro
    coipLogo: null, // Placeholder COIP para esta cidade
    sigla: 'RGS' // Sigla da cidade
  },
};

// Função para obter o centro do mapa pelo `cidadeId`
export const getMapCenterByCityId = (cidadeId) => {
  return MAP_CENTERS[cidadeId] || MAP_CENTERS[3315]; // Retorna Recife por padrão caso o `cidadeId` não exista
};

// Função para obter os logos pelo `cidadeId`
export const getLogosByCityId = (cidadeId) => {
  const cityData = MAP_CENTERS[cidadeId];
  return {
    extraLogo: cityData?.extraLogo || null,
    coipLogo: cityData?.coipLogo || null,
  };
};

// Nova função para obter a sigla da cidade pelo `cidadeId`
export const getCityAbbreviationById = (cidadeId) => {
  return MAP_CENTERS[cidadeId]?.sigla || 'N/A'; // Retorna 'N/A' se a sigla não estiver disponível
};

// Chave da API do Google Maps
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "YOUR_GOOGLE_MAPS_API_KEY";
