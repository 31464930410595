// src/pages/OrdensServicos/OrdensServicos.jsx

import React, { useState, useMemo } from 'react';
import ReactPaginate from 'react-paginate';
import { FaSortUp, FaSortDown, FaEdit, FaEye } from 'react-icons/fa'; // Importando FaEye
import { CSVLink } from 'react-csv';
import PageLayout from '../../components/PageLayout/PageLayout';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import EditServiceOrderModal from './EditServiceOrderModal';
import usePrestadores from '../../hooks/usePrestadores';
import useAlertaSituacao from '../../hooks/useAlertaSituacao';
import useAlertasComOrdemServico from '../../hooks/useAlertasComOrdemServico'; // Importando o hook personalizado
import styles from './OrdensServicos.module.css';
import { toast } from 'react-toastify'; // Importando toast para notificações

const OrdensServicos = () => {
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedAlerta, setSelectedAlerta] = useState(null); // Estado para o alerta correspondente
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false); // Novo estado para controlar o modo do modal
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [prestadorFilter, setPrestadorFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'ordemServico.osDataAbertura', direction: 'descending' }); // Ordenação padrão
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const { alertasComOS, isLoading, error, refetch } = useAlertasComOrdemServico(); // Usando o hook personalizado
  const { prestadores, isLoading: prestadoresLoading, error: prestadoresError } = usePrestadores();
  const { situacoes, isLoading: situacoesLoading, error: situacoesError } = useAlertaSituacao(); // Utilizando o novo hook

  // Adicione este console.log para verificar a estrutura dos dados
  console.log('Dados retornados pelo hook:', alertasComOS);

  // Função para mapear o ID da situação para o nome usando as situações dinâmicas
  const getSituacaoNome = (situacaoId) => {
    return situacoes[situacaoId] || 'Desconhecido';
  };

  // Função para obter o nome do prestador com base no ID
  const getPrestadorNome = (prestadorId) => {
    const prestador = prestadores.find(p => p.pessoaId === prestadorId);
    return prestador ? prestador.pessoaNome : 'Desconhecido';
  };

  // Função para obter o motivo do alerta com base no ID do alerta
  const getMotivoFromAlerta = (osAlertaId) => {
    const alerta = alertasComOS.find(alerta => alerta.alertaId === osAlertaId);
    return alerta ? alerta.alertaMotivo : 'Motivo não encontrado';
  };

  // Função para lidar com o clique no botão de edição ou visualização
  const handleActionClick = (order, viewOnly = false) => {
    setIsViewOnly(viewOnly);
    setSelectedOrder(order);
    const alertaCorrespondente = alertasComOS.find(alerta => alerta.alertaId === order.alertaId);
    if (alertaCorrespondente && alertaCorrespondente.ordemServico) {
      // Utilizar 'ordemServico.osPrestadorId' para obter o nome do prestador
      const prestadorNome = getPrestadorNome(alertaCorrespondente.ordemServico.osPrestadorId);
      setSelectedAlerta({
        ...alertaCorrespondente,
        prestadorNome: prestadorNome, // Adiciona o nome do prestador
      });
      setIsModalOpen(true);
    } else {
      toast.error('Alerta correspondente não encontrado ou sem Ordem de Serviço associada.');
    }
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
    setSelectedAlerta(null);
    setIsViewOnly(false); // Resetar o modo do modal
  };

  // Função para salvar as alterações na Ordem de Serviço e no Alerta
  const handleSaveOrder = async (updatedOrder) => {
    try {
      // Recupera o token e cidadeId do AuthContext ou do localStorage/sessionStorage
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      const cidadeId = localStorage.getItem('cidadeId') || sessionStorage.getItem('cidadeId');

      if (!token || !cidadeId) {
        throw new Error('Token de autenticação ou ID da cidade não encontrado.');
      }

      // Headers atualizados com cidadeId
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'cidadeId': cidadeId, // Inclui o cidadeId nos headers
      };

      console.log('Atualizando Ordem de Serviço com os seguintes dados:', updatedOrder.ordemServico);

      // Atualizar Ordem de Serviço (OS)
      const responseOS = await fetch(`https://api.ads10.com.br/api/os/${updatedOrder.ordemServico.osId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(updatedOrder.ordemServico),
      });

      console.log('Resposta da API para OS:', responseOS);

      if (!responseOS.ok) {
        throw new Error('Erro ao atualizar a ordem de serviço.');
      }

      // Atualizar Alerta correspondente
      const updatedAlert = {
        ...selectedAlerta,
        alertaSituacao: updatedOrder.ordemServico.osSituacaoId, // Atualiza a situação do alerta com o novo status da OS
      };

      console.log('Atualizando Alerta com os seguintes dados:', updatedAlert);

      const responseAlerta = await fetch(`https://api.ads10.com.br/api/alerta/${updatedAlert.alertaId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(updatedAlert),
      });

      console.log('Resposta da API para Alerta:', responseAlerta);

      if (!responseAlerta.ok) {
        throw new Error('Erro ao atualizar o alerta.');
      }

      // Recarregar os dados após atualização
      await refetch();

      setIsModalOpen(false);
      setSelectedOrder(null);
      setSelectedAlerta(null);
      setIsViewOnly(false); // Resetar o modo do modal
      toast.success('Ordem de serviço e alerta atualizados com sucesso.');
    } catch (error) {
      console.error('Erro ao salvar a ordem de serviço:', error);
      toast.error(`Erro ao salvar a ordem de serviço: ${error.message}`);
    }
  };

  // Funções para lidar com os filtros de pesquisa
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    setCurrentPage(0);
  };

  const handlePrestadorFilterChange = (e) => {
    setPrestadorFilter(e.target.value);
    setCurrentPage(0);
  };

  // Função para solicitar a ordenação
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  // Filtrar os dados com base na pesquisa e nos filtros selecionados
  const filteredData = useMemo(() => {
    if (!alertasComOS) return [];

    const lowercasedSearchTerm = searchTerm.toLowerCase();

    return alertasComOS
      .filter(order => {
        const osIdStr = order.ordemServico?.osId?.toString() || '';
        const alertaIdStr = order.alertaId?.toString() || '';
        const osCidadeIdStr = order.ordemServico?.osCidadeId?.toString() || '';
        const osPosteIdStr = order.ordemServico?.osPosteId?.toString() || '';

        const matchesSearchTerm =
          osIdStr.includes(lowercasedSearchTerm) ||
          alertaIdStr.includes(lowercasedSearchTerm) ||
          osCidadeIdStr.includes(lowercasedSearchTerm) ||
          osPosteIdStr.includes(lowercasedSearchTerm);

        const matchesStatusFilter = statusFilter
          ? (order.ordemServico?.osSituacaoId?.toString() || '') === statusFilter
          : true;

        const matchesPrestadorFilter = prestadorFilter
          ? (order.ordemServico?.osPrestadorId?.toString() || '') === prestadorFilter
          : true;

        return (
          matchesSearchTerm &&
          matchesStatusFilter &&
          matchesPrestadorFilter
        );
      });
  }, [alertasComOS, searchTerm, statusFilter, prestadorFilter]);

  // Ordenar os dados filtrados
  const sortedServiceOrders = useMemo(() => {
    const sortedOrders = [...filteredData];
    if (sortConfig.key) {
      sortedOrders.sort((a, b) => {
        let aValue, bValue;

        if (sortConfig.key.startsWith('ordemServico.')) {
          const key = sortConfig.key.split('.')[1];
          aValue = a.ordemServico ? a.ordemServico[key] : null;
          bValue = b.ordemServico ? b.ordemServico[key] : null;
        } else {
          aValue = a[sortConfig.key];
          bValue = b[sortConfig.key];
        }

        // Se a chave for data, converter para objeto Date
        if (sortConfig.key.toLowerCase().includes('data')) {
          aValue = aValue ? new Date(aValue) : new Date(0);
          bValue = bValue ? new Date(bValue) : new Date(0);
        }

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedOrders;
  }, [filteredData, sortConfig]);

  // Calcular o número total de páginas
  const pageCount = Math.ceil(sortedServiceOrders.length / itemsPerPage);

  // Obter os dados da página atual
  const currentPageData = useMemo(() => {
    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return sortedServiceOrders.slice(start, end);
  }, [sortedServiceOrders, currentPage, itemsPerPage]);

  // Função para lidar com a mudança de página
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  // Definir os headers para exportação CSV
  const headers = [
    { label: 'ID', key: 'osId' },
    { label: 'ID do Alerta', key: 'alertaId' },
    { label: 'Poste ID', key: 'osPosteId' },
    { label: 'Cidade', key: 'osCidadeId' },
    { label: 'Data de Abertura', key: 'osDataAbertura' },
    { label: 'Hora de Abertura', key: 'osHoraAbertura' },
    { label: 'Prestador', key: 'prestadorNome' },
    { label: 'Status', key: 'statusNome' },
    { label: 'Motivo', key: 'motivo' }, // Adicionando a coluna de motivo
    { label: 'Data de Encerramento', key: 'osDataEncerramento' }, // Novo
    { label: 'Hora de Encerramento', key: 'osHoraEncerramento' }, // Novo
    { label: 'Observação', key: 'osObs' }, // Adicionando a coluna de observação
  ];

  // Preparar os dados para exportação CSV
  const csvData = sortedServiceOrders.map(order => {
    return {
      osId: order.ordemServico?.osId || 'Não Disponível',
      alertaId: order.alertaId || 'Não Disponível',
      osPosteId: order.ordemServico?.osPosteId || 'Não Disponível',
      osCidadeId: order.ordemServico?.osCidadeId || 'Não Disponível',
      osDataAbertura: order.ordemServico?.osDataAbertura
        ? new Date(order.ordemServico.osDataAbertura).toLocaleDateString()
        : 'Não Disponível',
      osHoraAbertura: order.ordemServico?.osHoraAbertura || 'Não Disponível',
      prestadorNome: getPrestadorNome(order.ordemServico?.osPrestadorId),
      statusNome: getSituacaoNome(order.ordemServico?.osSituacaoId),
      motivo: getMotivoFromAlerta(order.alertaId),
      osDataEncerramento: order.ordemServico?.osDataEncerramento
        ? new Date(order.ordemServico.osDataEncerramento).toLocaleDateString()
        : 'Não Encerrada',
      osHoraEncerramento: order.ordemServico?.osHoraEncerramento || 'Não Encerrada',
      osObs: order.ordemServico?.osObs || 'Nenhuma Observação', // Adicionando a observação
    };
  });

  // Renderizar o componente de carregamento se os dados estiverem sendo carregados
  if (isLoading || prestadoresLoading || situacoesLoading) {
    return <LoadingOverlay />;
  }

  // Renderizar mensagem de erro se houver problemas ao carregar os prestadores ou as situações
  if (prestadoresError) {
    return <div className={styles.error}>Erro ao carregar os prestadores: {prestadoresError}</div>;
  }

  if (situacoesError) {
    return <div className={styles.error}>Erro ao carregar as situações: {situacoesError}</div>;
  }

  // Renderizar mensagem de erro geral
  if (error) {
    return <div className={styles.error}>Erro ao carregar os dados: {error}</div>;
  }

  return (
    <PageLayout title="Lista de Ordens de Serviço">
      <div className={styles.container}>
        {/* Seção de Controle Superior: Botão de Exportação */}
        <div className={styles.controlsTop}>
          <CSVLink
            data={csvData}
            headers={headers}
            filename={"ordens-servico-export.csv"}
            className={styles.exportButton}
            bom="true"
            separator={";"}
            enclosingCharacter={`"`}
          >
            Exportar para CSV
          </CSVLink>
        </div>

        {/* Seção de Filtros e Pesquisa */}
        <div className={styles.controls}>
          {/* Campo de Pesquisa Geral */}
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Pesquisar por ID, Alerta, Cidade ou Poste..."
            value={searchTerm}
            onChange={handleSearchChange}
          />

          {/* Filtros Existentes */}
          <select
            className={`${styles.searchInput} ${styles.selectInput}`}
            value={statusFilter}
            onChange={handleStatusFilterChange}
          >
            <option value="">Filtrar por Situação...</option>
            {Object.entries(situacoes).map(([id, nome]) => (
              <option key={id} value={id}>
                {nome}
              </option>
            ))}
          </select>

          <select
            className={`${styles.searchInput} ${styles.selectInput}`}
            value={prestadorFilter}
            onChange={handlePrestadorFilterChange}
          >
            <option value="">Filtrar por Prestador...</option>
            {prestadores.map((prestador) => (
              <option key={prestador.pessoaId} value={prestador.pessoaId}>
                {prestador.pessoaNome}
              </option>
            ))}
          </select>
        </div>

        {/* Tabela de Ordens de Serviço */}
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th onClick={() => requestSort('ordemServico.osId')}>
                  ID {sortConfig.key === 'ordemServico.osId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('alertaId')}>
                  ID do Alerta {sortConfig.key === 'alertaId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('ordemServico.osPosteId')}>
                  Poste {sortConfig.key === 'ordemServico.osPosteId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('ordemServico.osCidadeId')}>
                  Cidade {sortConfig.key === 'ordemServico.osCidadeId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('ordemServico.osDataAbertura')}>
                  Data de Abertura {sortConfig.key === 'ordemServico.osDataAbertura' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('ordemServico.osHoraAbertura')}>
                  Hora de Abertura {sortConfig.key === 'ordemServico.osHoraAbertura' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('ordemServico.osPrestadorId')}>
                  Prestador {sortConfig.key === 'ordemServico.osPrestadorId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('ordemServico.osSituacaoId')}>
                  Status {sortConfig.key === 'ordemServico.osSituacaoId' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('ordemServico.osDataEncerramento')}>
                  Data de Encerramento {sortConfig.key === 'ordemServico.osDataEncerramento' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th onClick={() => requestSort('ordemServico.osHoraEncerramento')}>
                  Hora de Encerramento {sortConfig.key === 'ordemServico.osHoraEncerramento' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                </th>
                <th>Motivo</th> {/* Nova coluna para motivo */}
                <th>Observação</th> {/* Nova coluna para observação */}
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData.length > 0 ? (
                currentPageData.map((order) => (
                  <tr key={order.ordemServico?.osId || order.alertaId}>
                    <td>{order.ordemServico?.osId || 'Não Disponível'}</td>
                    <td>{order.alertaId || 'Não Disponível'}</td>
                    <td>{order.ordemServico?.osPosteId || 'Não Disponível'}</td>
                    <td>{order.ordemServico?.osCidadeId || 'Não Disponível'}</td>
                    <td>
                      {order.ordemServico?.osDataAbertura
                        ? new Date(order.ordemServico.osDataAbertura).toLocaleDateString()
                        : 'Não Disponível'}
                    </td>
                    <td>{order.ordemServico?.osHoraAbertura || 'Não Disponível'}</td>
                    <td>{getPrestadorNome(order.ordemServico?.osPrestadorId)}</td>
                    <td>{getSituacaoNome(order.ordemServico?.osSituacaoId)}</td>
                    <td>
                      {order.ordemServico?.osDataEncerramento
                        ? new Date(order.ordemServico.osDataEncerramento).toLocaleDateString()
                        : 'Não Encerrada'}
                    </td>
                    <td>
                      {order.ordemServico?.osHoraEncerramento || 'Não Encerrada'}
                    </td>
                    <td>{getMotivoFromAlerta(order.alertaId)}</td> {/* Mostrar o motivo do alerta */}
                    <td>{order.ordemServico?.osObs || 'Nenhuma Observação'}</td> {/* Mostrar a observação */}
                    <td>
                      {Number(order.ordemServico?.osSituacaoId) === 3 || Number(order.ordemServico?.osSituacaoId) === 4 ? (
                        // Botão de Visualizar com ícone de olho
                        <button
                          className={styles.actionButton}
                          onClick={() => handleActionClick(order, true)} // Passa true para indicar modo de visualização
                          title="Visualizar Ordem de Serviço"
                          aria-label="Visualizar Ordem de Serviço"
                        >
                          <FaEye />
                        </button>
                      ) : (
                        // Botão de Editar com ícone de lápis
                        <button
                          className={styles.actionButton}
                          onClick={() => handleActionClick(order, false)}
                          title="Editar Ordem de Serviço"
                          disabled={Number(order.ordemServico?.osSituacaoId) === 3 || Number(order.ordemServico?.osSituacaoId) === 4}
                          aria-disabled={Number(order.ordemServico?.osSituacaoId) === 3 || Number(order.ordemServico?.osSituacaoId) === 4}
                          aria-label={
                            Number(order.ordemServico?.osSituacaoId) === 3 || Number(order.ordemServico?.osSituacaoId) === 4
                              ? 'Editar Ordem de Serviço (Desativado)'
                              : 'Editar Ordem de Serviço'
                          }
                        >
                          <FaEdit />
                        </button>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="14" className={styles.noData}>Nenhuma ordem de serviço encontrada.</td> {/* Atualizado para 14 colunas */}
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Component de Paginação */}
        <div className={styles.paginationContainer}>
          <ReactPaginate
            previousLabel={"← Anterior"}
            nextLabel={"Próximo →"}
            breakLabel={"..."}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={styles.pagination}
            previousLinkClassName={styles.paginationLink}
            nextLinkClassName={styles.paginationLink}
            breakLinkClassName={styles.paginationLink}
            disabledClassName={styles.paginationDisabled}
            activeClassName={styles.paginationActive}
            forcePage={currentPage}
          />
        </div>

        {/* Modal de Edição de Ordem de Serviço */}
        {selectedOrder && selectedAlerta && (
          <EditServiceOrderModal
            isOpen={isModalOpen}
            onRequestClose={handleCloseModal}
            order={selectedOrder}
            alerta={selectedAlerta} // Passando os dados do alerta
            onSave={handleSaveOrder}
            isViewOnly={isViewOnly} // Passando o modo do modal
          />
        )}
      </div>
    </PageLayout>
  );
};

export default OrdensServicos;
