// src/components/Sidebar/SubMenu.jsx
import React from 'react';
import NavItem from './NavItem';
import styles from './Sidebar.module.css';

const SubMenu = ({ items }) => {
    return (
        <ul className={styles.subMenu}>
            {items.map((item, index) => (
                <NavItem
                    key={index}
                    to={item.to}
                    icon={item.icon}
                    label={item.label}
                    notification={item.notification}
                />
            ))}
        </ul>
    );
};

export default SubMenu;
