// src/pages/Postes/SubComponents/FotoPoste.jsx

import React from 'react';
import { FaTrash } from 'react-icons/fa';
import styles from '../PosteModal.module.css';

const FotoPoste = ({ selectedImage, handleImageUpload, handleImageClick }) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Foto do Poste</legend>
      <div className={styles.formGroup}>
        <input 
          type="file" 
          accept="image/*" 
          onChange={handleImageUpload} 
          className={styles.fileInput}
        />
        {selectedImage && (
          <div className={styles.imageContainer}>
            <img
              src={selectedImage}
              alt="Prévia da Foto do Poste"
              className={styles.previewImage}
              onClick={handleImageClick}
            />
            <button
              type="button"
              className={styles.removeImageButton}
              onClick={handleImageClick}
            >
              <FaTrash />
            </button>
          </div>
        )}
      </div>
    </fieldset>
  );
};

export default FotoPoste;
