import React from 'react';
import { FaSatellite, FaMap } from 'react-icons/fa';
import styles from './SatelliteToggleButton.module.css';

const SatelliteToggleButton = ({ isSatelliteView, toggleSatelliteView }) => {
  return (
    <button className={styles.button} onClick={toggleSatelliteView}>
      {isSatelliteView ? <FaMap className={styles.icon} /> : <FaSatellite className={styles.icon} />}
      <span>{isSatelliteView ? 'Modo Padrão' : 'Visão Satélite'}</span>
    </button>
  );
};

export default SatelliteToggleButton;
