// src/components/AlertasRelatorio/AlertasRelatorio.jsx

import React, { useState } from 'react';
import { FaFileExport } from 'react-icons/fa';
import useAlertasComOrdemServico from '../../hooks/useAlertasComOrdemServico';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';

const AlertasRelatorio = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [showWithOSOnly, setShowWithOSOnly] = useState(false);
  const { alertasComOS, isLoading, error } = useAlertasComOrdemServico();

  const filteredAlertas = alertasComOS.filter((alerta) => {
    const alertDateTime = new Date(`${alerta.alertaDataGeracao}T${alerta.alertaHoraGeracao}`);
    const startDateTime = startDate ? new Date(`${startDate}T${startTime || '00:00'}`) : null;
    const endDateTime = endDate ? new Date(`${endDate}T${endTime || '23:59'}`) : null;

    const isAfterStart = startDateTime ? alertDateTime >= startDateTime : true;
    const isBeforeEnd = endDateTime ? alertDateTime <= endDateTime : true;
    const hasOrderService = showWithOSOnly ? !!alerta.ordemServico : true;

    return isAfterStart && isBeforeEnd && hasOrderService;
  });

  const handleExportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredAlertas);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Alertas');
    XLSX.writeFile(workbook, 'relatorio_alertas.xlsx');
  };

  return (
    <div className={styles.container}>
      <div className={styles.controlsTop}>
        {/* Filtro de Data/Hora de Geração */}
        <div className={styles.filterGroup}>
          <h3>Filtro de Geração</h3>
          <div className={styles.controlGroup}>
            <label htmlFor="startDate">Data Inicial:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={styles.dateInput}
            />
          </div>
          <div className={styles.controlGroup}>
            <label htmlFor="startTime">Hora Inicial:</label>
            <input
              type="time"
              id="startTime"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className={styles.timeInput}
            />
          </div>
          <div className={styles.controlGroup}>
            <label htmlFor="endDate">Data Final:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={styles.dateInput}
            />
          </div>
          <div className={styles.controlGroup}>
            <label htmlFor="endTime">Hora Final:</label>
            <input
              type="time"
              id="endTime"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className={styles.timeInput}
            />
          </div>

          {/* Checkbox para mostrar apenas alertas com OS */}
          <div className={styles.checkboxGroup}>
            <label htmlFor="showWithOSOnly">
              <input
                type="checkbox"
                id="showWithOSOnly"
                checked={showWithOSOnly}
                onChange={(e) => setShowWithOSOnly(e.target.checked)}
              />
              Mostrar apenas alertas com ordem de serviço
            </label>
          </div>
        </div>

        {/* Botão de exportação */}
        <div className={styles.actionsContainer}>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            <FaFileExport /> Exportar para Excel
          </button>
        </div>
      </div>

      {/* Mensagens de carregamento e erro */}
      {isLoading && <p className={styles.infoMessage}>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}

      {/* Tabela de resultados filtrados */}
      {!isLoading && filteredAlertas.length > 0 && (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Motivo</th>
                <th>Data Geração</th>
                <th>Hora Geração</th>
                <th>Origem</th>
                <th>Telefone</th>
                <th>OS ID</th>
                <th>Data Abertura OS</th>
                <th>Hora Abertura OS</th>
                <th>Situação OS</th>
                <th>Data Encerramento OS</th>
                <th>Hora Encerramento OS</th>
              </tr>
            </thead>
            <tbody>
              {filteredAlertas.map((alerta) => (
                <tr key={alerta.alertaId}>
                  <td>{alerta.alertaId}</td>
                  <td>{alerta.alertaMotivo}</td>
                  <td>{alerta.alertaDataGeracao}</td>
                  <td>{alerta.alertaHoraGeracao}</td>
                  <td>{alerta.alertaOrigem}</td>
                  <td>{alerta.alertaTelefone}</td>
                  <td>{alerta.ordemServico?.osId || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osDataAbertura || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osHoraAbertura || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osSituacaoId || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osDataEncerramento || 'N/A'}</td>
                  <td>{alerta.ordemServico?.osHoraEncerramento || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!isLoading && filteredAlertas.length === 0 && (
        <p className={styles.infoMessage}>Nenhum alerta encontrado para o período selecionado.</p>
      )}
    </div>
  );
};

export default AlertasRelatorio;
