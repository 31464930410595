// src/pages/Relatorios/Relatorios.jsx

import React, { useState } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import AlertasRelatorio from './AlertasRelatorio';
import OrdensServicoRelatorio from './OrdensServicoRelatorio';
import RelatorioInventarioPostes from './RelatorioInventarioPostes';
import RelatorioPostesGeolocalizacao from './RelatorioPostesGeolocalizacao';
import UltimasLeiturasDispositivos from './UltimasLeiturasDispositivos';
import RelatorioConsumoPorPoste from './RelatorioConsumoPorPoste'; // Importar o novo relatório
import styles from './Relatorios.module.css';
import RelatorioPostesComFoto from './RelatorioPostesComFoto';

const Relatorios = () => {
  const [mainCategory, setMainCategory] = useState('');
  const [posteReportType, setPosteReportType] = useState('');

  return (
    <PageLayout title="Relatórios">
      <div className={styles.container}>
        <div className={styles.controlsTop}>
          <div className={styles.inputsContainer}>
            {/* Primeiro dropdown para categoria principal */}
            <div className={styles.controlGroup}>
              <label htmlFor="mainCategory">Categoria:</label>
              <select
                id="mainCategory"
                value={mainCategory}
                onChange={(e) => {
                  setMainCategory(e.target.value);
                  setPosteReportType(''); // Resetar o segundo dropdown ao mudar a categoria
                }}
                className={styles.dropdown}
              >
                <option value="">Selecione a categoria</option>
                <option value="postes">Postes</option>
                <option value="alertas">Alertas</option>
                <option value="ordensServico">Ordens de Serviço</option>
              </select>
            </div>

            {/* Segundo dropdown para tipos de relatórios de postes */}
            {mainCategory === 'postes' && (
              <div className={styles.controlGroup}>
                <label htmlFor="posteReportType">Tipo de Relatório de Postes:</label>
                <select
                  id="posteReportType"
                  value={posteReportType}
                  onChange={(e) => setPosteReportType(e.target.value)}
                  className={styles.dropdown}
                >
                  <option value="">Selecione o tipo de relatório</option>
                  <option value="inventarioPostes">Inventário de Postes</option>
                  <option value="postesGeolocalizados">Postes Geolocalizados</option>
                  <option value="ultimasLeiturasDispositivos">Últimas Leituras dos Dispositivos</option>
                  <option value="consumoPorPoste">Consumo por Poste</option>
                  <option value="postesComFoto">Postes com Foto</option>
                </select>
              </div>
            )}
          </div>
        </div>

        {/* Renderização dos relatórios de postes */}
        {posteReportType === 'inventarioPostes' && <RelatorioInventarioPostes />}
        {posteReportType === 'postesGeolocalizados' && <RelatorioPostesGeolocalizacao />}
        {posteReportType === 'ultimasLeiturasDispositivos' && <UltimasLeiturasDispositivos />}
        {posteReportType === 'consumoPorPoste' && <RelatorioConsumoPorPoste />}
        {posteReportType === 'postesComFoto' && <RelatorioPostesComFoto />}

        {/* Renderização dos outros relatórios */}
        {mainCategory === 'alertas' && <AlertasRelatorio />}
        {mainCategory === 'ordensServico' && <OrdensServicoRelatorio />}

        {/* Mensagens de orientação */}
        {mainCategory === '' && (
          <p className={styles.infoMessage}>
            Selecione uma categoria para ver os tipos de relatórios disponíveis.
          </p>
        )}
        {mainCategory === 'postes' && posteReportType === '' && (
          <p className={styles.infoMessage}>
            Selecione o tipo de relatório de postes.
          </p>
        )}
      </div>
    </PageLayout>
  );
};

export default Relatorios;
