// src/pages/Postes/SubComponents/Transformador.jsx

import React from 'react';
import { FaBolt, FaTag, FaBatteryFull } from 'react-icons/fa'; // Adicionei FaBatteryFull como o ícone para "Possui Transformador"
import styles from '../PosteModal.module.css';

const Transformador = ({ formData, handleChange }) => {
  // Derivar se possui transformador a partir do formData
  const possuiTransformador = formData.transformador === '1';

  // Função para lidar com a mudança do dropdown
  const handleDropdownChange = (e) => {
    const value = e.target.value;
    handleChange({
      target: {
        name: 'transformador',
        value: value,
      },
    });

    if (value === '0') { // "Não" selecionado
      handleChange({
        target: {
          name: 'alimentacaoTransformador',
          value: null, // Definir como null
        },
      });
      handleChange({
        target: {
          name: 'numeroPlaquetaTransformador',
          value: '', // Definir como string vazia
        },
      });
    }
  };

  return (
    <fieldset className={styles.fieldset}>
      <legend>Transformador</legend>
      <div className={styles.formGroup}>
        {/* Dropdown para indicar se possui transformador */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaBatteryFull /> Possui Transformador
          </label>
          <select
            className={`${styles.select} ${styles.smallSelect}`} // Adicionei uma classe customizada para reduzir o tamanho
            name="transformador"
            value={formData.transformador}
            onChange={handleDropdownChange}
            required
          >
            <option value="">Selecione</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </select>
        </div>

        {/* Renderizar campos dependentes apenas se "Sim" estiver selecionado */}
        {possuiTransformador && (
          <>
            {/* Alimentação Transformador */}
            <div className={styles.colSpan2}>
              <label className={styles.label}><FaBolt /> Alimentação Transformador</label>
              <select
                className={styles.select}
                name="alimentacaoTransformador"
                value={formData.alimentacaoTransformador || ''}
                onChange={handleChange}
                required={possuiTransformador}
              >
                <option value="">Selecione</option>
                <option value="220">220V</option>
                <option value="380">380V</option>
              </select>
            </div>

            {/* Número Plaqueta Transformador */}
            <div className={styles.colSpan1}>
              <label className={styles.label}><FaTag /> Número Plaqueta Transformador</label>
              <input
                className={styles.input}
                type="text"
                name="numeroPlaquetaTransformador"
                value={formData.numeroPlaquetaTransformador || ''}
                onChange={handleChange}
                required={possuiTransformador}
              />
            </div>
          </>
        )}
      </div>
    </fieldset>
  );
};

export default Transformador;
