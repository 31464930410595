import axios from "axios"; /* Importa Axios para fazer REQUEST HTTP */

const BASE_URL = "https://api.ads10.com.br/api";


// Realiza a verificação do token
function VerificarToken() {
    const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

    if (token === null) {
        //window.location.href = "/login";
        return;
    }

    return token;
}


export const PostBracoDimensaoCreate = async (lampada) => {
    const token = VerificarToken();

    const response = await fetch(BASE_URL + '/bracodimensao', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'CidadeId': 3315,
        },
        body: JSON.stringify(lampada),
    });


    if (response.ok) {
        if (response.body != null){
            const data = await response.json();
            return data;
        }

        return null;

        // Redireciona para o dashboard após o login sem remover o loading
        //navigate('/dashboard');
    } else {
        const data = await response.json();
        throw data.error;
    }
};

export const GetBracoDimensoes = async (id) => {
    const token = VerificarToken();

    if (id > 0) {
        const response = await axios.get(BASE_URL + '/bracodimensao/' + id);
        return response.data;
    }
    else {
        const response = await fetch(BASE_URL + '/bracodimensao', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'CidadeId': 3315,
            },
        });

        if (response.ok) {
            if (response.body != null){
                const data = await response.json();
                return data;
            }

            return null;

            // Redireciona para o dashboard após o login sem remover o loading
            //navigate('/dashboard');
        } else {
            const data = await response.json();
            throw data.error;
        }
    }
};

//const response = await axios.put(BASE_URL + '/lampadatipo/' + id, servico);

export const PutBracoDimensao = async (id, lampada) => {
    const token = VerificarToken();

    const response = await fetch(BASE_URL + '/bracodimensao/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'CidadeId': 3315,
        },
        body: JSON.stringify(lampada),
    });


    if (response.ok) {
        if (response.body != null){
            const data = await response.json();
            return data;
        }

        return null;

        // Redireciona para o dashboard após o login sem remover o loading
        //navigate('/dashboard');
    } else {
        const data = await response.json();
        throw data.error;
    }
};

export const DeleteBracoDimensao = async (id) => {
    const token = VerificarToken();

    const response = await fetch(BASE_URL + '/bracodimensao/'+id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'CidadeId': 3315,
        },
    });

    if (response.ok) {
        return;
    } else {
        throw response.error;
    }
}