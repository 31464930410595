// src/hooks/useDevicesSemPoste.jsx

import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useDevicesSemPoste = () => {
  const { token, cidadeId } = useContext(AuthContext);
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDevices = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.ads10.com.br/api/fotocelulassemposte', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'cidadeId': cidadeId, // Certifique-se de que o cabeçalho está correto
        },
      });

      const contentType = response.headers.get('content-type');

      if (!response.ok) {
        let errorMessage = `Erro ao buscar dispositivos sem poste: ${response.status} ${response.statusText}`;
        // Tenta extrair mensagem de erro do corpo
        if (contentType && contentType.includes('application/json')) {
          const errorData = await response.json();
          errorMessage += ` - ${errorData.message || 'Erro desconhecido'}`;
        } else {
          const errorText = await response.text();
          errorMessage += ` - ${errorText}`;
        }
        throw new Error(errorMessage);
      }

      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();

        // Filtra dispositivos com localização válida (não "0,0")
        const validDevices = data.filter(
          (device) => device.latitude !== '0' && device.longitude !== '0'
        );

        setDevices(validDevices);
      } else {
        // Resposta não é JSON
        const errorText = await response.text();
        throw new Error(`Resposta inesperada da API: ${errorText}`);
      }
    } catch (err) {
      console.error('Erro ao buscar dispositivos sem poste:', err);
      setError(err.message || 'Erro desconhecido');
      toast.error(`Erro ao buscar dispositivos sem poste: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  return { devices, isLoading, error, refetch: fetchDevices };
};

export default useDevicesSemPoste;
