import React, { useEffect, useState } from 'react';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';

const RelatorioPostesGeolocalizacao = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [filter, setFilter] = useState('all');
  const [filteredPostes, setFilteredPostes] = useState([]);

  useEffect(() => {
    if (filter === 'geolocalizados') {
      setFilteredPostes(postesDetalhados.filter((poste) => poste.geoLocalizacao));
    } else if (filter === 'nao-geolocalizados') {
      setFilteredPostes(postesDetalhados.filter((poste) => !poste.geoLocalizacao));
    } else {
      setFilteredPostes(postesDetalhados);
    }
  }, [filter, postesDetalhados]);

  return (
    <div className={styles.container}>
      <h2>Relatório de Postes com Filtragem de Geolocalização</h2>
      <div className={styles.filterContainer}>
        <label htmlFor="geoFilter">Filtrar por geolocalização:</label>
        <select
          id="geoFilter"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className={styles.dropdown}
        >
          <option value="all">Exibir Todos</option>
          <option value="geolocalizados">Somente Geolocalizados</option>
          <option value="nao-geolocalizados">Somente Não Geolocalizados</option>
        </select>
      </div>
      {isLoading && <p>Carregando...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {!isLoading && filteredPostes.length > 0 && (
        <table className={styles.table}>
          <thead>
            <tr>
              <th>ID</th>
              <th>Código</th>
              <th>Geo-localização</th>
              <th>Tipo</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredPostes.map((poste) => (
              <tr key={poste.id}>
                <td>{poste.id}</td>
                <td>{poste.codigoPoste}</td>
                <td>{poste.geoLocalizacao || 'N/A'}</td>
                <td>{poste.tipoPoste || 'Desconhecido'}</td>
                <td>{poste.ativo === 'S' ? 'Ativo' : 'Inativo'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isLoading && filteredPostes.length === 0 && (
        <p>Nenhum dado encontrado para a opção selecionada.</p>
      )}
    </div>
  );
};

export default RelatorioPostesGeolocalizacao;
