import axios from "axios"; /* Importa Axios para fazer REQUEST HTTP */

const BASE_URL = "https://api.ads10.com.br/api";


// Realiza a verificação do token
function VerificarToken() {
    const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');

    if (token === null) {
        //window.location.href = "/login";
        return;
    }

    return token;
}


export const PostTipoViaCreate = async (tipovia) => {
    const token = VerificarToken();

    const response = await fetch(BASE_URL + '/tipovia', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'CidadeId': 3315,
        },
        body: JSON.stringify(tipovia),
    });

    const data = await response.json();

    if (response.ok) {
        return data;

        // Redireciona para o dashboard após o login sem remover o loading
        //navigate('/dashboard');
    } else {
        throw data.error;
    }
};

export const GetTipoVias = async (id) => {
    const token = VerificarToken();

    if (id > 0) {
        const response = await axios.get(BASE_URL + '/tipovia/' + id);
        return response.data;
    }
    else {
        const response = await fetch(BASE_URL + '/tipovia', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'CidadeId': 3315,
            },
        });

        const data = await response.json();

        if (response.ok) {
            return data;

            // Redireciona para o dashboard após o login sem remover o loading
            //navigate('/dashboard');
        } else {
            throw data.error;
        }
    }
};

//const response = await axios.put(BASE_URL + '/tipovia/' + id, servico);

export const PutTipoVia = async (id, tipovia) => {
    const token = VerificarToken();

    const response = await fetch(BASE_URL + '/tipovia/' + id, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'CidadeId': 3315,
        },
        body: JSON.stringify(tipovia),
    });

    const data = await response.json();

    if (response.ok) {
        return data;

        // Redireciona para o dashboard após o login sem remover o loading
        //navigate('/dashboard');
    } else {
        throw data.error;
    }
};

export const DeleteTipoVia = async (id) => {
    const token = VerificarToken();

    const response = await fetch(BASE_URL + '/tipovia/'+id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            'CidadeId': 3315,
        },
    });

    if (response.ok) {
        return;
    } else {
        throw response.error;
    }
}