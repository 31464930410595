import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import deviceLogs from '../../../data/logs.json'; // Verifique se o caminho do arquivo está correto
import markers from '../../../data/markers.json'; // Verifique se o caminho do arquivo está correto
import groupsData from '../../../data/groups.json'; // Verifique se o caminho do arquivo está correto
import styles from '../Levantamentos.module.css';

const GraficoLogsDispositivos = ({
  startDate, endDate, startTime, endTime, selectedGroup, selectedPoste, selectedDispositivo, selectedMetric, aggregateType
}) => {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filterLogsByDate = (logs) => {
      return logs.filter(log => {
        const logDate = new Date(log.data);
        const startDateObj = startDate ? new Date(`${startDate}T${startTime || '00:00'}`) : null;
        const endDateObj = endDate ? new Date(`${endDate}T${endTime || '23:59'}`) : null;
        return (!startDateObj || logDate >= startDateObj) && (!endDateObj || logDate <= endDateObj);
      });
    };

    const getFilteredLogs = () => {
      const relevantMarkers = selectedGroup
        ? markers.filter(marker => groupsData.find(group => group.id.toString() === selectedGroup)?.postes.includes(marker.idPoste))
        : markers;

      const markersToUse = selectedPoste !== 'all'
        ? relevantMarkers.filter(marker => marker?.idPoste.toString() === selectedPoste)
        : relevantMarkers;

      let aggregatedLogs = [];
      markersToUse.forEach(poste => {
        if (poste && Array.isArray(poste.luminarias)) {
          poste.luminarias.forEach(luminaria => {
            if (selectedDispositivo === 'all' || luminaria.dispositivoEUI === selectedDispositivo) {
              const deviceLog = deviceLogs.find(device => device.dispositivoEUI === luminaria.dispositivoEUI);
              if (deviceLog && Array.isArray(deviceLog.logs)) {
                const filteredLogs = filterLogsByDate(deviceLog.logs);
                filteredLogs.forEach(log => {
                  if (log[selectedMetric] !== undefined && log.data) {
                    aggregatedLogs.push({
                      data: log.data,
                      value: log[selectedMetric]
                    });
                  }
                });
              }
            }
          });
        }
      });

      if (aggregateType === 'average') {
        const dateMap = aggregatedLogs.reduce((acc, log) => {
          const date = log.data.split('T')[0];
          if (!acc[date]) {
            acc[date] = { total: 0, count: 0 };
          }
          acc[date].total += log.value;
          acc[date].count += 1;
          return acc;
        }, {});

        return Object.keys(dateMap).map(date => ({
          data: date,
          value: parseFloat((dateMap[date].total / dateMap[date].count).toFixed(2))
        }));
      } else if (aggregateType === 'sum') {
        const dateMap = aggregatedLogs.reduce((acc, log) => {
          const date = log.data.split('T')[0];
          if (!acc[date]) {
            acc[date] = { total: 0 };
          }
          acc[date].total += log.value;
          return acc;
        }, {});

        return Object.keys(dateMap).map(date => ({
          data: date,
          value: parseFloat(dateMap[date].total.toFixed(2))
        }));
      }

      return aggregatedLogs;
    };

    setFilteredData(getFilteredLogs());
  }, [startDate, endDate, startTime, endTime, selectedGroup, selectedPoste, selectedDispositivo, selectedMetric, aggregateType]);

  return (
    <div className={styles.chartCard}>
      <h3 className={styles.chartTitle}>Logs de Leituras dos Dispositivos</h3>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={filteredData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="data" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="value" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GraficoLogsDispositivos;
