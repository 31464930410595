// src/pages/Postes/SubComponents/MapModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaDirections } from 'react-icons/fa';
import { GoogleMap, Marker } from '@react-google-maps/api';
import styles from './MapModal.module.css'; // Importar o novo CSS Module

// Define o container style para o mapa
const containerStyle = {
  width: '100%',
  height: '100%', // Alterado para preencher o container
};

// Define as opções do mapa
const options = {
  disableDefaultUI: true,
  zoomControl: true,
};

const MapModal = ({ isOpen, onRequestClose, latitude, longitude }) => {
  // Estado para controlar a animação do marcador
  const [markerAnimation, setMarkerAnimation] = useState(null);

  // Verifica se as coordenadas são válidas
  const isValidCoordinates = (lat, lng) => {
    const latNum = parseFloat(lat);
    const lngNum = parseFloat(lng);
    return (
      !isNaN(latNum) &&
      !isNaN(lngNum) &&
      latNum >= -90 &&
      latNum <= 90 &&
      lngNum >= -180 &&
      lngNum <= 180
    );
  };

  const center = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };

  // useEffect para iniciar e parar a animação do marcador
  useEffect(() => {
    let timer;
    if (isOpen && isValidCoordinates(latitude, longitude)) {
      // Iniciar a animação
      setMarkerAnimation(window.google.maps.Animation.BOUNCE);

      // Parar a animação após 1 segundo
      timer = setTimeout(() => {
        setMarkerAnimation(null);
      }, 1000);
    }

    // Limpeza do timer quando o componente for desmontado ou o modal fechar
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isOpen, latitude, longitude]);

  // Função para obter a localização atual do usuário
  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocalização não é suportada pelo seu navegador.'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => {
            reject(new Error('Não foi possível obter a sua localização.'));
          }
        );
      }
    });
  };

  // Função para abrir o Google Maps com a rota
  const handleGetDirections = async () => {
    try {
      const userLocation = await getUserLocation();
      const origin = `${userLocation.lat},${userLocation.lng}`;
      const destination = `${latitude},${longitude}`;
      const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=driving`;

      window.open(googleMapsUrl, '_blank');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Localização do Poste"
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false} // Adicione esta linha se você não configurou o elemento root para acessibilidade
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>Localização do Poste</h2>
        {isValidCoordinates(latitude, longitude) ? (
          <>
            <div className={styles.mapContainer}>
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                options={options}
              >
                <Marker
                  position={center}
                  animation={markerAnimation}
                />
              </GoogleMap>
            </div>
            <div className={styles.directionsButtonContainer}>
              <button className={styles.directionsButton} onClick={handleGetDirections}>
                <FaDirections style={{ marginRight: '8px' }} /> Obter Rota
              </button>
            </div>
          </>
        ) : (
          <div>Coordenadas inválidas fornecidas.</div>
        )}
      </div>
    </Modal>
  );
};

export default MapModal;
