// src/pages/Levantamentos/Levantamentos.jsx

import React, { useState, useEffect } from 'react';
import PageLayout from '../../components/PageLayout/PageLayout';
import GraficoAlertas from './graficos/GraficoAlertas';
import GraficoOrdensServico from './graficos/GraficoOrdensServico';
import GraficoLogsDispositivos from './graficos/GraficoLogsDispositivos';
import markers from '../../data/markers.json';
import groupsData from '../../data/groups.json';
import styles from './Levantamentos.module.css';

const Levantamentos = () => {
  const [dataType, setDataType] = useState('logs');
  const [chartType, setChartType] = useState('line');
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedPoste, setSelectedPoste] = useState('all');
  const [selectedDispositivo, setSelectedDispositivo] = useState('all');
  const [selectedMetric, setSelectedMetric] = useState('tensaoRede');
  const [aggregateType, setAggregateType] = useState('average');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  useEffect(() => {
    if (selectedGroup) {
      setSelectedPoste('all');
      setSelectedDispositivo('all');
    }
  }, [selectedGroup]);

  const getGroupOptions = () => {
    return groupsData.map((group) => ({
      value: group.id.toString(),
      label: group.name
    }));
  };

  const getPosteOptions = () => {
    const relevantMarkers = selectedGroup 
      ? markers.filter(marker => groupsData.find(group => group.id.toString() === selectedGroup)?.postes.includes(marker.idPoste))
      : markers;

    return relevantMarkers.map((poste) => ({
      value: poste.idPoste.toString(),
      label: `Poste ${poste.idPoste}`
    }));
  };

  const getDispositivoOptions = () => {
    if (selectedPoste === 'all') return [];
    const poste = markers.find((poste) => poste.idPoste.toString() === selectedPoste);
    return poste ? poste.luminarias.map((luminaria) => ({
      value: luminaria.dispositivoEUI,
      label: `Dispositivo ${luminaria.dispositivoEUI}`
    })) : [];
  };

  const renderGrafico = () => {
    if (dataType === 'alerts') {
      return <GraficoAlertas />;
    } else if (dataType === 'orders') {
      return <GraficoOrdensServico />;
    } else if (dataType === 'logs') {
      return (
        <GraficoLogsDispositivos
          startDate={startDate}
          endDate={endDate}
          startTime={startTime}
          endTime={endTime}
          selectedGroup={selectedGroup}
          selectedPoste={selectedPoste}
          selectedDispositivo={selectedDispositivo}
          selectedMetric={selectedMetric}
          aggregateType={aggregateType}
        />
      );
    }
  };

  return (
    <PageLayout title="Levantamentos">
      <div className={styles.levantamentosPage}>
        <div className={styles.controls}>
          <div className={styles.controlGroup}>
            <label htmlFor="dataType">Tipo de Dados:</label>
            <select
              id="dataType"
              value={dataType}
              onChange={(e) => {
                setDataType(e.target.value);
                setChartType(''); // Reset chart type on data type change
              }}
              className={styles.selectInput}
            >
              <option value="logs">Logs de Dispositivos</option>
              <option value="alerts">Alertas</option>
              <option value="orders">Ordens de Serviço</option>
            </select>
          </div>

          {dataType === 'logs' && (
            <>
              <div className={styles.controlGroup}>
                <label htmlFor="chartType">Tipo de Gráfico:</label>
                <select
                  id="chartType"
                  value={chartType}
                  onChange={(e) => setChartType(e.target.value)}
                  className={styles.selectInput}
                >
                  <option value="line">Gráfico de Linhas</option>
                </select>
              </div>
              <div className={styles.controlGroup}>
                <label htmlFor="selectedGroup">Grupo:</label>
                <select
                  id="selectedGroup"
                  value={selectedGroup}
                  onChange={(e) => setSelectedGroup(e.target.value)}
                  className={styles.selectInput}
                >
                  <option value="">Selecione um grupo</option>
                  {getGroupOptions().map((group) => (
                    <option key={group.value} value={group.value}>{group.label}</option>
                  ))}
                </select>
              </div>
              <div className={styles.controlGroup}>
                <label htmlFor="poste">Poste:</label>
                <select
                  id="poste"
                  value={selectedPoste}
                  onChange={(e) => setSelectedPoste(e.target.value)}
                  className={styles.selectInput}
                >
                  <option value="all">Todos</option>
                  {getPosteOptions().map((poste) => (
                    <option key={poste.value} value={poste.value}>{poste.label}</option>
                  ))}
                </select>
              </div>
              {(selectedPoste !== 'all' || selectedDispositivo === 'all') && (
                <div className={styles.controlGroup}>
                  <label htmlFor="dispositivo">Dispositivo:</label>
                  <select
                    id="dispositivo"
                    value={selectedDispositivo}
                    onChange={(e) => setSelectedDispositivo(e.target.value)}
                    className={styles.selectInput}
                  >
                    <option value="all">Todos</option>
                    {getDispositivoOptions().map((dispositivo) => (
                      <option key={dispositivo.value} value={dispositivo.value}>{dispositivo.label}</option>
                    ))}
                  </select>
                </div>
              )}
              <div className={styles.controlGroup}>
                <label htmlFor="aggregateType">Tipo de Agregação:</label>
                <select
                  id="aggregateType"
                  value={aggregateType}
                  onChange={(e) => setAggregateType(e.target.value)}
                  className={styles.selectInput}
                >
                  <option value="average">Média</option>
                  <option value="sum">Soma</option>
                </select>
              </div>
              <div className={styles.controlGroup}>
                <label htmlFor="metric">Métrica:</label>
                <select
                  id="metric"
                  value={selectedMetric}
                  onChange={(e) => setSelectedMetric(e.target.value)}
                  className={styles.selectInput}
                >
                  <option value="tensaoRede">Tensão</option>
                  <option value="potenciaAtual">Potência</option>
                  <option value="consumo">Consumo</option>
                  <option value="corrente">Corrente</option>
                  <option value="isLigado">ON/OFF</option>
                </select>
              </div>
            </>
          )}

          <div className={styles.controlGroup}>
            <label htmlFor="startDate">Data Inicial:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className={styles.inputField}
            />
          </div>
          <div className={styles.controlGroup}>
            <label htmlFor="startTime">Hora Inicial:</label>
            <input
              type="time"
              id="startTime"
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              className={styles.inputField}
            />
          </div>
          <div className={styles.controlGroup}>
            <label htmlFor="endDate">Data Final:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className={styles.inputField}
            />
          </div>
          <div className={styles.controlGroup}>
            <label htmlFor="endTime">Hora Final:</label>
            <input
              type="time"
              id="endTime"
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              className={styles.inputField}
            />
          </div>
        </div>

        <div className={styles.chartContainer}>
          {renderGrafico()}
        </div>
      </div>
    </PageLayout>
  );
};

export default Levantamentos;
