import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const usePrestadores = () => {
  const { token, cidadeId, logout } = useContext(AuthContext); // Inclui cidadeId
  const [prestadores, setPrestadores] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrestadores = async () => {
      setIsLoading(true);
      setError(null);

      try {
        if (!token || !cidadeId) throw new Error('Token de autenticação ou ID da cidade não encontrado');

        const response = await fetch('https://api.ads10.com.br/api/pessoa', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId, // Adiciona o ID da cidade nos cabeçalhos
          },
        });

        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout();
          return;
        }

        if (!response.ok) throw new Error('Erro ao buscar os prestadores');

        const data = await response.json();
        // Filtrar apenas as pessoas que têm pessoaPrestador = "s"
        const prestadoresFiltrados = data.filter((pessoa) => pessoa.pessoaPrestador === 's');
        setPrestadores(prestadoresFiltrados);
      } catch (err) {
        console.error('Erro ao buscar prestadores:', err);
        setError(err.message);
        toast.error(`Erro ao buscar prestadores: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPrestadores();
  }, [token, cidadeId, logout]); // Inclui cidadeId como dependência

  return { prestadores, isLoading, error };
};

export default usePrestadores;
