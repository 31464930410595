import React, { useState, useEffect } from 'react';
import { FaIdCard, FaLightbulb, FaInfoCircle, FaTimes, FaTag } from 'react-icons/fa';
import Modal from 'react-modal';
import styles from './LampadaModal.module.css';

Modal.setAppElement('#root');
/*
{
    "ltId": "1",
    "ltTipo": "FL 7",
    "ltPotencia": "7.00",
    "ltPerdas": "0.00",
    "ltConsumo": "7.00",
    "ltValorkwh": "0.57"
}
*/
const LampadaModal = ({ isOpen, onRequestClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    ltId: '',
    ltTipo: '',
    ltPotencia: 0,
    ltPerdas: 0,
    ltConsumo: 0,
    ltValorkwh: 0,
  });

  useEffect(() => {
    if (initialData) {
      const formattedData = {
        ...initialData,
        ltId: initialData.ltId,
      };
      setFormData(formattedData);
    }else{
      setFormData({
        ltId: '',
        ltTipo: '',
        ltPotencia: 0,
        ltPerdas: 0,
        ltConsumo: 0,
        ltValorkwh: 0,
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData });
    onRequestClose();
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Informações de Poste"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.postePage}>
        <h1 className={styles.header}>Especificações</h1>
        <form onSubmit={handleSubmit}>
          <fieldset className={styles.fieldset}>
            <div className={styles.formGroup}>
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaIdCard /> ID</label>
                <input
                  className={styles.input}
                  type="text"
                  name="ltId"
                  value={formData.ltId}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLightbulb /> Tipo</label>
                <input
                  className={styles.input}
                  type="text"
                  name="ltTipo"
                  value={formData.ltTipo}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaInfoCircle /> Potencia</label>
                <input
                  className={styles.input}
                  type="text"
                  name="ltPotencia"
                  value={formData.ltPotencia}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaTag /> Perdas</label>
                <input
                  className={styles.input}
                  type="text"
                  name="ltPerdas"
                  value={formData.ltPerdas}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaInfoCircle /> Consumo</label>
                <input
                  className={styles.input}
                  type="text"
                  name="ltConsumo"
                  value={formData.ltConsumo}
                  onChange={handleChange}
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaInfoCircle /> Valor kWh</label>
                <input
                  className={styles.input}
                  type="text"
                  name="ltValorkwh"
                  value={formData.ltValorkwh}
                  onChange={handleChange}
                />
              </div>
            </div>
          </fieldset>

          <button className={styles.button} type="submit">Salvar</button>
        </form>

      </div>
    </Modal>
  );
};

export default LampadaModal;
