import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de regiões com base no país, estado e cidade selecionados.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @param {string} selectedPais - ID do país selecionado.
 * @param {string} selectedEstado - ID do estado selecionado.
 * @param {string} selectedCidade - ID da cidade selecionada.
 * @returns {object} - Contém a lista de regiões, estado de carregamento, erro e função para refetch.
 */
const useRegiao = (isOpen, selectedPais, selectedEstado, selectedCidade) => {
  const { token, logout } = useContext(AuthContext); // Usa o contexto para token e logout
  const [regioes, setRegioes] = useState([]);
  const [isRegiaoLoading, setIsRegiaoLoading] = useState(false);
  const [regiaoError, setRegiaoError] = useState(null);

  /**
   * Função para buscar as regiões da API com base nos parâmetros selecionados.
   */
  const fetchRegiao = useCallback(async () => {
    if (!isOpen || !selectedPais || !selectedEstado || !selectedCidade) return;

    setIsRegiaoLoading(true);
    setRegiaoError(null);

    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const url = new URL('https://api.ads10.com.br/api/regiao');
      url.searchParams.append('pais', selectedPais);
      url.searchParams.append('estado', selectedEstado);
      url.searchParams.append('cidade', selectedCidade);

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar regiões: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Regiões recebidas da API:', data); // Log para depuração
      setRegioes(data);
    } catch (error) {
      console.error('Erro ao buscar regiões:', error);
      setRegiaoError('Falha ao carregar a lista de regiões. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(`Erro ao buscar regiões: ${error.message}`);
    } finally {
      setIsRegiaoLoading(false);
    }
  }, [isOpen, selectedPais, selectedEstado, selectedCidade, token, logout]);

  useEffect(() => {
    fetchRegiao();
  }, [fetchRegiao]);

  return { regioes, isRegiaoLoading, regiaoError, refetch: fetchRegiao };
};

export default useRegiao;
