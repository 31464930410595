// src/pages/Relatorios/RelatorioPostesComFoto.jsx

import React, { useState, useEffect, forwardRef } from 'react';
import styles from './Relatorios.module.css';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';

const RelatorioPostesComFoto = forwardRef((props, ref) => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [postesComFoto, setPostesComFoto] = useState([]);

  useEffect(() => {
    // Construir as informações dos postes com URL da imagem
    const postesComImagens = postesDetalhados.map((poste) => ({
      codigoPoste: poste.codigoPoste || 'N/A',
      address: poste.address || 'N/A',
      image: `https://ceasa.ads10.com.br/images/${poste.codigoPoste}.jpg`,
      tipo: poste.tipoPoste || 'N/A',
      consumo: poste.consumo || 'N/A',
      potencia: poste.potencia || 'N/A',
    }));

    setPostesComFoto(postesComImagens);
  }, [postesDetalhados]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className={styles.container}>
      <h2>Relatório de Postes com Foto</h2>
      {isLoading && <p>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {!isLoading && postesComFoto.length > 0 && (
        <>
          <button onClick={handlePrint} className={styles.exportButton}>
            Imprimir
          </button>
          {/* Adicionar o ID 'printableArea' */}
          <div id="printableArea" className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Código do Poste</th>
                  <th>Endereço</th>
                  <th>Imagem</th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody>
                {postesComFoto.map((poste, index) => (
                  <tr key={index}>
                    <td>{poste.codigoPoste}</td>
                    <td>{poste.address}</td>
                    <td>
                      <img
                        src={poste.image}
                        alt={`Poste ${poste.codigoPoste}`}
                        className={styles.posteImage}
                        onError={(e) => (e.target.style.display = 'none')}
                        style={{ width: '100px', height: '100px' }}
                      />
                    </td>
                    <td>{poste.tipo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && postesComFoto.length === 0 && (
        <p>Nenhum dado de poste com foto disponível.</p>
      )}
    </div>
  );
});

export default RelatorioPostesComFoto;
