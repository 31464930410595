// src/components/Dashboard/Dashboard.jsx

import React, { useEffect, useState, useContext } from 'react';
import {
  format,
  subMonths,
  endOfMonth,
  parseISO,
  isSameMonth,
  getDate,
} from 'date-fns';
import PageLayout from '../../components/PageLayout/PageLayout';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import ChartCard from '../../components/ChartCard/ChartCard';
import { FaCog } from 'react-icons/fa'; // Importando o ícone de configuração
import styles from './Dashboard.module.css';
import { AuthContext } from '../../context/AuthContext'; // Importando o contexto de autenticação
import { toast } from 'react-toastify'; // Para notificações

// Definição das cores para os gráficos
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6384'];
const STATUS_COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];
const POSTE_COLORS = ['#FF8042', '#00C49F'];
const BAR_COLOR = '#8884d8';
const LINE_COLOR = '#82ca9d';

// Mapeamento de status para facilitar a contagem
const statusMap = {
  '1': 'Aguardando',
  '2': 'Atendendo',
  '3': 'Cancelada',
  '4': 'Finalizada',
};

const Dashboard = () => {
  const { token, cidadeId, logout } = useContext(AuthContext); // Obtendo token e cidadeId do contexto
  const [alertData, setAlertData] = useState({
    internos: [],
    externos: [],
    todos: [], // Adicionado para cidadeId diferente de 3315
    status: [],
    postes: [],
    last4Months: [],
    currentMonth: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeLeft, setTimeLeft] = useState(60); // Tempo restante para a próxima atualização

  // Estados para configurações
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const [visibleCharts, setVisibleCharts] = useState({
    internos: true,
    externos: true,
    todos: true, // Adicionado para controlar o gráfico "Todos os Alertas"
    status: true,
    postes: true,
    last4Months: true,
    currentMonth: true,
  });
  const [isCompactMode, setIsCompactMode] = useState(false);

  // Função para processar dados de alertas
  const processAlertData = (data, cidadeId) => {
    if (parseInt(cidadeId, 10) === 3315) {
      // Lógica existente para separar internos e externos
      const internos = {
        Outros: 0,
        'Ponto Apagado': 0,
        'Material Danificado': 0,
        Implantação: 0,
      };
      const externos = {
        Outros: 0,
        'Falta de Energia': 0,
        'Queda de Tensão': 0,
        'Risco de Choque Elétrico': 0,
        'Limpeza das Instalações': 0,
        'Novas Instalações': 0,
        'Ampliação de Rede': 0,
        'Material Danificado': 0,
      };
      const status = {
        Aguardando: 0,
        Atendendo: 0,
        Cancelada: 0,
        Finalizada: 0,
      };

      data.forEach((alert) => {
        const [tipoAlerta, motivoRaw] = alert.alertaMotivo
          .split('/')
          .map((item) => item.trim());

        if (tipoAlerta.includes('Interno aos Galpões')) {
          if (motivoRaw.includes('Ponto Apagado')) {
            internos['Ponto Apagado'] += 1;
          } else if (motivoRaw.includes('Material Danificado')) {
            internos['Material Danificado'] += 1;
          } else if (motivoRaw.includes('Implantação')) {
            internos['Implantação'] += 1;
          } else {
            internos.Outros += 1;
          }
        } else if (tipoAlerta.includes('Externo aos Galpões')) {
          if (motivoRaw.includes('Falta de Energia')) {
            externos['Falta de Energia'] += 1;
          } else if (motivoRaw.includes('Queda de Tensão')) {
            externos['Queda de Tensão'] += 1;
          } else if (motivoRaw.includes('Risco de Choque Elétrico')) {
            externos['Risco de Choque Elétrico'] += 1;
          } else if (motivoRaw.includes('Limpeza das Instalações')) {
            externos['Limpeza das Instalações'] += 1;
          } else if (motivoRaw.includes('Novas Instalações')) {
            externos['Novas Instalações'] += 1;
          } else if (motivoRaw.includes('Ampliação de Rede')) {
            externos['Ampliação de Rede'] += 1;
          } else if (motivoRaw.includes('Material Danificado')) {
            externos['Material Danificado'] += 1;
          } else {
            externos.Outros += 1;
          }
        }

        const situacao = statusMap[alert.alertaSituacao] || 'Desconhecido';
        if (situacao !== 'Desconhecido') {
          status[situacao] += 1;
        }
      });

      return {
        internos: transformToChartData(internos),
        externos: transformToChartData(externos),
        status: [
          { name: 'Aguardando', value: status['Aguardando'] },
          { name: 'Atendendo', value: status['Atendendo'] },
          { name: 'Cancelada', value: status['Cancelada'] },
          { name: 'Finalizada', value: status['Finalizada'] },
        ],
      };
    } else {
      // Nova lógica para agrupar todos os alertas juntos conforme as novas categorias
      const categorias = {
        'Ponto Apagado durante a Noite': 0,
        'Material Danificado Causando Risco de Choque/Queda': 0,
        'Implantação': 0,
        'Outros': 0,
      };
      const status = {
        Aguardando: 0,
        Atendendo: 0,
        Cancelada: 0,
        Finalizada: 0,
      };

      data.forEach((alert) => {
        // Removemos 'tipoAlerta' pois não é utilizado
        const [, motivoRaw] = alert.alertaMotivo
          .split('/')
          .map((item) => item.trim());

        // Mapeamento das novas categorias
        if (motivoRaw.includes('Ponto Apagado durante a Noite')) {
          categorias['Ponto Apagado durante a Noite'] += 1;
        } else if (
          motivoRaw.includes('Material Danificado') &&
          (motivoRaw.includes('Risco de Choque') ||
            motivoRaw.includes('Queda'))
        ) {
          categorias['Material Danificado Causando Risco de Choque/Queda'] += 1;
        } else if (motivoRaw.includes('Implantação')) {
          categorias['Implantação'] += 1;
        } else {
          categorias['Outros'] += 1;
        }

        const situacao = statusMap[alert.alertaSituacao] || 'Desconhecido';
        if (situacao !== 'Desconhecido') {
          status[situacao] += 1;
        }
      });

      return {
        todos: transformToChartData(categorias),
        status: [
          { name: 'Aguardando', value: status['Aguardando'] },
          { name: 'Atendendo', value: status['Atendendo'] },
          { name: 'Cancelada', value: status['Cancelada'] },
          { name: 'Finalizada', value: status['Finalizada'] },
        ],
      };
    }
  };

  // Função para processar dados de postes
  const processPosteData = (posteData) => {
    const ativos = posteData.filter((poste) => poste.ativo === 'S');
    let ligado = 0;
    let desligado = 0;

    ativos.forEach((poste) => {
      if (poste.ligado === 'L') {
        ligado += 1;
      } else if (poste.ligado === 'D') {
        desligado += 1;
      }
    });

    return [
      { name: 'Ligado', value: ligado },
      { name: 'Desligado', value: desligado },
    ];
  };

  // Função para processar dados dos últimos 4 meses
  const processAlertDataLast4Months = (data) => {
    const today = new Date();
    const last4Months = [];

    for (let i = 3; i >= 0; i--) {
      const date = subMonths(today, i);
      const month = format(date, 'MMMM yyyy');
      last4Months.push(month);
    }

    const alertByMonth = {};
    last4Months.forEach((month) => {
      alertByMonth[month] = 0;
    });

    data.forEach((alert) => {
      const alertDate = parseISO(alert.alertaDataGeracao);
      const alertMonth = format(alertDate, 'MMMM yyyy');

      if (alertByMonth.hasOwnProperty(alertMonth)) {
        alertByMonth[alertMonth] += 1;
      }
    });

    return last4Months.map((month) => ({
      month,
      count: alertByMonth[month],
    }));
  };

  // Função para processar dados do mês atual
  const processAlertDataCurrentMonth = (data) => {
    const today = new Date();
    const endOfCurrentMonth = endOfMonth(today);
    const daysInMonth = getDate(endOfCurrentMonth);
    const alertByDay = {};

    for (let day = 1; day <= daysInMonth; day++) {
      alertByDay[day] = 0;
    }

    data.forEach((alert) => {
      const alertDate = parseISO(alert.alertaDataGeracao);
      if (isSameMonth(alertDate, today)) {
        const day = getDate(alertDate);
        alertByDay[day] += 1;
      }
    });

    const chartData = [];
    for (let day = 1; day <= daysInMonth; day++) {
      chartData.push({
        day: day.toString(),
        count: alertByDay[day],
      });
    }

    return chartData;
  };

  // Função para transformar objetos em dados de gráfico
  const transformToChartData = (obj) => {
    return Object.keys(obj).map((key) => ({
      name: key,
      value: obj[key],
    }));
  };

  // Função para salvar as configurações no localStorage
  const saveSettingsToLocalStorage = (settings) => {
    localStorage.setItem('dashboardSettings', JSON.stringify(settings));
  };

  // Função para carregar as configurações do localStorage
  const loadSettingsFromLocalStorage = () => {
    const settings = localStorage.getItem('dashboardSettings');
    if (settings) {
      const parsedSettings = JSON.parse(settings);
      setVisibleCharts(parsedSettings.visibleCharts);
      setIsCompactMode(parsedSettings.isCompactMode);
    }
  };

  // Função para buscar os dados do Dashboard
  const fetchDashboardData = async () => {
    try {
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      if (!cidadeId) {
        throw new Error('ID da cidade não encontrado');
      }

      // Imprime o token e o cidadeId no console para debug
      console.log('Token JWT usado no Dashboard:', token);
      console.log('Cidade ID usado no Dashboard:', cidadeId);

      const [alertaResponse, posteResponse] = await Promise.all([
        fetch('https://api.ads10.com.br/api/alerta', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'cidadeId': cidadeId, // Envia o cidadeId no header
          },
        }),
        fetch('https://api.ads10.com.br/api/postefotocelula', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            'cidadeId': cidadeId, // Envia o cidadeId no header
          },
        }),
      ]);

      if (!alertaResponse.ok || !posteResponse.ok) {
        if (
          alertaResponse.status === 401 ||
          posteResponse.status === 401
        ) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout(); // Usa a função de logout do contexto
          return;
        }
        throw new Error('Erro ao buscar os dados da API');
      }

      const [alertaData, posteData] = await Promise.all([
        alertaResponse.json(),
        posteResponse.json(),
      ]);

      const processedAlertaData = processAlertData(alertaData, cidadeId); // Passa cidadeId
      const processedPosteData = processPosteData(posteData);
      const processedLast4Months = processAlertDataLast4Months(alertaData);
      const processedCurrentMonth = processAlertDataCurrentMonth(alertaData);

      setAlertData({
        ...processedAlertaData,
        postes: processedPosteData,
        last4Months: processedLast4Months,
        currentMonth: processedCurrentMonth,
      });
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro desconhecido');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadSettingsFromLocalStorage();
  }, []);

  useEffect(() => {
    fetchDashboardData();

    const dataInterval = setInterval(() => {
      fetchDashboardData();
      setTimeLeft(60);
    }, 60000); // Atualiza os dados a cada 60 segundos

    const countdownTimer = setInterval(() => {
      setTimeLeft((prevTimeLeft) =>
        prevTimeLeft > 0 ? prevTimeLeft - 1 : 0
      );
    }, 1000); // Decrementa o tempo a cada segundo

    return () => {
      clearInterval(dataInterval);
      clearInterval(countdownTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, cidadeId]); // Dependências atualizadas para token e cidadeId

  // Funções para gerenciar as configurações
  const handleChartVisibilityChange = (chartKey) => {
    setVisibleCharts((prev) => {
      const updated = { ...prev, [chartKey]: !prev[chartKey] };
      saveSettingsToLocalStorage({
        visibleCharts: updated,
        isCompactMode,
      });
      return updated;
    });
  };

  const handleCompactModeChange = () => {
    setIsCompactMode((prev) => {
      const updated = !prev;
      saveSettingsToLocalStorage({
        visibleCharts,
        isCompactMode: updated,
      });
      return updated;
    });
  };

  if (isLoading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return (
      <PageLayout title="Dashboard">
        <div className={styles.errorContainer}>
          <p className={styles.errorMessage}>Erro: {error}</p>
          <button
            className={styles.retryButton}
            onClick={fetchDashboardData}
          >
            Tentar Novamente
          </button>
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout title="Dashboard">
      {/* Ícone de Configuração */}
      <div className={styles.configIconContainer}>
        <FaCog
          className={styles.configIcon}
          onClick={() => setIsConfigModalOpen(true)}
          title="Configurações"
        />
      </div>

      {/* Modal de Configuração */}
      {isConfigModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <h2>Configurações</h2>
            <button
              className={styles.closeButton}
              onClick={() => setIsConfigModalOpen(false)}
              aria-label="Fechar configurações"
            >
              &times;
            </button>

            <div className={styles.configSection}>
              <h3>Exibir Gráficos</h3>
              <div className={styles.checkboxGroup}>
                {parseInt(cidadeId, 10) === 3315 ? (
                  // Exibir opções de internos e externos apenas para cidadeId 3315
                  <>
                    <label className={styles.checkboxLabel}>
                      <input
                        type="checkbox"
                        checked={visibleCharts.internos}
                        onChange={() => handleChartVisibilityChange('internos')}
                      />
                      {getChartDisplayName('internos')}
                    </label>
                    <label className={styles.checkboxLabel}>
                      <input
                        type="checkbox"
                        checked={visibleCharts.externos}
                        onChange={() => handleChartVisibilityChange('externos')}
                      />
                      {getChartDisplayName('externos')}
                    </label>
                  </>
                ) : (
                  // Exibir opção de 'Todos os Alertas' para outras cidades
                  <label className={styles.checkboxLabel}>
                    <input
                      type="checkbox"
                      checked={visibleCharts.todos}
                      onChange={() => handleChartVisibilityChange('todos')}
                    />
                    {getChartDisplayName('todos')}
                  </label>
                )}

                {/* Exibir outras opções de gráficos que estão disponíveis para todas as cidades */}
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    checked={visibleCharts.status}
                    onChange={() => handleChartVisibilityChange('status')}
                  />
                  {getChartDisplayName('status')}
                </label>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    checked={visibleCharts.postes}
                    onChange={() => handleChartVisibilityChange('postes')}
                  />
                  {getChartDisplayName('postes')}
                </label>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    checked={visibleCharts.last4Months}
                    onChange={() => handleChartVisibilityChange('last4Months')}
                  />
                  {getChartDisplayName('last4Months')}
                </label>
                <label className={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    checked={visibleCharts.currentMonth}
                    onChange={() => handleChartVisibilityChange('currentMonth')}
                  />
                  {getChartDisplayName('currentMonth')}
                </label>
              </div>
            </div>

            <div className={styles.configSection}>
              <h3>Modo de Exibição</h3>
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={isCompactMode}
                  onChange={handleCompactModeChange}
                />
                Modo Compacto
              </label>
            </div>
          </div>
        </div>
      )}

      <div
        className={`${styles.dashboardContent} ${
          isCompactMode ? styles.compact : ''
        }`}
      >
        {parseInt(cidadeId, 10) === 3315 ? (
          <>
            {visibleCharts.internos && (
              <ChartCard
                title="Alertas Internos"
                type="pie"
                data={alertData.internos}
                dataKey="value"
                colors={COLORS}
                compactMode={isCompactMode}
              />
            )}
            {visibleCharts.externos && (
              <ChartCard
                title="Alertas Externos"
                type="pie"
                data={alertData.externos}
                dataKey="value"
                colors={COLORS}
                compactMode={isCompactMode}
              />
            )}
          </>
        ) : (
          <>
            {visibleCharts.todos && (
              <ChartCard
                title="Todos os Alertas"
                type="pie"
                data={alertData.todos}
                dataKey="value"
                colors={COLORS}
                compactMode={isCompactMode}
              />
            )}
          </>
        )}

        {visibleCharts.status && (
          <ChartCard
            title="Alertas por Status"
            type="pie"
            data={alertData.status}
            dataKey="value"
            colors={STATUS_COLORS}
            compactMode={isCompactMode}
          />
        )}
        {visibleCharts.postes && (
          <ChartCard
            title="Status de Postes"
            type="pie"
            data={alertData.postes}
            dataKey="value"
            colors={POSTE_COLORS}
            compactMode={isCompactMode}
          />
        )}
        {visibleCharts.last4Months && (
          <ChartCard
            title="Total de Alertas nos Últimos 4 Meses"
            type="bar"
            data={alertData.last4Months}
            dataKey="count"
            colors={[BAR_COLOR]}
            additionalProps={{
              margin: { top: 20, right: 30, left: 20, bottom: 5 },
            }}
            compactMode={isCompactMode}
          />
        )}
        {visibleCharts.currentMonth && (
          <ChartCard
            title="Alertas Criados no Mês Atual"
            type="line"
            data={alertData.currentMonth}
            dataKey="count"
            colors={[LINE_COLOR]}
            additionalProps={{
              margin: { top: 20, right: 30, left: 20, bottom: 5 },
            }}
            compactMode={isCompactMode}
          />
        )}
      </div>

      {/* Barra de Carregamento */}
      <div className={styles.loadingBarContainer}>
        <div
          className={styles.loadingBar}
          style={{ width: `${((60 - timeLeft) / 60) * 100}%` }}
        ></div>
        <div className={styles.loadingText}>
          Atualização em {Math.floor(timeLeft / 60)}:
          {(timeLeft % 60).toString().padStart(2, '0')}
        </div>
      </div>
    </PageLayout>
  );
};

// Função auxiliar para obter o nome de exibição dos gráficos
const getChartDisplayName = (key) => {
  const displayNames = {
    internos: 'Alertas Internos',
    externos: 'Alertas Externos',
    todos: 'Todos os Alertas', // Adicionado
    status: 'Alertas por Status',
    postes: 'Status de Postes',
    last4Months: 'Total de Alertas nos Últimos 4 Meses',
    currentMonth: 'Alertas Criados no Mês Atual',
  };
  return displayNames[key] || key;
};

export default Dashboard;
