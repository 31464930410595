import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate'; // Importar React Paginate
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import { CSVLink } from 'react-csv';
import LoadingOverlay from '../../../components/LoadingOverlay/LoadingOverlay'; // Importando o componente de Loading
import styles from './BracoTiposTabela.module.css';
import { PostBracoDimensaoCreate, PutBracoDimensao, GetBracoDimensoes, DeleteBracoDimensao } from './BracoDimensao.service';
import BracoDimensaoModal from './BracoDimensaoModal';

const BracoDimensaoTabela = () => {
  const [bracoDimensoes, setBracoDimensoes] = useState([]);
  const [selectedBracoDimensao, setSelectedBracoDimensao] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [tipoFilter, setTipoFilter] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(0); // Estado para a página atual
  const [isLoading, setIsLoading] = useState(true); // Estado para controle de loading
  const itemsPerPage = 10; // Define o número de itens por página


  useEffect(() => {
    UpdateTable().finally(() => {
      setIsLoading(false);
    });
  }, []);

  const UpdateTable = async () => {
    GetBracoDimensoes().then((data) => {
      setBracoDimensoes(data);
    })
      .catch((error) => {
        alert("ERRO ao carregar Braços Dimensões -> " + error);
      })

  }


  /* Funções de Modificação de dados  */
  const handleAddNew = () => {
    setSelectedBracoDimensao(null);
    setIsModalOpen(true);
  };

  const handleEdit = (bracodimensao) => {
    setSelectedBracoDimensao(bracodimensao);
    setIsModalOpen(true);
  };

  const handleDelete = (bracodimensao) => {
    const confirm = window.confirm('Tem certeza de que deseja deletar o Braço Dimensão: ' + bracodimensao.bd_nome + " (ID: " + bracodimensao.bd_id + ")?");

    if (!confirm) {
      return;
    }
    else {
      setIsLoading(true);
      DeleteBracoDimensao(bracodimensao.bd_id).then(() => {
        alert("Braço Dimensão deletado");
      })
        .catch((error) => {
          alert("ERRO ao deletar Braço Dimensão ->" + error)
        })
        .finally(() => {
          UpdateTable().finally(() => {
            setIsLoading(false);
          });
        });
    }
  };

  const handleSaveBracoDimensao = (bracodimensao) => {
    setIsLoading(true);
    if (bracodimensao.bd_id) {
      PutBracoDimensao(bracodimensao.bd_id, bracodimensao).then((data) => {
        alert("Braço Dimensão Editado");
      })
        .catch((error) => {
          alert("ERRO AO EDITAR BRAÇO DIMENSÂO -> " + error);
        })
        .finally(() => {
          UpdateTable().finally(() => {
            setIsLoading(false);
          });
        });
    }
    else {
      PostBracoDimensaoCreate(bracodimensao).then((data) => {
        alert("Braço Dimensão Criado");
      })
        .catch((error) => {
          alert("ERRO AO CRIAR BRAÇO DIMENSÃO -> " + error);
        })
        .finally(() => {
          UpdateTable().finally(() => {
            setIsLoading(false);
          });
        });
    }
  };


  // Funções de sort da tabela
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedBracoDimensoes = () => {
    const sortedBracoDimensoes = [...bracoDimensoes];
    if (sortConfig.key) {
      sortedBracoDimensoes.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortedBracoDimensoes;
  };

  const filterdBracoDimensoes = sortedBracoDimensoes().filter(bracodimensao => {
    const matchesSearchTerm = bracodimensao.bd_id.toString().toLowerCase().includes(searchTerm.toLowerCase());
    const matchesTipoFilter = tipoFilter ? bracodimensao.bd_nome.toLowerCase().includes(tipoFilter.toLowerCase()) : true;

    return matchesSearchTerm && matchesTipoFilter;
  });


  // Funções de paginação
  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = filterdBracoDimensoes.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(filterdBracoDimensoes.length / itemsPerPage);

  // Funções de input de filtro/pesquisa
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleTipoFilterChange = (e) => {
    setTipoFilter(e.target.value);
  };


  // Preparando dados para exportação CSV
  const headers = [
    { label: 'ID', key: 'bd_id' },
    { label: 'Tipo', key: 'bd_nome' },
  ];

  const csvData = filterdBracoDimensoes.map(bracoDimensao => ({
    bd_id: bracoDimensao.bd_id,
    bd_nome: bracoDimensao.bd_nome,
  }));

  return (
    <>
      {isLoading && <LoadingOverlay />} {/* Loading screen enquanto os dados estão sendo carregados */}

      {!isLoading && (
        <>
          <div className={styles.controlsTop}>
            <button className={styles.addButton} onClick={handleAddNew}>
              <FaPlus /> Adicionar Novo Braço Dimensão
            </button>
            <CSVLink
              data={csvData}
              headers={headers}
              filename={"bracodimensao-export.csv"}
              className={styles.exportButton}
              bom={true}
              separator={";"}
              enclosingCharacter={`"`}
            >
              Exportar para Excel
            </CSVLink>
          </div>
          <div className={styles.controls}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Pesquisar por ID do Braço Dimensão..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Filtrar por Tipo..."
              value={tipoFilter}
              onChange={handleTipoFilterChange}
            />
          </div>

          {/* Tabela de Lampadas */}
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th onClick={() => requestSort('bd_id')}>
                    ID {sortConfig.key === 'bd_id' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th onClick={() => requestSort('bd_nome')}>
                    Tipo {sortConfig.key === 'bd_nome' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
                  </th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentPageData.map(bracoDimensao => (
                  <tr key={bracoDimensao.bd_id}>
                    <td>{bracoDimensao.bd_id}</td>
                    <td>{bracoDimensao.bd_nome}</td>
                    <td>
                      <button className={styles.actionButton} onClick={() => handleEdit(bracoDimensao)}>
                        <FaEdit />
                      </button>
                      <button className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => handleDelete(bracoDimensao)}>
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Componente de paginação */}
          <div className={styles.paginationContainer}>
            <ReactPaginate
              previousLabel={"← Anterior"}
              nextLabel={"Próximo →"}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={styles.pagination}
              previousLinkClassName={styles.paginationLink}
              nextLinkClassName={styles.paginationLink}
              disabledClassName={styles.paginationDisabled}
              activeClassName={styles.paginationActive}
            />
          </div>
          <BracoDimensaoModal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            onSubmit={handleSaveBracoDimensao}
            initialData={selectedBracoDimensao}
          />
        </>
      )}
    </>
  );
};

export default BracoDimensaoTabela;