// src/ProtectedRoute.js

import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './context/AuthContext';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    // Você pode retornar um loader aqui se desejar
    return <div>Carregando...</div>;
  }

  if (!isAuthenticated) {
    toast.error('Por favor, faça login para acessar esta página.');
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
