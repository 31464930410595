// CompanyLogos.js
import React, { useContext } from 'react';
import styles from './Logos.module.css';
import directLuzLogo from '../../assets/directluz-icone.png'; // Este logo sempre irá aparecer
import { AuthContext } from '../../context/AuthContext';
import { getLogosByCityId } from '../../config';

const Logos = () => {
  const { cidadeId } = useContext(AuthContext);
  const { extraLogo, coipLogo } = getLogosByCityId(cidadeId); // Obter logos específicos da cidade

  const directLuzLogoElement = (
    <img src={directLuzLogo} alt="DirectLuz" className={styles.logo} />
  );

  // Renderização condicional dos logos específicos
  const extraLogoElement = extraLogo ? (
    <img src={extraLogo} alt="Logo Extra" className={styles.logo} />
  ) : null;

  const coipLogoElement = coipLogo ? (
    <img src={coipLogo} alt="Logo COIP" className={styles.logo} />
  ) : (
    <div className={styles.coipPlaceholder}>
      <span>COIP</span>
    </div>
  );

  return (
    <div className={styles.logoContainer}>
      {directLuzLogoElement}
      {coipLogoElement}
      {extraLogoElement}
    </div>
  );
};

export default Logos;
