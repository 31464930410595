// src/pages/Relatorios/OrdensServicoRelatorio.jsx

import React, { useState } from 'react';
import { FaFileExport } from 'react-icons/fa';
import useAlertasComOrdemServico from '../../hooks/useAlertasComOrdemServico';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';

const OrdensServicoRelatorio = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');

    const [filterByEndDate, setFilterByEndDate] = useState(false);
    const [endStartDate, setEndStartDate] = useState('');
    const [endEndDate, setEndEndDate] = useState('');
    const [endStartTime, setEndStartTime] = useState('');
    const [endEndTime, setEndEndTime] = useState('');

    const { alertasComOS, isLoading, error } = useAlertasComOrdemServico();

    const handleClearFilters = () => {
        setStartDate('');
        setEndDate('');
        setStartTime('');
        setEndTime('');
        setFilterByEndDate(false);
        setEndStartDate('');
        setEndEndDate('');
        setEndStartTime('');
        setEndEndTime('');
    };

    const filteredOrdens = alertasComOS
        .map((alerta) => alerta.ordemServico)
        .filter((os) => os)
        .filter((os) => {
            const osOpenDateTime = new Date(`${os.osDataAbertura}T${os.osHoraAbertura}`);
            const osCloseDateTime = os.osDataEncerramento
                ? new Date(`${os.osDataEncerramento}T${os.osHoraEncerramento}`)
                : null;

            const startDateTime = startDate ? new Date(`${startDate}T${startTime || '00:00'}`) : null;
            const endDateTime = endDate ? new Date(`${endDate}T${endTime || '23:59'}`) : null;
            const isAfterStart = startDateTime ? osOpenDateTime >= startDateTime : true;
            const isBeforeEnd = endDateTime ? osOpenDateTime <= endDateTime : true;

            const endStartDateTime = filterByEndDate && endStartDate
                ? new Date(`${endStartDate}T${endStartTime || '00:00'}`)
                : null;
            const endEndDateTime = filterByEndDate && endEndDate
                ? new Date(`${endEndDate}T${endEndTime || '23:59'}`)
                : null;
            const isAfterCloseStart = endStartDateTime && osCloseDateTime ? osCloseDateTime >= endStartDateTime : true;
            const isBeforeCloseEnd = endEndDateTime && osCloseDateTime ? osCloseDateTime <= endEndDateTime : true;

            return isAfterStart && isBeforeEnd && isAfterCloseStart && isBeforeCloseEnd && (!filterByEndDate || osCloseDateTime);
        });

    const handleExportToExcel = () => {
        const exportData = filteredOrdens.map((os) => ({
            'ID da Ordem de Serviço': os.osId,
            'ID do Poste': os.osPosteId,
            'Data de Abertura': os.osDataAbertura,
            'Hora de Abertura': os.osHoraAbertura,
            'Situação': os.osSituacaoId,
            'Data de Encerramento': os.osDataEncerramento || 'N/A',
            'Hora de Encerramento': os.osHoraEncerramento || 'N/A',
            'Observações': os.osObs,
            'Prestador de Serviço ID': os.osPrestadorId,
            'Usuário Abertura ID': os.osUsuarioAbertura,
            'Usuário Encerramento ID': os.osUsuarioEncerramento,
            'Cidade ID': os.osCidadeId,
            'ID do Alerta': os.osAlertaId,
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório Ordens de Serviço');
        XLSX.writeFile(workbook, 'relatorio_ordens_servico.xlsx');
    };

    return (
        <div className={styles.container}>
            <div className={styles.controlsTop}>

                {/* Filtro de abertura */}
                <div className={styles.filterGroup}>
                    <h3>Filtro de Geração</h3>
                    <div className={styles.dateGroup}>
                        <label htmlFor="startDate">Data Inicial de Abertura:</label>
                        <input
                            type="date"
                            id="startDate"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            className={styles.dateInput}
                        />
                    </div>
                    <div className={styles.dateGroup}>
                        <label htmlFor="startTime">Hora Inicial de Abertura:</label>
                        <input
                            type="time"
                            id="startTime"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            className={styles.timeInput}
                        />
                    </div>
                    <div className={styles.dateGroup}>
                        <label htmlFor="endDate">Data Final de Abertura:</label>
                        <input
                            type="date"
                            id="endDate"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            className={styles.dateInput}
                        />
                    </div>
                    <div className={styles.dateGroup}>
                        <label htmlFor="endTime">Hora Final de Abertura:</label>
                        <input
                            type="time"
                            id="endTime"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            className={styles.timeInput}
                        />
                    </div>
                </div>

                {/* Checkbox e filtros de encerramento */}
                <div className={styles.filterGroup}>
                    <div className={styles.checkboxGroup}>
                        <label htmlFor="filterByEndDate">
                            <input
                                type="checkbox"
                                id="filterByEndDate"
                                checked={filterByEndDate}
                                onChange={(e) => setFilterByEndDate(e.target.checked)}
                            />
                            Filtrar por data de encerramento
                        </label>
                    </div>
                    {filterByEndDate && (
                        <>
                            <div className={styles.dateGroup}>
                                <label htmlFor="endStartDate">Data Inicial de Encerramento:</label>
                                <input
                                    type="date"
                                    id="endStartDate"
                                    value={endStartDate}
                                    onChange={(e) => setEndStartDate(e.target.value)}
                                    className={styles.dateInput}
                                />
                            </div>
                            <div className={styles.dateGroup}>
                                <label htmlFor="endStartTime">Hora Inicial de Enc:</label>
                                <input
                                    type="time"
                                    id="endStartTime"
                                    value={endStartTime}
                                    onChange={(e) => setEndStartTime(e.target.value)}
                                    className={styles.timeInput}
                                />
                            </div>
                            <div className={styles.dateGroup}>
                                <label htmlFor="endEndDate">Data Final de Encerramento:</label>
                                <input
                                    type="date"
                                    id="endEndDate"
                                    value={endEndDate}
                                    onChange={(e) => setEndEndDate(e.target.value)}
                                    className={styles.dateInput}
                                />
                            </div>
                            <div className={styles.dateGroup}>
                                <label htmlFor="endEndTime">Hora Final de Encerramento:</label>
                                <input
                                    type="time"
                                    id="endEndTime"
                                    value={endEndTime}
                                    onChange={(e) => setEndEndTime(e.target.value)}
                                    className={styles.timeInput}
                                />
                            </div>
                        </>
                    )}
                </div>

                <div className={styles.actionsContainer}>
                    <button onClick={handleExportToExcel} className={styles.exportButton}>
                        <FaFileExport /> Exportar para Excel
                    </button>
                    <button onClick={handleClearFilters} className={styles.clearFiltersButton}>
                        Limpar Filtros
                    </button>
                </div>
            </div>

            {isLoading && <p className={styles.infoMessage}>Carregando dados...</p>}
            {error && <p className={styles.errorMessage}>{error}</p>}
            {!isLoading && filteredOrdens.length > 0 && (
                <div className={styles.tableContainer}>
                    <table className={styles.table}>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>ID do Poste</th>
                                <th>Data Abertura</th>
                                <th>Hora Abertura</th>
                                <th>Situação</th>
                                <th>Data Encerramento</th>
                                <th>Hora Encerramento</th>
                                <th>Observação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOrdens.map((os) => (
                                <tr key={os.osId}>
                                    <td>{os.osId}</td>
                                    <td>{os.osPosteId}</td>
                                    <td>{os.osDataAbertura}</td>
                                    <td>{os.osHoraAbertura}</td>
                                    <td>{os.osSituacaoId}</td>
                                    <td>{os.osDataEncerramento || 'N/A'}</td>
                                    <td>{os.osHoraEncerramento || 'N/A'}</td>
                                    <td>{os.osObs}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {!isLoading && filteredOrdens.length === 0 && (
                <p className={styles.infoMessage}>Nenhuma ordem de serviço encontrada para o período selecionado.</p>
            )}
        </div>
    );
};

export default OrdensServicoRelatorio;

