import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const usePosteDataWithDevices = () => {
  const { token, cidadeId, logout } = useContext(AuthContext); // Obtendo do contexto
  const [postes, setPostes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const processPostesData = useCallback((postesData, postefotocelulaData, fotocelulasData) => {
    const fotocelulaIdToDeviceEUI = {};
    fotocelulasData.forEach(fotocelula => {
      fotocelulaIdToDeviceEUI[fotocelula.fotocelulaId] = fotocelula.deviceEUI;
    });

    const posteIdToDeviceEUIs = {};
    postefotocelulaData.forEach(association => {
      const posteId = association.posteId;
      const fotocelulaId = association.fotocelulaId;
      const deviceEUI = fotocelulaIdToDeviceEUI[fotocelulaId];

      if (deviceEUI && association.ativo === "S") {
        if (!posteIdToDeviceEUIs[posteId]) {
          posteIdToDeviceEUIs[posteId] = [];
        }
        posteIdToDeviceEUIs[posteId].push({
          deviceEUI,
          postefotocelulaId: association.postefotocelulaId,
        });
      }
    });

    return postesData
      .filter(poste => poste.id !== undefined && poste.id !== null)
      .map(poste => ({
        ...poste,
        euis: posteIdToDeviceEUIs[poste.id] || [],
      }));
  }, []);

  const fetchPostes = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const [postesResponse, postefotocelulaResponse, fotocelulasResponse] = await Promise.all([
        fetch('https://api.ads10.com.br/api/poste', {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'cidadeId': cidadeId,
            'Content-Type': 'application/json'
          }
        }),
        fetch('https://api.ads10.com.br/api/postefotocelula', {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'cidadeId': cidadeId,
            'Content-Type': 'application/json'
          }
        }),
        fetch('https://api.ads10.com.br/api/fotocelula', {
          headers: { 
            'Authorization': `Bearer ${token}`,
            'cidadeId': cidadeId,
            'Content-Type': 'application/json'
          }
        }),
      ]);

      if (![postesResponse, postefotocelulaResponse, fotocelulasResponse].every(res => res.ok)) {
        if ([postesResponse, postefotocelulaResponse, fotocelulasResponse].some(res => res.status === 401)) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout(); // Realiza logout ao expirar sessão
          return;
        }
        throw new Error('Erro ao buscar dados das APIs.');
      }

      const [postesData, postefotocelulaData, fotocelulasData] = await Promise.all([
        postesResponse.json(),
        postefotocelulaResponse.json(),
        fotocelulasResponse.json(),
      ]);

      const processedData = processPostesData(postesData, postefotocelulaData, fotocelulasData);
      setPostes(processedData);
    } catch (err) {
      console.error(err);
      setError(err.message || 'Erro desconhecido ao buscar dados dos postes.');
      toast.error(`Erro ao buscar dados dos postes: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [processPostesData, token, cidadeId, logout]);

  useEffect(() => {
    fetchPostes();
  }, [fetchPostes]);

  return {
    postes,
    isLoading,
    error,
    refetch: fetchPostes, 
  };
};

export default usePosteDataWithDevices;
