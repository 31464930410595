// src/pages/Levantamentos/graficos/GraficoAlertas.jsx

import React, { useEffect, useState, useContext } from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AuthContext } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import useAlertaSituacao from '../../../hooks/useAlertaSituacao';
import styles from '../Levantamentos.module.css';

const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'];

const GraficoAlertas = ({ startDate, endDate, startTime, endTime }) => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [alertData, setAlertData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { situacoes, isLoading: situacoesLoading } = useAlertaSituacao();

  useEffect(() => {
    const fetchAlertData = async () => {
      if (!token || !cidadeId) {
        toast.error('Token de autenticação ou ID da cidade não encontrado');
        return;
      }

      try {
        const response = await fetch('https://api.ads10.com.br/api/alerta', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId,
          },
        });

        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout();
          return;
        }

        if (!response.ok) {
          throw new Error('Erro ao buscar dados dos alertas');
        }

        const data = await response.json();
        const filteredData = data.filter((alert) => {
          const alertDate = new Date(`${alert.alertaDataGeracao}T${alert.alertaHoraGeracao}`);
          const startDateObj = startDate ? new Date(`${startDate}T${startTime || '00:00'}`) : null;
          const endDateObj = endDate ? new Date(`${endDate}T${endTime || '23:59'}`) : null;

          return (!startDateObj || alertDate >= startDateObj) && (!endDateObj || alertDate <= endDateObj);
        });

        const dataCounts = filteredData.reduce((acc, alert) => {
          const situacaoNome = situacoes[alert.alertaSituacao] || `Situação ${alert.alertaSituacao}`;
          acc[situacaoNome] = (acc[situacaoNome] || 0) + 1;
          return acc;
        }, {});

        const formattedData = Object.keys(dataCounts).map((key) => ({
          name: key,
          value: dataCounts[key],
        }));

        setAlertData(formattedData);
      } catch (error) {
        toast.error(`Erro ao buscar dados dos alertas: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    if (!situacoesLoading) {
      fetchAlertData();
    }
  }, [token, cidadeId, logout, situacoes, situacoesLoading, startDate, endDate, startTime, endTime]);

  return (
    <div className={styles.chartCard}>
      <h3 className={styles.chartTitle}>Alertas por status</h3>
      {isLoading || situacoesLoading ? (
        <p>Carregando dados...</p>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={alertData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
              outerRadius={100}
              dataKey="value"
            >
              {alertData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
          </PieChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default GraficoAlertas;
  