// src/pages/GruposPostes/GroupModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import styles from './GroupModal.module.css';

Modal.setAppElement('#root'); // Adjust as necessary

const GroupModal = ({ isOpen, onRequestClose, onSubmit, group }) => {
  const isEditMode = !!group; // If group is provided, we're in edit mode

  const [groupName, setGroupName] = useState('');
  const [groupColor, setGroupColor] = useState('#FF0000'); // Default color

  useEffect(() => {
    if (isEditMode && group) {
      setGroupName(group.name);
      setGroupColor(group.color || '#FF0000');
    } else {
      setGroupName('');
      setGroupColor('#FF0000');
    }
  }, [isEditMode, group]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (groupName.trim() === '') {
      alert('O nome do grupo não pode estar vazio.');
      return;
    }

    const newGroup = {
      ...group,
      name: groupName,
      color: groupColor,
    };

    onSubmit(newGroup);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isEditMode ? 'Editar Grupo' : 'Criar Novo Grupo'}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.header}>
        <h2>{isEditMode ? 'Editar Grupo' : 'Criar Novo Grupo'}</h2>
        <button onClick={onRequestClose} className={styles.closeButton}>
          <FaTimes />
        </button>
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <label htmlFor="groupName">Nome do Grupo:</label>
        <input
          type="text"
          id="groupName"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          required
          className={styles.input}
        />

        <label htmlFor="groupColor">Cor do Grupo:</label>
        <input
          type="color"
          id="groupColor"
          value={groupColor}
          onChange={(e) => setGroupColor(e.target.value)}
          className={styles.colorInput}
        />

        <div className={styles.buttons}>
          <button type="submit" className={styles.submitButton}>
            {isEditMode ? 'Salvar' : 'Criar'}
          </button>
          <button type="button" onClick={onRequestClose} className={styles.cancelButton}>
            Cancelar
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default GroupModal;
