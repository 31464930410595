import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de formatos de poste.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de formatos de poste, estado de carregamento, erro e função para refetch.
 */
const useFormatoPoste = (isOpen) => {
  const { token, logout } = useContext(AuthContext); // Obtém token e logout do contexto
  const [formatosPoste, setFormatosPoste] = useState([]);
  const [isFormatoPosteLoading, setIsFormatoPosteLoading] = useState(false);
  const [formatoPosteError, setFormatoPosteError] = useState(null);

  /**
   * Função para buscar os formatos de poste da API.
   */
  const fetchFormatoPoste = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsFormatoPosteLoading(true);
    setFormatoPosteError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch('https://api.ads10.com.br/api/formatoposte', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar formatos de poste: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Formatos de Poste recebidos da API:', data); // Log para depuração
      setFormatosPoste(data);
    } catch (error) {
      console.error('Erro ao buscar formatos de poste:', error);
      setFormatoPosteError(error.message.includes('Token')
        ? 'Você precisa estar autenticado para carregar a lista de formatos de poste.'
        : 'Falha ao carregar a lista de formatos de poste. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(setFormatoPosteError);
    } finally {
      setIsFormatoPosteLoading(false);
    }
  }, [isOpen, token, logout]);

  useEffect(() => {
    fetchFormatoPoste();
  }, [fetchFormatoPoste]);

  return { formatosPoste, isFormatoPosteLoading, formatoPosteError, refetch: fetchFormatoPoste };
};

export default useFormatoPoste;
