// src/hooks/usePosteDataComAlertas.js

import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const usePosteDataComAlertas = (filtroAtivo = false) => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [postesDetalhados, setPostesDetalhados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        cidadeId,
      };

      const [
        posteResponse,
        fotocelulaResponse,
        postefotocelulaResponse,
        alertaResponse,
        consumoResponse,
      ] = await Promise.all([
        fetch('https://api.ads10.com.br/api/poste', { headers }),
        fetch('https://api.ads10.com.br/api/fotocelula', { headers }),
        fetch('https://api.ads10.com.br/api/postefotocelula', { headers }),
        fetch('https://api.ads10.com.br/api/alerta', { headers }),
        fetch('https://api.ads10.com.br/api/posteconsumo', { headers }),
      ]);

      const responses = [
        posteResponse,
        fotocelulaResponse,
        postefotocelulaResponse,
        alertaResponse,
        consumoResponse,
      ];

      if (responses.some((res) => res.status === 401)) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!responses.every((res) => res.ok)) {
        throw new Error('Erro ao buscar dados das APIs');
      }

      const [postes, fotocelulas, postefotocelulas, alertas, consumos] = await Promise.all(
        responses.map((res) => res.json())
      );

      const postesFiltrados = filtroAtivo
        ? postes.filter((poste) => poste.ativo === 'S')
        : postes;

      const postesComDetalhes = postesFiltrados.map((poste) => {
        const associacoesFotocelulas = postefotocelulas
          .filter((pf) => pf.posteId === poste.id && pf.ativo === 'S')
          .map((pf) => {
            const fotocelula = fotocelulas.find((f) => f.fotocelulaId === pf.fotocelulaId);
            return fotocelula
              ? {
                  ...fotocelula,
                  tensaoRede: pf.tensaoRede,
                  ligado: pf.ligado,
                  manutencao: pf.manutencao,
                  dataUltimaLeitura: fotocelula.deviceAtualizacao,
                }
              : null;
          })
          .filter(Boolean);

        const alertasAssociados = alertas.filter((alerta) => alerta.alertaPosteId === poste.id);

        // Certificar-se de que os IDs são strings
        const consumoAssociado = consumos.find(
          (consumo) => consumo.posteconsumoPoste.toString() === poste.id.toString()
        );

        // Obter a potência do poste diretamente
        const potenciaPoste = poste.potencia || null;

        // Logs para depuração
        console.log(`Poste ID: ${poste.id}, Potência: ${poste.potencia}`);

        return {
          ...poste,
          fotocelulas: associacoesFotocelulas,
          alertas: alertasAssociados,
          consumo: consumoAssociado ? consumoAssociado.posteconsumoConsumo : null,
          potencia: potenciaPoste,
        };
      });

      setPostesDetalhados(postesComDetalhes);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError(error.message);
      toast.error(`Erro ao buscar dados: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout, filtroAtivo]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { postesDetalhados, isLoading, error, refetch: fetchData };
};

export default usePosteDataComAlertas;
