import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de países.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de países, estado de carregamento, erro e função para refetch.
 */
const useCountries = (isOpen) => {
  const { token, logout } = useContext(AuthContext); // Obtém token e logout do contexto
  const [countries, setCountries] = useState([]);
  const [isCountriesLoading, setIsCountriesLoading] = useState(false);
  const [countriesError, setCountriesError] = useState(null);

  /**
   * Função para buscar os países da API.
   */
  const fetchCountries = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsCountriesLoading(true);
    setCountriesError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch('https://api.ads10.com.br/api/pais', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar países: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Países recebidos da API:', data); // Log para depuração
      setCountries(data);
    } catch (error) {
      console.error('Erro ao buscar países:', error);
      setCountriesError(error.message.includes('Token')
        ? 'Você precisa estar autenticado para carregar a lista de países.'
        : 'Falha ao carregar a lista de países. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(setCountriesError);
    } finally {
      setIsCountriesLoading(false);
    }
  }, [isOpen, token, logout]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  return { countries, isCountriesLoading, countriesError, refetch: fetchCountries };
};

export default useCountries;
