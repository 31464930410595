import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const usePosteData = (somenteAssociadas = false) => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [postesDetalhados, setPostesDetalhados] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    // Garantimos que o `cidadeId` e `token` estão disponíveis
    if (!token || !cidadeId) {
      setError('Token de autenticação ou ID da cidade não encontrado');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      // Construímos os headers com o `cidadeId` e `token` do contexto
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        cidadeId,
      };

      // Realizamos as requisições
      const [posteResponse, fotocelulaResponse, postefotocelulaResponse] = await Promise.all([
        fetch('https://api.ads10.com.br/api/poste', { headers }),
        fetch('https://api.ads10.com.br/api/fotocelula', { headers }),
        fetch('https://api.ads10.com.br/api/postefotocelula', { headers }),
      ]);

      // Tratamos possíveis erros de autenticação ou resposta inadequada
      const responses = [posteResponse, fotocelulaResponse, postefotocelulaResponse];
      if (responses.some((res) => res.status === 401)) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!responses.every((res) => res.ok)) {
        throw new Error('Erro ao buscar dados das APIs');
      }

      // Convertendo respostas para JSON
      const [postes, fotocelulas, postefotocelulas] = await Promise.all(
        responses.map((res) => res.json())
      );

      // Estruturando os dados para incluir apenas fotocélulas associadas
      const postesComDetalhes = postes.map((poste) => {
        const associacoesFotocelulas = postefotocelulas
          .filter((pf) => pf.posteId === poste.id && pf.ativo === 'S')
          .map((pf) => {
            const fotocelula = fotocelulas.find((f) => f.fotocelulaId === pf.fotocelulaId);
            return fotocelula
              ? {
                  ...fotocelula,
                  tensaoRede: pf.tensaoRede,
                  ligado: pf.ligado,
                  manutencao: pf.manutencao,
                  postefotocelulaId: pf.postefotocelulaId,
                  codigoPoste: poste.codigoPoste,
                }
              : null;
          })
          .filter(Boolean);

        return {
          ...poste,
          fotocelulas: associacoesFotocelulas,
        };
      });

      // Filtramos fotocélulas não associadas, se necessário
      const fotocelulasNaoAssociadas = fotocelulas.filter(
        (fotocelula) =>
          !postefotocelulas.some((pf) => pf.fotocelulaId === fotocelula.fotocelulaId && pf.ativo === 'S')
      );

      // Ajustamos o resultado de acordo com `somenteAssociadas`
      const dadosFiltrados = somenteAssociadas
        ? postesComDetalhes
        : [...postesComDetalhes, ...fotocelulasNaoAssociadas];

      setPostesDetalhados(dadosFiltrados);
      console.log("Dados detalhados dos postes:", dadosFiltrados);

    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setError(error.message);
      toast.error(`Erro ao buscar dados: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout, somenteAssociadas]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { postesDetalhados, isLoading, error, refetch: fetchData };
};

export default usePosteData;
