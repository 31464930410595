import React, { useState } from 'react';
import { FaPlus, FaTrash, FaSave } from 'react-icons/fa';
import styles from '../PosteModal.module.css';
import lumiIcon from '../../../assets/Lumi-icon.png';

const EUIsDevices = ({
  euis,
  unsavedEUIs,
  handleAddEUI,
  handleEUIChange,
  handleSaveEUI,
  handleRemoveEUI,
  animateSaveButtons,
  availableDevices,
  isAvailableDevicesLoading,
  availableDevicesError,
  token,
}) => {
  const [searchTerms, setSearchTerms] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState({});

  const isLoading = isAvailableDevicesLoading;
  const error = availableDevicesError;

  // Função para lidar com a seleção de um dispositivo EUI para um item específico
  const handleDeviceSelect = (device, index) => {
    handleEUIChange(index, { target: { value: device.deviceEUI } }); // Atualiza o campo com o EUI selecionado
    setSearchTerms((prev) => ({ ...prev, [index]: device.deviceEUI })); // Atualiza o termo de pesquisa com o valor selecionado
    setIsDropdownOpen((prev) => ({ ...prev, [index]: false })); // Fechar o dropdown após a seleção
  };

  // Função para abrir ou fechar o dropdown
  const toggleDropdown = (index) => {
    setIsDropdownOpen((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  // Função para lidar com a mudança no campo de pesquisa individual
  const handleSearchTermChange = (index, term) => {
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [index]: term,
    }));
    setIsDropdownOpen((prev) => ({ ...prev, [index]: true })); // Abrir o dropdown ao digitar
  };

  // Filtra os dispositivos disponíveis de acordo com o termo de busca e exclui os já adicionados
  const getFilteredDevices = (index) => {
    const searchTerm = searchTerms[index]?.toLowerCase() || '';
    if (!searchTerm) return []; // Se o termo de busca estiver em branco, retorna uma lista vazia

    // Obter lista de EUIs já adicionados (salvos e não salvos)
    const addedEUIs = [
      ...euis.map((eui) => eui.deviceEUI),
      ...unsavedEUIs.map((eui) => eui.deviceEUI),
    ];

    return availableDevices
      .filter((device) =>
        device.deviceEUI.toLowerCase().includes(searchTerm)
      )
      .filter((device) => !addedEUIs.includes(device.deviceEUI)); // Excluir dispositivos já adicionados
  };

  return (
    <fieldset className={styles.fieldset}>
      <legend>Dispositivos EUI</legend>

      {/* Dispositivos EUI Salvos */}
      {euis.length > 0 && (
        <div>
          <h4>Dispositivos EUI Salvos</h4>
          {euis.map((eui, index) => (
            <div key={eui.postefotocelulaId} className={styles.itemContainer}>
              <div className={styles.euiInputContainer}>
                <label className={styles.label}>
                  <img src={lumiIcon} alt="Lumi Icon" className={styles.icon} />
                  Device EUI
                </label>
                <input
                  className={styles.input}
                  type="text"
                  value={eui.deviceEUI}
                  readOnly
                />
              </div>
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveEUI(eui, index, false)} // Passamos isUnsaved como false
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Dispositivos EUI Não Salvos */}
      {unsavedEUIs.length > 0 && (
        <div>
          <h4>Novos Dispositivos EUI</h4>
          {unsavedEUIs.map((eui, index) => (
            <div key={index} className={styles.itemContainer}>
              <div className={styles.euiInputContainer}>
                <label className={styles.label}>
                  <img src={lumiIcon} alt="Lumi Icon" className={styles.icon} />
                  Device EUI
                </label>

                {/* Dropdown dinâmico de pesquisa */}
                <div className={styles.dropdown}>
                  <input
                    type="text"
                    className={styles.euiInputSmaller}
                    placeholder="Pesquisar EUI..."
                    value={searchTerms[index] || ''} // O termo de busca é específico para cada item
                    onChange={(e) => handleSearchTermChange(index, e.target.value)}
                    onClick={() => toggleDropdown(index)} // Abrir o dropdown ao clicar no input
                  />

                  {/* Lista de dropdown */}
                  {isDropdownOpen[index] && !isLoading && !error && getFilteredDevices(index).length > 0 && (
                    <ul className={styles.dropdownList}>
                      {getFilteredDevices(index)
                        .slice(0, 5) // Mostra no máximo 5 resultados
                        .map((device) => (
                          <li
                            key={device.fotocelulaId}
                            className={styles.dropdownItem}
                            onClick={() => handleDeviceSelect(device, index)} // Seleciona o dispositivo e preenche o input
                          >
                            {device.deviceEUI}
                          </li>
                        ))}
                    </ul>
                  )}

                  {/* Ajuste na condição para exibir a mensagem */}
                  {isDropdownOpen[index] &&
                    searchTerms[index] && // Verifica se o usuário digitou algo
                    getFilteredDevices(index).length === 0 &&
                    !isLoading &&
                    !error && (
                      <p className={styles.noResults}>Nenhum dispositivo disponível.</p>
                    )}

                  {isDropdownOpen[index] && isLoading && <p>Carregando dispositivos...</p>}
                  {isDropdownOpen[index] && error && (
                    <p className={styles.errorMessage}>Erro: {error}</p>
                  )}
                </div>
              </div>

              {/* Botões de salvar e remover */}
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={`${styles.saveButton} ${
                    animateSaveButtons[index] ? styles.animateSaveButton : ''
                  }`}
                  onClick={() => handleSaveEUI(index)} // Salvar o EUI não salvo
                >
                  <FaSave />
                </button>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveEUI(eui, index, true)} // Passamos isUnsaved como true
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Botão para adicionar um novo dispositivo EUI */}
      <button
        type="button"
        className={styles.addButton}
        onClick={() => {
          handleAddEUI(); // Adicionar novo EUI
          // Limpar o termo de busca para o novo índice
          const newIndex = unsavedEUIs.length;
          setSearchTerms((prevTerms) => ({
            ...prevTerms,
            [newIndex]: '',
          }));
        }}
      >
        <FaPlus /> Adicionar Dispositivo EUI
      </button>
    </fieldset>
  );
};

export default EUIsDevices;
