import React, { useState, useEffect } from 'react';
import { FaIdCard, FaTools, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import styles from './FormatoPostesModal.module.css';

Modal.setAppElement('#root');

/*
{
  "fp_id": "1",
  "fp_nome": "Curso Simples"
}
*/
const FormatoPostesModal = ({ isOpen, onRequestClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    fp_id: '',
    fp_nome: '',
  });

  useEffect(() => {
    if (initialData) {
      const formattedData = {
        ...initialData,
        fp_id: initialData.fp_id,
      };
      setFormData(formattedData);
    } else {
      setFormData({
        fp_id: '',
        fp_nome: '',
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData });
    onRequestClose();
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Informações de Formato Poste"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.postePage}>
        <h1 className={styles.header}>Especificações</h1>
        <form onSubmit={handleSubmit}>
          <fieldset className={styles.fieldset}>
            <div className={styles.formGroup}>
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaIdCard /> ID</label>
                <input
                  className={styles.input}
                  type="text"
                  name="fp_id"
                  value={formData.fp_id}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className={styles.colSpan2}>
                <label className={styles.label}><FaTools /> Tipo</label>
                <input
                  className={styles.input}
                  type="text"
                  name="fp_nome"
                  value={formData.fp_nome}
                  onChange={handleChange}
                />
              </div>

            </div>
          </fieldset>

          <button className={styles.button} type="submit">Salvar</button>
        </form>

      </div>
    </Modal>
  );
};

export default FormatoPostesModal;
