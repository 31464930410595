// src/components/AtrelarLegend/AtrelarLegend.jsx

import React, { useState } from 'react';
import styles from './AtrelarLegend.module.css';
import { MdPersonPin } from 'react-icons/md'; // Importando o ícone do react-icons

const AtrelarLegend = ({ markerCounts, toggleMarkerVisibility, visibleMarkers }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  // Definindo as cores diretamente no componente
  const colors = {
    poste: '#0000FF', // Azul para postes
    dispositivo: '#FF0000', // Vermelho para dispositivos
    userLocation: '#0000FF', // Verde para localização do usuário
  };

  // Garantir que markerCounts tenha valores seguros
  const safeMarkerCounts = markerCounts || {
    poste: 0,
    dispositivo: 0,
    userLocation: 0,
  };

  const safeVisibleMarkers = visibleMarkers || {
    poste: true,
    dispositivo: true,
    userLocation: true,
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={styles.legendContainer}>
      <h3 className={styles.legendTitle}>Legenda</h3>
      {!isMinimized && (
        <ul className={styles.legendList}>
          <li
            onClick={() => toggleMarkerVisibility('poste')}
            className={!safeVisibleMarkers.poste ? styles.inactive : ''}
          >
            <span
              className={styles.legendCircle}
              style={{ backgroundColor: colors.poste }}
            ></span>
            <span>Poste ({safeMarkerCounts.poste})</span>
          </li>
          <li
            onClick={() => toggleMarkerVisibility('dispositivo')}
            className={!safeVisibleMarkers.dispositivo ? styles.inactive : ''}
          >
            <span
              className={styles.legendCircle}
              style={{ backgroundColor: colors.dispositivo }}
            ></span>
            <span>Dispositivo ({safeMarkerCounts.dispositivo})</span>
          </li>
          <li
            onClick={() => toggleMarkerVisibility('userLocation')}
            className={!safeVisibleMarkers.userLocation ? styles.inactive : ''}
          >
            <MdPersonPin
              size={24}
              color={colors.userLocation}
              className={styles.legendIcon}
            />
            <span>Sua Localização ({safeMarkerCounts.userLocation})</span>
          </li>
        </ul>
      )}
      <button className={styles.minimizeButton} onClick={toggleMinimize}>
        {isMinimized ? 'Expandir' : 'Minimizar'}
      </button>
    </div>
  );
};

// Definindo valores padrão para evitar problemas com `undefined`
AtrelarLegend.defaultProps = {
  markerCounts: {
    poste: 0,
    dispositivo: 0,
    userLocation: 0,
  },
  toggleMarkerVisibility: () => {},
  visibleMarkers: {
    poste: true,
    dispositivo: true,
    userLocation: true,
  },
};

export default AtrelarLegend;
