// src/components/PosteConfigurationModal/PosteConfigurationModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaPowerOff } from 'react-icons/fa';
import styles from './PosteConfigurationModal.module.css';
import plansData from '../../data/plans.json';

const PosteConfigurationModal = ({ isOpen, onRequestClose, poste }) => {
  const [mode, setMode] = useState('fotocelula');
  const [isOn, setIsOn] = useState(false);
  const [dimValue, setDimValue] = useState(50);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');

  useEffect(() => {
    if (poste) {
      // Load the initial state from the poste configuration
      setMode(poste.mode || 'fotocelula');
      setIsOn(poste.isOn || false);
      setDimValue(poste.dimValue !== undefined ? poste.dimValue : 50);
      setStartTime(poste.startTime || '');
      setEndTime(poste.endTime || '');
      setSelectedPlan('');
    } else {
      // Reset all fields if no poste is selected
      setMode('fotocelula');
      setIsOn(false);
      setDimValue(50);
      setStartTime('');
      setEndTime('');
      setSelectedPlan('');
    }
  }, [poste]);

  useEffect(() => {
    if (selectedPlan && selectedPlan !== 'personalizar') {
      const plan = plansData.find(p => p.id === selectedPlan);
      if (plan) {
        setStartTime(plan.startTime);
        setEndTime(plan.endTime);
        setDimValue(plan.dimValue);
      }
    }
  }, [selectedPlan]);

  const handleSaveConfiguration = () => {
    if (!poste) {
      alert('Poste não selecionado para configuração.');
      return;
    }
    // Logic to save the configuration
    const updatedConfiguration = {
      id: poste.id, // ensure id is included
      mode,
      isOn,
      dimValue,
      startTime,
      endTime,
    };
    console.log('Saved configuration:', updatedConfiguration);
    // Aqui, você provavelmente enviará o updatedConfiguration para a API
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>Configuração do Poste</h2>
        
        <div className={styles.controlGroup}>
          <label htmlFor="mode">Modo de Operação:</label>
          <select
            id="mode"
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            className={styles.selectInput}
          >
            <option value="fotocelula">Fotocélula</option>
            <option value="programado">Programado</option>
          </select>
        </div>
        
        {mode === 'programado' && (
          <div className={styles.controlGroup}>
            <label htmlFor="selectedPlan">Plano:</label>
            <select
              id="selectedPlan"
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              className={styles.selectInput}
            >
              <option value="">Selecione um Plano...</option>
              {plansData.map(plan => (
                <option key={plan.id} value={plan.id}>
                  {plan.name}
                </option>
              ))}
              <option value="personalizar">Personalizar</option>
            </select>
          </div>
        )}

        {mode === 'programado' && selectedPlan && (
          <>
            <div className={styles.controlGroup}>
              <label htmlFor="startTime">Hora de Acender:</label>
              <input
                type="time"
                id="startTime"
                value={startTime}
                readOnly={selectedPlan !== 'personalizar'}
                onChange={(e) => setStartTime(e.target.value)}
                className={styles.input}
              />
            </div>
            <div className={styles.controlGroup}>
              <label htmlFor="endTime">Hora de Apagar:</label>
              <input
                type="time"
                id="endTime"
                value={endTime}
                readOnly={selectedPlan !== 'personalizar'}
                onChange={(e) => setEndTime(e.target.value)}
                className={styles.input}
              />
            </div>
          </>
        )}

        {mode !== 'fotocelula' && (
          <div className={styles.controlGroup}>
            <label>Dimerização:</label>
            {selectedPlan === 'personalizar' ? (
              <>
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={dimValue}
                  onChange={(e) => setDimValue(Number(e.target.value))}
                  className={styles.slider}
                />
                <span>{dimValue}%</span>
              </>
            ) : (
              <span>{dimValue}%</span>
            )}
          </div>
        )}

        <div className={styles.controlGroup}>
          <button
            onClick={() => setIsOn(!isOn)}
            className={isOn ? styles.powerButtonOn : styles.powerButtonOff}
          >
            <FaPowerOff />
            {isOn ? 'Apagar' : 'Acender'}
          </button>
        </div>

        <div className={styles.controlGroup}>
          <button onClick={handleSaveConfiguration} className={styles.saveButton}>
            Salvar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PosteConfigurationModal;
