import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useAlertaSituacao = () => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [situacoes, setSituacoes] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Definindo fetchSituacoes como uma função memorizada com useCallback
  const fetchSituacoes = useCallback(async () => {
    try {
      if (!token || !cidadeId) throw new Error('Token de autenticação ou ID da cidade não encontrado');

      const response = await fetch('https://api.ads10.com.br/api/alertasituacao', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          cidadeId: cidadeId, // Adicionando o ID da cidade nos cabeçalhos
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout(); // Realiza logout ao expirar sessão
          return;
        }
        throw new Error(`Erro ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();
      const situacaoMap = {};
      data.forEach((situacao) => {
        situacaoMap[situacao.alertasituacaoId] = situacao.alertasituacaoNome;
      });
      setSituacoes(situacaoMap);
    } catch (err) {
      setError(err.message);
      toast.error(`Erro ao buscar situações dos alertas: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [token, cidadeId, logout]);

  // Chamando fetchSituacoes dentro do useEffect
  useEffect(() => {
    fetchSituacoes();
  }, [fetchSituacoes]); // Adicionando fetchSituacoes como dependência

  return { situacoes, isLoading, error };
};

export default useAlertaSituacao;
