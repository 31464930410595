import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de cidades com base no estado selecionado.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @param {string|number} stateId - ID do estado para filtrar as cidades.
 * @returns {object} - Contém a lista de cidades, estado de carregamento, erro e função para refetch.
 */
const useCities = (isOpen, stateId) => {
  const { token, logout } = useContext(AuthContext); // Obtém token e logout do contexto
  const [cities, setCities] = useState([]);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [citiesError, setCitiesError] = useState(null);

  /**
   * Função para buscar as cidades da API com base no estado selecionado.
   */
  const fetchCities = useCallback(async () => {
    if (!isOpen || !stateId) return; // Não buscar se o modal não estiver aberto ou se o estado não estiver selecionado

    setIsCitiesLoading(true);
    setCitiesError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      // Supondo que o endpoint aceita um parâmetro de query para filtrar por estado
      const response = await fetch(`https://api.ads10.com.br/api/cidade?estado=${stateId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Incluindo o token de autenticação
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar cidades: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Cidades recebidas da API:', data); // Log para depuração
      setCities(data);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
      setCitiesError(error.message.includes('Token')
        ? 'Você precisa estar autenticado para carregar a lista de cidades.'
        : 'Falha ao carregar a lista de cidades. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(setCitiesError);
    } finally {
      setIsCitiesLoading(false);
    }
  }, [isOpen, stateId, token, logout]);

  useEffect(() => {
    fetchCities();
  }, [fetchCities]);

  return { cities, isCitiesLoading, citiesError, refetch: fetchCities };
};

export default useCities;
