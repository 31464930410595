// src/components/PageLayout/PageLayout.jsx

import React from 'react';
import styles from './PageLayout.module.css';
import Footer from '../Footer/Footer';

const PageLayout = ({ title, children }) => {
  return (
    <div className={styles.pageLayout}>
      <header className={styles.header}>
        <h1 className={styles.pageTitle}>{title}</h1> {/* Título da página passado como prop */}
      </header>

      <main className={styles.pageContent}>
        {children}
      </main>

      <Footer />
    </div>
  );
};

export default PageLayout;
