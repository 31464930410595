// src/pages/Levantamentos/graficos/GraficoOrdensServico.jsx

import React, { useEffect, useState, useContext } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import { AuthContext } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import styles from '../Levantamentos.module.css';

const GraficoOrdensServico = ({ startDate, endDate, startTime, endTime }) => {
  const { token, cidadeId, logout } = useContext(AuthContext);
  const [ordensData, setOrdensData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchOrdensData = async () => {
      if (!token || !cidadeId) {
        toast.error('Token de autenticação ou ID da cidade não encontrado');
        return;
      }

      try {
        const response = await fetch('https://api.ads10.com.br/api/os', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            cidadeId: cidadeId,
          },
        });

        if (response.status === 401) {
          toast.error('Sessão expirada. Faça login novamente.');
          logout();
          return;
        }

        if (!response.ok) {
          throw new Error('Erro ao buscar dados das ordens de serviço');
        }

        const data = await response.json();
        const filteredData = data.filter((os) => {
          const osDate = new Date(`${os.osDataAbertura}T${os.osHoraAbertura}`);
          const startDateObj = startDate ? new Date(`${startDate}T${startTime || '00:00'}`) : null;
          const endDateObj = endDate ? new Date(`${endDate}T${endTime || '23:59'}`) : null;

          return (!startDateObj || osDate >= startDateObj) && (!endDateObj || osDate <= endDateObj);
        });

        const dataCounts = filteredData.reduce((acc, os) => {
          acc[os.osSituacaoId] = (acc[os.osSituacaoId] || 0) + 1;
          return acc;
        }, {});

        const formattedData = Object.keys(dataCounts).map((key) => ({
          name: `Situação ${key}`,
          value: dataCounts[key],
        }));

        setOrdensData(formattedData);
      } catch (error) {
        toast.error(`Erro ao buscar dados das ordens de serviço: ${error.message}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrdensData();
  }, [token, cidadeId, logout, startDate, endDate, startTime, endTime]);

  return (
    <div className={styles.chartCard}>
      <h3 className={styles.chartTitle}>Distribuição de Ordens de Serviço</h3>
      {isLoading ? (
        <p>Carregando dados...</p>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={ordensData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#36A2EB" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default GraficoOrdensServico;
