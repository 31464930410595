import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

const useFormatTampo = (isOpen) => {
  const { token, logout } = useContext(AuthContext); // Obtém token e logout do contexto
  const [formatosTampo, setFormatosTampo] = useState([]);
  const [isFormatTampoLoading, setIsFormatTampoLoading] = useState(false);
  const [formatoTampoError, setFormatoTampoError] = useState(null);

  const fetchFormatTampo = useCallback(async () => {
    if (!isOpen) return;

    setIsFormatTampoLoading(true);
    setFormatoTampoError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch('https://api.ads10.com.br/api/formatotampa', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar formatos de tampo: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Formatos de Tampo recebidos da API:', data);
      setFormatosTampo(data);
    } catch (error) {
      console.error('Erro ao buscar formatos de tampo:', error);
      setFormatoTampoError(error.message.includes('Token')
        ? 'Você precisa estar autenticado para carregar a lista de formatos de tampo.'
        : 'Falha ao carregar a lista de formatos de tampo. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(setFormatoTampoError);
    } finally {
      setIsFormatTampoLoading(false);
    }
  }, [isOpen, token, logout]);

  useEffect(() => {
    fetchFormatTampo();
  }, [fetchFormatTampo]);

  return { formatosTampo, isFormatTampoLoading, formatoTampoError, refetch: fetchFormatTampo };
};

export default useFormatTampo;
