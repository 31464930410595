import { useState, useEffect } from 'react';

/**
 * Hook personalizado para debounce de um valor.
 * @param {any} value - O valor a ser debounced.
 * @param {number} delay - O atraso em milissegundos.
 * @returns {any} - O valor debounced.
 */
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Define um timeout para atualizar o valor debounced após o delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Limpa o timeout se o valor ou o delay mudarem antes do término
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
