import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    FaUser,
    FaClipboardList,
    FaBolt,
    FaFileAlt,
    FaCog,
    FaBell,
    FaTools,
    FaLightbulb,
    FaTachometerAlt,
    FaMap,
    FaSignOutAlt,
    FaLink,
    FaBars,
    FaTimes,
} from 'react-icons/fa';
import styles from './Sidebar.module.css';
import NavItem from './NavItem';
import SubMenu from './SubMenu';
import directLuzLogo from '../../assets/login-directluz-icone.png';
import { AuthContext } from '../../context/AuthContext'; // Adicione isso para usar o contexto de autenticação

const Sidebar = () => {
    const [isCadastrosOpen, setIsCadastrosOpen] = useState(false);
    const [unresolvedAlerts, setUnresolvedAlerts] = useState(0);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [usuarioNome, setUsuarioNome] = useState('');
    const { logout } = useContext(AuthContext); // Obtém a função de logout do contexto
    const navigate = useNavigate();
    const location = useLocation();
    const sidebarRef = useRef(null);
    const toggleButtonRef = useRef(null);

    // Função para buscar alertas com status "Aguardando"
    useEffect(() => {
        const fetchAlertas = async () => {
            try {
                const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
                const response = await fetch('https://api.ads10.com.br/api/alerta', {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        'CidadeId': 3315,
                    },
                });

                console.log("Sidebar.js: Status da resposta:", response.status);

                // Verifique o tipo de conteúdo para confirmar que a resposta é JSON
                const contentType = response.headers.get("content-type");
                if (!contentType || !contentType.includes("application/json")) {
                    const text = await response.text();
                    console.error("Sidebar.js: Resposta inesperada (não JSON):", text);
                    throw new Error("A resposta não está no formato JSON esperado.");
                }

                const data = await response.json();
                console.log("Sidebar.js: Dados recebidos:", data);

                // Verifique se os dados recebidos são um array como esperado
                if (!Array.isArray(data)) {
                    console.error("Sidebar.js: Dados não são um array:", data);
                    throw new Error("Formato de dados inesperado");
                }

                const unresolvedCount = data.filter(alert => alert.alertaSituacao === '1').length;
                setUnresolvedAlerts(unresolvedCount);
            } catch (error) {
                console.error("Erro ao carregar os alertas:", error);
            }
        };

        fetchAlertas();
    }, []);


    // Função para buscar informações do usuário
    useEffect(() => {
        const storedUsuarioNome = localStorage.getItem('usuarioNome') || sessionStorage.getItem('usuarioNome');

        if (storedUsuarioNome) {
            setUsuarioNome(storedUsuarioNome);
        } else {
            const fetchUsuario = async () => {
                try {
                    const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
                    const response = await fetch('https://api.ads10.com.br/api/usuario', {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    if (response.ok) {
                        const data = await response.json();
                        const usuario = data.find(user => user.usuarioNome);

                        if (usuario) {
                            setUsuarioNome(usuario.usuarioNome);
                        }
                    } else {
                        console.error('Erro ao buscar dados do usuário.');
                    }
                } catch (error) {
                    console.error('Erro ao carregar dados do usuário: ', error);
                }
            };

            fetchUsuario();
        }
    }, []);

    useEffect(() => {
        setIsSidebarOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        if (isSidebarOpen && sidebarRef.current) {
            sidebarRef.current.focus();
        } else if (!isSidebarOpen && toggleButtonRef.current) {
            toggleButtonRef.current.focus();
        }
    }, [isSidebarOpen]);

    const toggleCadastros = () => {
        setIsCadastrosOpen(!isCadastrosOpen);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const handleLogout = () => {
        const confirmLogout = window.confirm('Tem certeza de que deseja sair?');
        if (confirmLogout) {
            // Chama a função de logout do contexto para limpar o estado de autenticação
            logout();
            // Redireciona para a tela de login
            navigate('/login');
        }
    };

    const navItems = [
        { to: '/dashboard', icon: FaTachometerAlt, label: 'Dashboard' },
        { to: '/mapa', icon: FaMap, label: 'Mapa' },
        {
            label: 'Cadastros',
            icon: FaClipboardList,
            onClick: toggleCadastros,
            children: [
                { to: '/postes', icon: FaLightbulb, label: 'Postes' },
                { to: '/dispositivos', icon: FaTools, label: 'Dispositivos' },
                { to: '/perfis-configuracao', icon: FaCog, label: 'Perfis de Configuração' },
                { to: '/grupos-postes', icon: FaMap, label: 'Criação de Grupos' },

                { to: '/bracos', icon: FaLightbulb, label: 'Braços' },
                { to: '/lampadas', icon: FaTools, label: 'Lampâdas' },
                { to: '/tipopostes', icon: FaCog, label: 'Tipos de Poste' },
                { to: '/tipovias', icon: FaMap, label: 'Tipos de Via' },
            ],
        },
        { to: '/levantamentos', icon: FaBolt, label: 'Levantamentos' },
        { to: '/relatorios', icon: FaFileAlt, label: 'Relatórios' },
        { to: '/configuracoes', icon: FaCog, label: 'Configurações' },
        { to: '/alertas', icon: FaBell, label: 'Alertas', notification: unresolvedAlerts },
        { to: '/ordens-servico', icon: FaTools, label: 'Ordens de Serviço' },
        { to: '/atrelar-dispositivo', icon: FaLink, label: 'Atrelar Dispositivo' },
    ];

    return (
        <>
            <button
                className={styles.toggleButton}
                onClick={toggleSidebar}
                aria-label={isSidebarOpen ? 'Fechar menu' : 'Abrir menu'}
                aria-expanded={isSidebarOpen}
                ref={toggleButtonRef}
            >
                {isSidebarOpen ? <FaTimes /> : <FaBars />}
            </button>

            {isSidebarOpen && <div className={styles.overlay} onClick={toggleSidebar} aria-hidden="true"></div>}

            <aside
                className={`${styles.sidebar} ${isSidebarOpen ? styles.open : ''}`}
                ref={sidebarRef}
                tabIndex={-1}
                aria-hidden={!isSidebarOpen}
            >
                <div className={styles.logo}>
                    <img src={directLuzLogo} alt="DirectLuz Logo" className={styles.logoImage} />
                </div>
                <div className={styles.user}>
                    <FaUser className={styles.icon} />
                    <span className={styles.userName}>
                        {usuarioNome}
                    </span>
                </div>
                <nav className={styles.nav} aria-label="Navegação Principal">
                    <ul>
                        {navItems.map((item, index) => (
                            item.children ? (
                                <React.Fragment key={index}>
                                    <NavItem
                                        icon={item.icon}
                                        label={item.label}
                                        onClick={item.onClick}
                                    />
                                    {isCadastrosOpen && <SubMenu items={item.children} />}
                                </React.Fragment>
                            ) : (
                                <NavItem
                                    key={index}
                                    to={item.to}
                                    icon={item.icon}
                                    label={item.label}
                                    notification={item.notification}
                                />
                            )
                        ))}
                    </ul>
                </nav>
                <div className={styles.logoutSection}>
                    <button onClick={handleLogout} className={styles.logoutButton}>
                        <FaSignOutAlt className={styles.icon} />
                        <span>Sair</span>
                    </button>
                </div>
            </aside>
        </>
    );
};

export default Sidebar;
