import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaUser, FaLock, FaCity } from 'react-icons/fa';
import { toast } from 'react-toastify';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import styles from './Login.module.css';
import { AuthContext } from '../../context/AuthContext';

const FormularioLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cidadeId, setCidadeId] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);
  const [captchaToken, setCaptchaToken] = useState('');
  const [showCaptcha, setShowCaptcha] = useState(false);

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const hcaptchaRef = useRef(null);

  // Carregar dados salvos ao carregar a página
  useEffect(() => {
    const storedRememberMe = localStorage.getItem('rememberMe') === 'true';
    setRememberMe(storedRememberMe);

    if (storedRememberMe) {
      const storedUsername = localStorage.getItem('username') || '';
      const storedPassword = localStorage.getItem('password') || '';
      const storedCidadeId = localStorage.getItem('cidadeId') || '';

      setUsername(storedUsername);
      setPassword(storedPassword);
      setCidadeId(storedCidadeId);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (showCaptcha && !captchaToken) {
      toast.error('Por favor, resolva o CAPTCHA antes de tentar novamente.');
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch('https://api.ads10.com.br/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          cidadeId, // Enviando cidadeId no header
        },
        body: JSON.stringify({
          usuarioUserName: username,
          usuarioSenha: password,
          cidadeId: parseInt(cidadeId, 10),
          captchaToken,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        const { token, usuarioId, usuarioUserName, usuarioNome, cidadeId } = data;

        toast.success('Login realizado com sucesso!');

        // Armazenar os dados no localStorage ou sessionStorage conforme rememberMe
        if (rememberMe) {
          localStorage.setItem('jwtToken', token);
          localStorage.setItem('usuarioId', usuarioId);
          localStorage.setItem('usuarioUserName', usuarioUserName);
          localStorage.setItem('usuarioNome', usuarioNome);
          localStorage.setItem('cidadeId', cidadeId);
          localStorage.setItem('rememberMe', 'true');
          // Armazenando dados de login ao marcar "manter conectado"
          localStorage.setItem('username', username);
          localStorage.setItem('password', password);
          localStorage.setItem('cidadeId', cidadeId);
        } else {
          sessionStorage.setItem('jwtToken', token);
          sessionStorage.setItem('usuarioId', usuarioId);
          sessionStorage.setItem('usuarioUserName', usuarioUserName);
          sessionStorage.setItem('usuarioNome', usuarioNome);
          sessionStorage.setItem('cidadeId', cidadeId);
          localStorage.setItem('rememberMe', 'false');
          // Removendo credenciais de login ao desmarcar "manter conectado"
          localStorage.removeItem('username');
          localStorage.removeItem('password');
          localStorage.removeItem('cidadeId');
        }

        login(token, usuarioId, usuarioNome, cidadeId, rememberMe);
        navigate('/dashboard');
      } else {
        setFailedAttempts((prev) => prev + 1);
        const errorMessage = data.message ? data.message : 'Verifique suas credenciais.';
        toast.error(`Erro no login: ${errorMessage}`);

        if (failedAttempts + 1 >= 3) {
          setShowCaptcha(true);
        }

        setIsLoading(false);

        if (showCaptcha && hcaptchaRef.current) {
          hcaptchaRef.current.resetCaptcha();
          setCaptchaToken('');
        }
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      toast.error('Erro ao conectar-se ao servidor. Tente novamente mais tarde.');
      setIsLoading(false);
    }
  };

  // Atualiza o estado de "manter conectado" e salva ou limpa as credenciais
  const handleRememberMeChange = (e) => {
    const isChecked = e.target.checked;
    setRememberMe(isChecked);

    if (!isChecked) {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
      localStorage.removeItem('cidadeId');
      localStorage.setItem('rememberMe', 'false');
    }
  };

  const handleCaptchaVerification = (token) => {
    setCaptchaToken(token);
  };

  return (
    <div className={styles.formWrapper}>
      {isLoading && <LoadingOverlay />}
      <form onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <FaUser className={styles.icon} />
          <input
            type="text"
            className={styles.inputField}
            placeholder="Nome de Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <FaLock className={styles.icon} />
          <input
            type="password"
            className={styles.inputField}
            placeholder="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputGroup}>
          <FaCity className={styles.icon} />
          <input
            type="text"
            className={`${styles.inputField} ${styles.inputCidade}`}
            placeholder="ID da Cidade"
            value={cidadeId}
            onChange={(e) => setCidadeId(e.target.value)}
            required
          />
        </div>
        <div className={styles.checkboxGroup}>
          <label htmlFor="rememberMe" className={styles.checkboxLabel}>
            <input
              type="checkbox"
              id="rememberMe"
              className={styles.checkbox}
              checked={rememberMe}
              onChange={handleRememberMeChange}
            />
            Manter conectado
          </label>
        </div>

        {showCaptcha && (
          <div className={styles.captchaContainer}>
            <HCaptcha
              sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
              onVerify={handleCaptchaVerification}
              ref={hcaptchaRef}
            />
          </div>
        )}

        <button
          type="submit"
          className={styles.button}
          disabled={showCaptcha && !captchaToken}
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default FormularioLogin;
