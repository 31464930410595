// src/pages/Mapa/Legend.jsx

import React, { useState } from 'react';
import { IoMdWifi } from 'react-icons/io';
import styles from './Legend.module.css';
import acesaIcon from '../../assets/green-icon.png';
import apagadaIcon from '../../assets/blue-icon.png';
import semFotocelulaIcon from '../../assets/grey-icon.png';
import alertaIcon from '../../assets/orange-icon.png';
import subtensaoIcon from '../../assets/subtensao-icon.png';
import sobretensaoIcon from '../../assets/sobretensao-icon.png';

const Legend = ({ markerCounts, toggleMarkerVisibility, visibleMarkers }) => {
  const [isMinimized, setIsMinimized] = useState(false);

  const safeMarkerCounts = markerCounts || {
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
    semPoste: 0, // Nome atualizado
  };

  const safeVisibleMarkers = visibleMarkers || {
    acesa: true,
    apagada: true,
    semFotocelula: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
    semPoste: true, // Nome atualizado
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  return (
    <div className={styles.legendContainer}>
      <h3 className={styles.legendTitle}>Legenda</h3>
      {!isMinimized && (
        <ul className={styles.legendList}>
          <li onClick={() => toggleMarkerVisibility('acesa')} className={!safeVisibleMarkers.acesa ? styles.inactive : ''}>
            <img src={acesaIcon} alt="Acesa" className={styles.icon} />
            <span>Acesa ({safeMarkerCounts.acesa})</span>
          </li>
          <li onClick={() => toggleMarkerVisibility('apagada')} className={!safeVisibleMarkers.apagada ? styles.inactive : ''}>
            <img src={apagadaIcon} alt="Apagada" className={styles.icon} />
            <span>Apagada ({safeMarkerCounts.apagada})</span>
          </li>
          <li onClick={() => toggleMarkerVisibility('semFotocelula')} className={!safeVisibleMarkers.semFotocelula ? styles.inactive : ''}>
            <img src={semFotocelulaIcon} alt="Sem Fotocélula" className={styles.icon} />
            <span>Sem Fotocélula ({safeMarkerCounts.semFotocelula})</span>
          </li>
          <li onClick={() => toggleMarkerVisibility('alerta')} className={!safeVisibleMarkers.alerta ? styles.inactive : ''}>
            <img src={alertaIcon} alt="Alerta" className={styles.icon} />
            <span>Alerta ({safeMarkerCounts.alerta})</span>
          </li>
          <li onClick={() => toggleMarkerVisibility('subTensao')} className={!safeVisibleMarkers.subTensao ? styles.inactive : ''}>
            <img src={subtensaoIcon} alt="Subtensão" className={styles.icon} />
            <span>Subtensão ({safeMarkerCounts.subTensao})</span>
          </li>
          <li onClick={() => toggleMarkerVisibility('sobreTensao')} className={!safeVisibleMarkers.sobreTensao ? styles.inactive : ''}>
            <img src={sobretensaoIcon} alt="Sobretensão" className={styles.icon} />
            <span>Sobretensão ({safeMarkerCounts.sobreTensao})</span>
          </li>
          <li onClick={() => toggleMarkerVisibility('semPoste')} className={!safeVisibleMarkers.semPoste ? styles.inactive : ''}>
            <IoMdWifi size={20} color="#007BFF" className={styles.icon} />
            <span>Dispositivo sem Poste ({safeMarkerCounts.semPoste})</span>
          </li>
        </ul>
      )}
      <button className={styles.minimizeButton} onClick={toggleMinimize}>
        {isMinimized ? 'Expandir' : 'Minimizar'}
      </button>
    </div>
  );
};

Legend.defaultProps = {
  markerCounts: {
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
    semPoste: 0, // Nome atualizado
  },
  toggleMarkerVisibility: () => {},
  visibleMarkers: {
    acesa: true,
    apagada: true,
    semFotocelula: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
    semPoste: true, // Nome atualizado
  },
};

export default Legend;
