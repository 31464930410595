import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import usePrestadores from '../../hooks/usePrestadores';
import styles from './ServiceOrderModal.module.css';

Modal.setAppElement('#root');

const ServiceOrderModal = ({ isOpen, onRequestClose, onSubmit, alertData }) => {
  const [formData, setFormData] = useState({
    idAlerta: alertData ? alertData.alertaId : '',
    idPoste: alertData ? alertData.alertaPosteId : '',
    cidade: alertData ? alertData.alertaCidade : '',
    telefone: alertData ? alertData.alertaTelefone : '',
    prestador: '',
    descricaoAlerta: alertData ? alertData.alertaMotivo : ''
  });

  const { prestadores, isLoading: prestadoresLoading, error: prestadoresError } = usePrestadores();

  useEffect(() => {
    if (alertData) {
      setFormData({
        idAlerta: alertData.alertaId || '',
        idPoste: alertData.alertaPosteId || '',
        cidade: alertData.alertaCidade || '',
        telefone: alertData.alertaTelefone || '',
        prestador: '',
        descricaoAlerta: alertData.alertaMotivo || ''
      });
    }
  }, [alertData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validação básica
    if (!formData.prestador) {
      alert('Por favor, selecione um prestador de serviço.');
      return;
    }
    onSubmit(formData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Cadastrar Ordem de Serviço"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.serviceOrderPage}>
        <h2 className={styles.header}>Cadastrar Ordem de Serviço</h2>
        <form onSubmit={handleSubmit}>
          <fieldset className={styles.fieldset}>
            <div className={styles.formGroup}>
              <div className={styles.colSpan1}>
                <label className={styles.label}>ID do Alerta:</label>
                <input
                  className={styles.input}
                  type="text"
                  name="idAlerta"
                  value={formData.idAlerta}
                  readOnly
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Poste:</label>
                <input
                  className={styles.input}
                  type="text"
                  name="idPoste"
                  value={formData.idPoste}
                  readOnly
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Cidade:</label>
                <input
                  className={styles.input}
                  type="text"
                  name="cidade"
                  value={formData.cidade}
                  readOnly
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Telefone:</label>
                <input
                  className={styles.input}
                  type="text"
                  name="telefone"
                  value={formData.telefone}
                  readOnly
                />
              </div>
              <div className={styles.colSpan1}>
                <label className={styles.label}>Prestador:</label>
                {prestadoresLoading ? (
                  <p>Carregando prestadores...</p>
                ) : prestadoresError ? (
                  <p>Erro ao carregar prestadores: {prestadoresError}</p>
                ) : (
                  <select
                    className={styles.input}
                    name="prestador"
                    value={formData.prestador}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Selecione um prestador</option>
                    {prestadores.map((prestador) => (
                      <option key={prestador.pessoaId} value={prestador.pessoaId}>
                        {prestador.pessoaNome}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className={styles.colSpan3}>
                <label className={styles.label}>Descrição do Alerta:</label>
                <input
                  className={styles.input}
                  type="text"
                  name="descricaoAlerta"
                  value={formData.descricaoAlerta}
                  readOnly
                />
              </div>
            </div>
          </fieldset>
          <button type="submit" className={styles.button}>
            Cadastrar Ordem de Serviço
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default ServiceOrderModal;
