import React from 'react';
import PageLayout from '../../../components/PageLayout/PageLayout';
//import styles from './Bracos.module.css';
import BracoTiposTabela from './BracoTiposTabela';
import BracoDimensaoTabela from './BracoDimensaoTabela';


const Bracos = () => {

  return (
    <PageLayout title="Lista de Braços">
        <>
          <BracoDimensaoTabela />
        </>

    </PageLayout>);


};

export default Bracos;