// src/pages/AtrelarDispositivo/AtrelarDispositivo.js

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { GiDoubleStreetLights } from 'react-icons/gi'; // Ícone para Postes
import { TiWiFiOutline } from 'react-icons/ti'; // Ícone para Dispositivos
import { FaCrosshairs, FaLayerGroup, FaTag, FaSearch } from 'react-icons/fa'; // Ícones para botões (FaEye substituído por FaTag)
import { MdPersonPin } from 'react-icons/md'; // Ícone para localização do usuário
import { renderToStaticMarkup } from 'react-dom/server';
import AtrelarModal from './AtrelarModal';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { lightMapStyles } from '../../config/mapStyles';
import AtrelarLegend from './AtrelarLegend';
import styles from './AtrelarDispositivo.module.css';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const MAP_CENTER = { lat: -8.060, lng: -34.946 };

const AtrelarDispositivo = () => {
  // Referência do mapa
  const mapRef = useRef(null);

  // Estados para dados e funcionalidades
  const [icons, setIcons] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMarkerData, setSelectedMarkerData] = useState(null);
  const [selectedMarkerType, setSelectedMarkerType] = useState(''); // 'poste' ou 'dispositivo'
  const [postes, setPostes] = useState([]);
  const [dispositivos, setDispositivos] = useState([]);
  const [fotocelulas, setFotocelulas] = useState([]);
  const [associacoes, setAssociacoes] = useState([]);
  const [attachedDeviceIds, setAttachedDeviceIds] = useState(new Set());
  const [userLocation, setUserLocation] = useState(null);
  const [autoFocus, setAutoFocus] = useState(false);
  const [clusteringEnabled, setClusteringEnabled] = useState(true);
  const [labelsEnabled, setLabelsEnabled] = useState(true); // Botão de visualizar (FaTag)
  const [searchInput, setSearchInput] = useState('');
  const [error, setError] = useState(null);
  const [visibleMarkers, setVisibleMarkers] = useState({
    poste: true,
    dispositivo: true,
    userLocation: true,
  });
  const [isLoading, setIsLoading] = useState(false);

  // Novo estado para o zoom
  const [zoom, setZoom] = useState(13); // Zoom inicial

  // Novo estado para as bounds do mapa
  const [mapBounds, setMapBounds] = useState(null);

  // Refs para marcadores e clusters
  const postesMarkersRef = useRef([]);
  const dispositivosMarkersRef = useRef([]);
  const clusterPostesRef = useRef(null);
  const clusterDispositivosRef = useRef(null);

  /**
   * Função para verificar se uma posição está dentro das bounds atuais
   */
  const isPositionInBounds = useCallback((lat, lng) => {
    if (!mapBounds) return true; // Se bounds não estiver definido, renderizar todos
    return mapBounds.contains(new window.google.maps.LatLng(lat, lng));
  }, [mapBounds]);

  /**
   * Função para lidar com o clique em um marcador
   */
  const handleMarkerClick = useCallback(
    async (marker, type) => {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        console.error('Token de autenticação não encontrado');
        return;
      }

      setIsLoading(true);

      try {
        if (type === 'poste') {
          const responsePoste = await fetch(`https://api.ads10.com.br/api/poste/${marker.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
              'CidadeId': 3315,
            },
          });

          if (!responsePoste.ok) {
            throw new Error(`Erro ao buscar poste: ${responsePoste.status}`);
          }

          const posteData = await responsePoste.json();

          // Filtrar as associações para obter apenas as fotocélulas relacionadas ao poste atual
          const associatedFotocelulas = associacoes
            .filter((assoc) => assoc.posteId === marker.id && assoc.ativo === 'S')
            .map((assoc) =>
              fotocelulas.find((f) => f.fotocelulaId.toString() === assoc.fotocelulaId.toString())
            )
            .filter(Boolean); // Remove undefined

          // Filtrar apenas os dispositivos que são fotocélulas associadas ao poste atual
          const dispositivosAtivos = dispositivos.filter((d) =>
            associatedFotocelulas.some((f) => f && f.deviceEUI.toLowerCase() === d.deviceId.toLowerCase())
          );

          setSelectedMarkerData({ ...posteData, fotocelulas: dispositivosAtivos });
          setSelectedMarkerType(type);
          setIsModalOpen(true);
        } else if (type === 'dispositivo') {
          // Código para lidar com o tipo dispositivo
          const dispositivoData = marker;

          // Obter as associações para este dispositivo
          const associatedFotocelulaIds = fotocelulas
            .filter((f) => f.deviceEUI.toLowerCase() === dispositivoData.deviceId.toLowerCase())
            .map((f) => f.fotocelulaId.toString());

          const associatedPostes = associacoes
            .filter(
              (assoc) =>
                associatedFotocelulaIds.includes(assoc.fotocelulaId.toString()) &&
                assoc.ativo === 'S'
            )
            .map((assoc) => assoc.posteId.toString());

          const postesAtivos = postes.filter(
            (poste) => associatedPostes.includes(poste.id.toString()) && poste.ativo === 'S'
          );

          setSelectedMarkerData({ ...dispositivoData, postesAtivos });
          setSelectedMarkerType(type);
          setIsModalOpen(true);
        }
      } catch (error) {
        console.error('Erro ao carregar detalhes do marcador:', error);
        setError(error.message || 'Erro desconhecido');
      } finally {
        setIsLoading(false);
      }
    },
    [dispositivos, postes, associacoes, fotocelulas]
  );

  /**
   * Funções para buscar dados das APIs
   */
  const fetchDispositivos = async () => {
    try {
      const response = await fetch('http://191.252.196.95:3000/api/messages/latest/all', {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Erro ao buscar os dados dos dispositivos');
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error('Formato de dados inesperado ao buscar dispositivos');
      }

      setDispositivos(data);
    } catch (err) {
      console.error('Erro ao buscar dispositivos:', err);
      setError(err.message || 'Erro desconhecido');
    }
  };

  const fetchFotocelulas = async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/fotocelula', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'CidadeId': 3315,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          alert('Sessão expirada. Faça login novamente.');
          localStorage.removeItem('jwtToken');
          sessionStorage.removeItem('jwtToken');
          window.location.href = '/login';
          return;
        }
        throw new Error('Erro ao buscar os dados das fotocélulas');
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error('Formato de dados inesperado ao buscar fotocélulas');
      }

      setFotocelulas(data);
    } catch (err) {
      console.error('Erro ao buscar fotocélulas:', err);
      setError(err.message || 'Erro desconhecido');
    }
  };

  const fetchAssociacoes = async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/postefotocelula', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'CidadeId': 3315,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          alert('Sessão expirada. Faça login novamente.');
          localStorage.removeItem('jwtToken');
          sessionStorage.removeItem('jwtToken');
          window.location.href = '/login';
          return;
        }
        throw new Error('Erro ao buscar as associações de postes e fotocélulas');
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error('Formato de dados inesperado ao buscar associações');
      }

      // Definindo as associações no estado
      setAssociacoes(data);

      // Imprimindo todas as associações em um único log
      console.log('Lista de Associações:', data);
    } catch (err) {
      console.error('Erro ao buscar associações:', err);
      setError(err.message || 'Erro desconhecido');
    }
  };

  const fetchPostes = async () => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      const response = await fetch('https://api.ads10.com.br/api/poste', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'CidadeId': 3315,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          alert('Sessão expirada. Faça login novamente.');
          localStorage.removeItem('jwtToken');
          sessionStorage.removeItem('jwtToken');
          window.location.href = '/login';
          return;
        }
        throw new Error('Erro ao buscar os dados dos postes');
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error('Formato de dados inesperado ao buscar postes');
      }

      setPostes(data);
    } catch (err) {
      console.error('Erro ao buscar postes:', err);
      setError(err.message || 'Erro desconhecido');
    }
  };

  /**
   * useEffect para buscar dados ao montar o componente
   */
  useEffect(() => {
    fetchDispositivos();
    fetchFotocelulas();
    fetchAssociacoes();
    fetchPostes();
  }, []);

  /**
   * useEffect para rastrear a localização do usuário
   */
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('Erro ao obter localização do usuário:', error);
          if (error.code === error.PERMISSION_DENIED) {
            alert('Permissão de localização negada. Verifique as configurações do navegador.');
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            alert('A posição não está disponível. Por favor, tente novamente mais tarde.');
          } else if (error.code === error.TIMEOUT) {
            alert('Tempo para obter a localização excedido. Tente novamente.');
          } else {
            alert('Erro desconhecido ao obter a localização.');
          }
        },
        {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 5000,
        }
      );
      
    } else {
      alert('Geolocalização não é suportada neste navegador.');
    }
  }, []);

  /**
   * useEffect para atualizar os dispositivos associados
   */
  useEffect(() => {
    const newAttachedDeviceIds = new Set();

    associacoes
      .filter((assoc) => assoc.ativo === 'S')
      .forEach((assoc) => {
        const fotocelula = fotocelulas.find(
          (f) => f.fotocelulaId.toString() === assoc.fotocelulaId.toString()
        );
        if (fotocelula && fotocelula.deviceEUI) {
          newAttachedDeviceIds.add(fotocelula.deviceEUI.toLowerCase());
        }
      });

    setAttachedDeviceIds(newAttachedDeviceIds);
  }, [associacoes, fotocelulas]);

  /**
   * Função para carregar o mapa e definir os ícones
   */
  const onLoad = useCallback((map) => {
    mapRef.current = map;

    map.setOptions({ styles: lightMapStyles });

    // Definir o nível de zoom inicial
    setZoom(map.getZoom());

    // Listener para mudanças de zoom
    map.addListener('zoom_changed', () => {
      const currentZoom = map.getZoom();
      setZoom(currentZoom);
    });

    // Listener para mudanças de bounds
    map.addListener('bounds_changed', () => {
      const bounds = map.getBounds();
      setMapBounds(bounds);
    });

    // Funções para criar ícones
    const createPosteIcon = () => {
      const posteIconMarkup = renderToStaticMarkup(
        <GiDoubleStreetLights size={30} color="red" />
      );
      return {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(posteIconMarkup)}`,
        scaledSize: new window.google.maps.Size(30, 30),
        labelOrigin: new window.google.maps.Point(15, -10),
      };
    };

    const createDispositivoIcon = () => {
      const dispositivoIconMarkup = renderToStaticMarkup(
        <TiWiFiOutline size={30} color="blue" />
      );
      return {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(dispositivoIconMarkup)}`,
        scaledSize: new window.google.maps.Size(30, 30),
        labelOrigin: new window.google.maps.Point(15, -10),
      };
    };

    const createUserIcon = () => {
      const iconMarkup = renderToStaticMarkup(<MdPersonPin size={24} color="blue" />);
      return {
        url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(iconMarkup)}`,
        scaledSize: new window.google.maps.Size(24, 24),
        anchor: new window.google.maps.Point(12, 24),
      };
    };

    setIcons({
      posteIcon: createPosteIcon(),
      dispositivoIcon: createDispositivoIcon(),
      userIcon: createUserIcon(),
    });
  }, []);

  /**
   * useEffect para atualizar os marcadores de postes
   */
  useEffect(() => {
    if (mapRef.current && postes.length > 0 && icons.posteIcon) {
      // Remover marcadores existentes
      postesMarkersRef.current.forEach((marker) => marker.setMap(null));
      postesMarkersRef.current = [];

      // Criar novos marcadores filtrados pelas bounds
      const postesMarkers = postes
        .filter((poste) => poste.ativo === 'S')
        .filter((poste) => poste.lat && poste.lng)
        .filter((poste) => {
          const lat = parseFloat(poste.lat.trim());
          const lng = parseFloat(poste.lng.trim());
          return !isNaN(lat) && !isNaN(lng) && isPositionInBounds(lat, lng);
        })
        .map((poste) => {
          const lat = parseFloat(poste.lat.trim());
          const lng = parseFloat(poste.lng.trim());
          if (isNaN(lat) || isNaN(lng)) {
            console.warn(`Coordenadas inválidas para poste ID ${poste.id}`);
            return null;
          }
          const marker = new window.google.maps.Marker({
            position: { lat: lat, lng: lng },
            title: `ID do Poste: ${poste.id || poste.codigoPoste || poste.name}`,
            label: labelsEnabled && zoom > 18
              ? {
                  text: poste.name || poste.codigoPoste || poste.id.toString(),
                  color: 'black',
                  fontSize: '12px',
                }
              : null,
            icon: icons.posteIcon,
            visible: visibleMarkers.poste,
          });
          marker.addListener('click', () => handleMarkerClick(poste, 'poste'));
          return marker;
        })
        .filter((marker) => marker !== null);

      postesMarkersRef.current = postesMarkers;

      // Gerenciar clustering
      if (clusteringEnabled) {
        if (clusterPostesRef.current) {
          clusterPostesRef.current.clearMarkers();
        }
        clusterPostesRef.current = new MarkerClusterer({
          markers: postesMarkers,
          map: mapRef.current,
        });
      } else {
        postesMarkers.forEach((marker) => marker.setMap(mapRef.current));
      }
    }
  }, [
    postes,
    labelsEnabled,
    clusteringEnabled,
    visibleMarkers.poste,
    handleMarkerClick,
    icons.posteIcon,
    zoom, // Dependência para atualizar labels com zoom
    mapBounds, // Dependência para atualizar com bounds
    isPositionInBounds, // Adicionado
  ]);

  /**
   * useEffect para atualizar os marcadores de dispositivos
   */
  useEffect(() => {
    if (mapRef.current && dispositivos.length > 0 && icons.dispositivoIcon) {
      // Remover marcadores existentes
      dispositivosMarkersRef.current.forEach((marker) => marker.setMap(null));
      dispositivosMarkersRef.current = [];

      // Criar novos marcadores filtrados pelas bounds
      const dispositivosMarkers = dispositivos
        .filter((dispositivo) => dispositivo.deviceId)
        .filter((dispositivo) => dispositivo.Lat && dispositivo.Lng)
        .filter((dispositivo) => dispositivo.Lat !== 0 && dispositivo.Lng !== 0)
        .filter(
          (dispositivo) => !attachedDeviceIds.has(dispositivo.deviceId.toLowerCase())
        )
        .filter((dispositivo) => {
          const lat = parseFloat(dispositivo.Lat);
          const lng = parseFloat(dispositivo.Lng);
          return !isNaN(lat) && !isNaN(lng) && isPositionInBounds(lat, lng);
        })
        .map((dispositivo) => {
          const lat = parseFloat(dispositivo.Lat);
          const lng = parseFloat(dispositivo.Lng);
          if (isNaN(lat) || isNaN(lng)) {
            console.warn(
              `Coordenadas inválidas para dispositivo ID ${dispositivo.deviceId}`
            );
            return null;
          }
          const marker = new window.google.maps.Marker({
            position: { lat: lat, lng: lng },
            title: `Dispositivo ID: ${dispositivo.deviceId}`,
            label: labelsEnabled && zoom > 18
              ? {
                  text: dispositivo.deviceId,
                  color: 'blue',
                  fontSize: '12px',
                }
              : null,
            icon: icons.dispositivoIcon,
            visible: visibleMarkers.dispositivo,
          });
          marker.addListener('click', () => handleMarkerClick(dispositivo, 'dispositivo'));
          return marker;
        })
        .filter((marker) => marker !== null);

      dispositivosMarkersRef.current = dispositivosMarkers;

      // Gerenciar clustering
      if (clusteringEnabled) {
        if (clusterDispositivosRef.current) {
          clusterDispositivosRef.current.clearMarkers();
        }
        clusterDispositivosRef.current = new MarkerClusterer({
          markers: dispositivosMarkers,
          map: mapRef.current,
        });
      } else {
        dispositivosMarkers.forEach((marker) => marker.setMap(mapRef.current));
      }
    }
  }, [
    dispositivos,
    labelsEnabled,
    clusteringEnabled,
    visibleMarkers.dispositivo,
    attachedDeviceIds,
    handleMarkerClick,
    icons.dispositivoIcon,
    zoom, // Dependência para atualizar labels com zoom
    mapBounds, // Dependência para atualizar com bounds
    isPositionInBounds, // Adicionado
  ]);

  /**
   * Função para centralizar o mapa em um marcador específico
   */
  const focusOnMarker = (marker) => {
    if (mapRef.current) {
      const lat = parseFloat(marker.lat || marker.Lat || marker.latitude);
      const lng = parseFloat(marker.lng || marker.Lng || marker.longitude);
      if (isNaN(lat) || isNaN(lng)) {
        alert('Coordenadas inválidas para este marcador.');
        return;
      }
      const latLng = new window.google.maps.LatLng(lat, lng);
      mapRef.current.panTo(latLng);
      mapRef.current.setZoom(18);
    }
  };

  /**
   * Função para lidar com a busca
   */
  const handleSearch = () => {
    const targetMarker =
      postes.find(
        (poste) =>
          poste.id.toString() === searchInput ||
          poste.codigoPoste === searchInput ||
          poste.name === searchInput
      ) ||
      dispositivos.find((dispositivo) => dispositivo.deviceId === searchInput);
    if (targetMarker) {
      focusOnMarker(targetMarker);
    } else {
      alert('Poste ou Dispositivo não encontrado');
    }
  };

  /**
   * Função para fechar o modal
   */
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMarkerData(null);
    setSelectedMarkerType('');
    setError(null);
  };

  /**
   * Funções para alternar funcionalidades
   */
  const toggleAutoFocus = () => {
    setAutoFocus((prev) => !prev);
    if (mapRef.current && userLocation) {
      if (!autoFocus) {
        mapRef.current.panTo(userLocation);
      }
    }
  };

  const toggleClustering = () => {
    setClusteringEnabled((prev) => !prev);
  };

  const toggleLabels = () => {
    setLabelsEnabled((prev) => !prev);
  };

  /**
   * Função para atribuir poste a um dispositivo
   */
  const atribuirPoste = async (posteId, deviceId) => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      // Obter o fotocelulaId correspondente ao deviceId
      const fotocelula = fotocelulas.find(
        (f) => f.deviceEUI.toLowerCase() === deviceId.toLowerCase()
      );

      if (!fotocelula) {
        throw new Error('Fotocélula não encontrada para este dispositivo');
      }

      const response = await fetch('https://api.ads10.com.br/api/postefotocelula', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'CidadeId': 3315,
        },
        body: JSON.stringify({
          posteId,
          fotocelulaId: fotocelula.fotocelulaId, // Usando fotocelulaId
          ativo: 'S',
          ligado: 'S',
          manutencao: 'N',
          tensaoRede: '0',
        }),
      });

      if (!response.ok) {
        throw new Error('Erro ao atribuir o poste ao dispositivo');
      }

      await fetchAssociacoes();
      await fetchDispositivos();

      closeModal();
    } catch (err) {
      console.error('Erro ao atribuir poste:', err);
      setError(err.message || 'Erro desconhecido');
    }
  };

  /**
   * Função para remover dispositivo de um poste
   */
  const removerDispositivo = async (deviceId, posteId) => {
    try {
      const token = localStorage.getItem('jwtToken') || sessionStorage.getItem('jwtToken');
      if (!token) {
        throw new Error('Token de autenticação não encontrado');
      }

      // Obter o fotocelulaId correspondente ao deviceId
      const fotocelula = fotocelulas.find(
        (f) => f.deviceEUI.toLowerCase() === deviceId.toLowerCase()
      );

      if (!fotocelula) {
        throw new Error('Fotocélula não encontrada para este dispositivo');
      }

      const association = associacoes.find(
        (assoc) =>
          assoc.fotocelulaId.toString() === fotocelula.fotocelulaId.toString() &&
          assoc.posteId.toString() === posteId.toString() &&
          assoc.ativo === 'S'
      );

      if (!association) {
        throw new Error('Associação não encontrada');
      }

      const response = await fetch(
        `https://api.ads10.com.br/api/postefotocelula/${association.postefotocelulaId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
            'CidadeId': 3315,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Erro ao remover o dispositivo do poste');
      }

      await fetchAssociacoes();
      await fetchDispositivos();

      closeModal();
    } catch (err) {
      console.error('Erro ao remover dispositivo:', err);
      setError(err.message || 'Erro desconhecido');
    }
  };

  /**
   * Função para alternar visibilidade dos marcadores
   */
  const toggleMarkerVisibility = (markerType) => {
    setVisibleMarkers((prevVisibleMarkers) => ({
      ...prevVisibleMarkers,
      [markerType]: !prevVisibleMarkers[markerType],
    }));

    if (markerType === 'poste') {
      postesMarkersRef.current.forEach((marker) => marker.setVisible(!visibleMarkers.poste));
    } else if (markerType === 'dispositivo') {
      dispositivosMarkersRef.current.forEach((marker) =>
        marker.setVisible(!visibleMarkers.dispositivo)
      );
    }
    // O marcador de localização do usuário é gerenciado via JSX
  };

  /**
   * Contagem de marcadores para a legenda
   */
  const markerCounts = React.useMemo(
    () => ({
      poste: postes.filter((poste) => poste.ativo === 'S').length,
      dispositivo: dispositivos.filter(
        (dispositivo) =>
          dispositivo.Lat !== 0 &&
          dispositivo.Lng !== 0 &&
          !attachedDeviceIds.has(dispositivo.deviceId.toLowerCase())
      ).length,
      userLocation: userLocation ? 1 : 0,
    }),
    [postes, dispositivos, userLocation, attachedDeviceIds]
  );

  return (
    <>
      {/* Overlay de carregamento */}
      {isLoading && <LoadingOverlay />}

      {/* Controles do Mapa */}
      <div className={styles.controlsContainer}>
        {/* Botão de Auto Foco */}
        <button
          onClick={toggleAutoFocus}
          className={`${styles.controlButton} ${autoFocus ? styles.autoFocusActive : ''}`}
          aria-label={autoFocus ? 'Desativar Auto Foco' : 'Ativar Auto Foco'}
        >
          <FaCrosshairs size={24} color={autoFocus ? 'blue' : 'black'} />
        </button>

        {/* Botão de Clustering */}
        <button
          onClick={toggleClustering}
          className={styles.controlButton}
          aria-label={clusteringEnabled ? 'Desativar Clustering' : 'Ativar Clustering'}
        >
          <FaLayerGroup size={24} color={clusteringEnabled ? 'blue' : 'black'} />
        </button>

        {/* Botão de Visualizar Labels (Ícone de Etiqueta) */}
        <button
          onClick={toggleLabels}
          className={styles.controlButton}
          aria-label={labelsEnabled ? 'Desativar Labels' : 'Ativar Labels'}
        >
          <FaTag size={24} color={labelsEnabled ? 'blue' : 'black'} /> {/* Ícone de etiqueta */}
        </button>
      </div>

      {/* Barra de Busca */}
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Buscar Poste ou Dispositivo"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className={styles.searchInput}
        />
        <button onClick={handleSearch} className={styles.searchButton} aria-label="Buscar">
          <FaSearch size={18} className={styles.searchIcon} />
        </button>
      </div>

      {/* Legenda dos Marcadores */}
      <div className={styles.legendContainer}>
        <AtrelarLegend
          markerCounts={markerCounts}
          toggleMarkerVisibility={toggleMarkerVisibility}
          visibleMarkers={visibleMarkers}
        />
      </div>

      {/* Componente Google Map */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={MAP_CENTER}
        zoom={zoom} // Utiliza o estado de zoom
        mapTypeId="roadmap"
        onLoad={onLoad}
        options={{
          styles: lightMapStyles,
          disableDefaultUI: true,
          zoomControl: true,
        }}
      >
        {/* Marcador da Localização do Usuário */}
        {userLocation && visibleMarkers.userLocation && (
          <Marker
            position={userLocation}
            icon={icons.userIcon}
            title="Sua Localização"
            // Se precisar de referência, utilize onLoad
            // onLoad={(marker) => { /* lógica para referência, se necessário */ }}
          />
        )}
      </GoogleMap>

      {/* Modal de Detalhes do Marcador */}
      {isModalOpen && selectedMarkerData && (
        <AtrelarModal
          isOpen={isModalOpen}
          onClose={closeModal}
          markerData={selectedMarkerData}
          atribuirPoste={atribuirPoste}
          removerDispositivo={removerDispositivo}
          markerType={selectedMarkerType}
          error={error}
          setError={setError}
          postesDropdown={postes.filter((poste) => poste.ativo === 'S')}
        />
      )}
    </>
  );
};

export default AtrelarDispositivo;
