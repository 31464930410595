import React, { useState, useEffect } from 'react';
import { FaExclamationCircle, FaWhatsapp, FaTimes, FaBell } from 'react-icons/fa';
import styles from './MicroAlertWindow.module.css';

const MicroAlertWindow = ({ alerts, onAlertClick, onCloseAlert }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dismissedAlerts, setDismissedAlerts] = useState(() => {
    const storedDismissedAlerts = localStorage.getItem('dismissedAlerts');
    return storedDismissedAlerts ? JSON.parse(storedDismissedAlerts) : [];
  });

  useEffect(() => {
    localStorage.setItem('dismissedAlerts', JSON.stringify(dismissedAlerts));
  }, [dismissedAlerts]);

  // Agrupar alertas por tipo e posteId
  const uniqueAlerts = alerts.reduce((acc, alert) => {
    const key = `${alert.alertType}-${alert.posteId}`;
    if (!acc.some((a) => a.key === key)) {
      acc.push({ ...alert, key });
    }
    return acc;
  }, []);

  // Filtrar alertas visíveis e não descartados
  const visibleAlerts = uniqueAlerts.filter((alert) => !dismissedAlerts.includes(alert.id));
  const alertCount = visibleAlerts.length;

  const toggleWindow = () => {
    setIsOpen((prev) => !prev);
  };

  const dismissAlert = (alertId) => {
    setDismissedAlerts((prev) => [...prev, alertId]);
    onCloseAlert(alertId);
  };

  const clearAllAlerts = () => {
    const alertIds = visibleAlerts.map((alert) => alert.id);
    setDismissedAlerts((prev) => [...prev, ...alertIds]);
    alertIds.forEach(onCloseAlert);
  };

  const generateWhatsAppLink = (posteId) => {
    const formattedPosteId = `CEA${posteId.toString().padStart(9, '0')}`;
    const message = `@vialuz ceasa ${formattedPosteId}`;
    const phoneNumber = '+5511913454578';
    return `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  };

  // Função para formatar a data e hora
  const formatDateTime = (dateTime) => {
    if (!dateTime) return '';
    const date = new Date(dateTime);
    if (isNaN(date.getTime())) return '';
    return date.toLocaleString('pt-BR'); // Formato de data e hora brasileiro
  };

  return (
    <div className={styles.container}>
      <button
        className={`${styles.minimizedButton} ${alertCount > 0 ? styles.activeBell : styles.inactiveBell}`}
        onClick={toggleWindow}
      >
        <FaBell size={24} />
        {alertCount > 0 && <span className={styles.badge}>{alertCount}</span>}
      </button>

      <div className={`${styles.alertWindow} ${isOpen ? styles.open : styles.closed}`}>
        <div className={styles.alertHeader}>
          <h3 className={styles.alertTitle}>Alertas</h3>
          <button className={styles.minimizeButton} onClick={toggleWindow} title="Minimizar">
            <FaTimes />
          </button>
        </div>

        {visibleAlerts.length > 0 ? (
          visibleAlerts.map((alert) => (
            <div 
              key={alert.id} 
              className={styles.alertItem}
              onClick={() => onAlertClick(alert)}
            >
              <div className={styles.alertContent}>
                <FaExclamationCircle className={styles.alertIcon} />
                <div className={styles.alertText}>
                  <div>
                    {alert.alertType} - Poste: {alert.posteId}
                  </div>
                  <div className={styles.alertTimestamp}>
                    Última leitura: {formatDateTime(alert.lastReadingDateTime)}
                  </div>
                </div>
              </div>
              <div className={styles.alertActions}>
                <a 
                  href={generateWhatsAppLink(alert.posteId)} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className={styles.whatsappIcon}
                  title="Registrar Alerta via WhatsApp"
                  onClick={(e) => {
                    e.stopPropagation();
                    dismissAlert(alert.id);
                  }}
                >
                  <FaWhatsapp />
                </a>
                <button 
                  className={styles.closeButton} 
                  onClick={(e) => {
                    e.stopPropagation();
                    dismissAlert(alert.id);
                  }} 
                  title="Fechar Alerta"
                >
                  <FaTimes />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noAlerts}>Nenhum alerta disponível</div>
        )}

        {/* Footer with Clear All button */}
        <footer className={styles.alertFooter}>
          <button className={styles.clearAllButton} onClick={clearAllAlerts}>
            Limpar Todos
          </button>
        </footer>
      </div>
    </div>
  );
};

export default MicroAlertWindow;
