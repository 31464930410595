import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar os componentes.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de componentes, estado de carregamento, erro e função para refetch.
 */
const useComponentes = (isOpen) => {
  const { token, logout } = useContext(AuthContext); // Obtém token e logout do contexto
  const [componentes, setComponentes] = useState([]);
  const [isComponentesLoading, setIsComponentesLoading] = useState(false);
  const [componentesError, setComponentesError] = useState(null);

  const fetchComponentes = useCallback(async () => {
    if (!isOpen) return;

    setIsComponentesLoading(true);
    setComponentesError(null);
    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch('https://api.ads10.com.br/api/produto', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar componentes: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Componentes recebidos da API:', data);
      setComponentes(data);
    } catch (error) {
      console.error('Erro ao buscar componentes:', error);
      setComponentesError(error.message.includes('Token')
        ? 'Você precisa estar autenticado para carregar a lista de componentes.'
        : 'Falha ao carregar a lista de componentes. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(setComponentesError);
    } finally {
      setIsComponentesLoading(false);
    }
  }, [isOpen, token, logout]);

  useEffect(() => {
    fetchComponentes();
  }, [fetchComponentes]);

  return { componentes, isComponentesLoading, componentesError, refetch: fetchComponentes };
};

export default useComponentes;
