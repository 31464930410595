// src/pages/Relatorios/RelatorioConsumoPorPoste.jsx

import React, { useState, useEffect } from 'react';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';

const RelatorioConsumoPorPoste = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [consumoPorPoste, setConsumoPorPoste] = useState([]);

  useEffect(() => {
    // Extrair os consumos de cada poste e converter os valores para números
    const consumos = postesDetalhados
      .filter((poste) => poste.consumo !== null)
      .map((poste) => ({
        numeroPoste: poste.codigoPoste || 'N/A',
        consumo: parseFloat(poste.consumo) || 0, // Converter para número e tratar valores inválidos
      }));

    setConsumoPorPoste(consumos);
  }, [postesDetalhados]);

  const totalConsumo = consumoPorPoste.reduce((total, poste) => total + poste.consumo, 0);
  const mediaConsumo = consumoPorPoste.length > 0 ? (totalConsumo / consumoPorPoste.length).toFixed(2) : 0;

  const handleExportToExcel = () => {
    const exportData = consumoPorPoste.map((poste) => ({
      'Número do Poste': poste.numeroPoste,
      'Consumo (kWh)': poste.consumo.toFixed(2),
    }));

    exportData.push({ 'Número do Poste': 'Total', 'Consumo (kWh)': totalConsumo.toFixed(2) });
    exportData.push({ 'Número do Poste': 'Consumo Médio Por Poste', 'Consumo (kWh)': mediaConsumo });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Consumo por Poste');
    XLSX.writeFile(workbook, 'consumo_por_poste.xlsx');
  };

  return (
    <div className={styles.container}>
      <h2>Relatório de Consumo por Poste</h2>
      {isLoading && <p>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {!isLoading && consumoPorPoste.length > 0 && (
        <>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            Exportar para Excel
          </button>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Número do Poste</th>
                  <th>Consumo (kWh)</th>
                </tr>
              </thead>
              <tbody>
                {consumoPorPoste.map((poste, index) => (
                  <tr key={index}>
                    <td>{poste.numeroPoste}</td>
                    <td>{poste.consumo.toFixed(2)}</td>
                  </tr>
                ))}
                <tr>
                  <td><strong>Total</strong></td>
                  <td><strong>{totalConsumo.toFixed(2)}</strong></td>
                </tr>
                <tr>
                  <td><strong>Consumo Médio Por Poste</strong></td>
                  <td><strong>{mediaConsumo}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && consumoPorPoste.length === 0 && (
        <p>Nenhum dado de consumo disponível.</p>
      )}
    </div>
  );
};

export default RelatorioConsumoPorPoste;
