import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de estados.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de estados, estado de carregamento, erro e função para refetch.
 */
const useStates = (isOpen) => {
  const { token, logout } = useContext(AuthContext); // Obtendo token e logout do AuthContext
  const [states, setStates] = useState([]);
  const [isStatesLoading, setIsStatesLoading] = useState(false);
  const [statesError, setStatesError] = useState(null);

  /**
   * Função para buscar os estados da API.
   */
  const fetchStates = useCallback(async () => {
    if (!isOpen) return; // Não buscar se o modal não estiver aberto

    setIsStatesLoading(true);
    setStatesError(null);

    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch('https://api.ads10.com.br/api/estado', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar estados: ${response.status} ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Estados recebidos da API:', data);
      setStates(data);
    } catch (error) {
      console.error('Erro ao buscar estados:', error);
      setStatesError('Falha ao carregar a lista de estados. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error(`Erro ao buscar estados: ${error.message}`);
    } finally {
      setIsStatesLoading(false);
    }
  }, [isOpen, token, logout]);

  useEffect(() => {
    fetchStates();
  }, [fetchStates]);

  return { states, isStatesLoading, statesError, refetch: fetchStates };
};

export default useStates;
