// src/pages/Mapa/Mapa.jsx

import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import styles from './Mapa.module.css';
import Legend from './Legend';
import CompanyLogos from './Logos';
import MarkerModal from '../../components/MarkerModal/MarkerModal';
import SearchBar from '../../components/SearchBar/SearchBar';
import { getMapCenterByCityId } from '../../config';
import { darkMapStyles, lightMapStyles } from '../../config/mapStyles';
import acesaIcon from '../../assets/green-icon.png';
import apagadaIcon from '../../assets/blue-icon.png';
import alertaIcon from '../../assets/orange-icon.png';
import subtensaoIcon from '../../assets/subtensao-icon.png';
import sobretensaoIcon from '../../assets/sobretensao-icon.png';
import LoadingOverlay from '../../components/LoadingOverlay/LoadingOverlay';
import MicroAlertWindow from '../../components/MicroAlertWindow/MicroAlertWindow';
import { AuthContext } from '../../context/AuthContext';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import semFotocelulaIcon from '../../assets/grey-icon.png';
import { IoMdWifi } from 'react-icons/io';
import useDevicesSemPoste from '../../hooks/useDevicesSemPoste';
import { renderToStaticMarkup } from 'react-dom/server';
import AtrelarModal from './AtrelarModal';
import { toast } from 'react-toastify';

const containerStyle = {
  width: '100%',
  height: '100vh',
};

const MIN_ZOOM_FOR_LABELS = 20;

const Mapa = () => {
  const { token, cidadeId, usuarioId, logout } = useContext(AuthContext);

  // Estados
  const [alerts, setAlerts] = useState([]);
  const [markerCounts, setMarkerCounts] = useState({
    acesa: 0,
    apagada: 0,
    semFotocelula: 0,
    alerta: 0,
    subTensao: 0,
    sobreTensao: 0,
    semPoste: 0, // Nome atualizado
  });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleMarkers, setVisibleMarkers] = useState({
    acesa: true,
    apagada: true,
    semFotocelula: true,
    alerta: true,
    subTensao: true,
    sobreTensao: true,
    semPoste: true, // Nome atualizado
  });
  const [searchInput, setSearchInput] = useState('');
  const [timeLeft, setTimeLeft] = useState(180);
  const [highlightedMarker, setHighlightedMarker] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mapType, setMapType] = useState('roadmap');
  const [tilt, setTilt] = useState(0);
  const [zoom, setZoom] = useState(17);
  const [showPostIds, setShowPostIds] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [allMarkers, setAllMarkers] = useState([]);

  // Hooks personalizados
  const { devices: fotocelulasSemPoste, isLoading: isDevicesLoading } = useDevicesSemPoste();
  const mapRef = useRef(null);

  const { postesDetalhados, isLoading: isDataLoading, refetch } = usePosteDataComAlertas(true);
  const [isAtrelarModalOpen, setIsAtrelarModalOpen] = useState(false);
  const [selectedDeviceData, setSelectedDeviceData] = useState(null);
  const [error, setError] = useState(null); // Para exibir mensagens de erro no modal

  const markerZIndex = {
    alerta: 6,
    sobreTensao: 5,
    subTensao: 4,
    acesa: 3,
    apagada: 2,
    semFotocelula: 1,
    userLocation: 7,
    semPoste: 0,
  };

  // Função para abrir o modal de atribuição
  const openAtrelarModal = (deviceData) => {
    setSelectedDeviceData(deviceData);
    setIsAtrelarModalOpen(true);
  };

  // Função para fechar o modal de atribuição
  const closeAtrelarModal = () => {
    setIsAtrelarModalOpen(false);
    setSelectedDeviceData(null);
    setError(null);
  };

  /**
   * Função para atribuir um dispositivo a um poste
   * @param {number|string} posteId - ID do poste selecionado
   * @param {string} fotocelulaId - ID da fotocélula a ser associada
   */
  const atribuirPoste = async (posteId, fotocelulaId) => {
    setIsLoading(true);
    console.log('atribuirPoste chamado com posteId:', posteId, 'fotocelulaId:', fotocelulaId);
    try {
      const payload = {
        posteId: posteId,
        fotocelulaId: fotocelulaId,
        usuarioId: usuarioId, // ID do usuário obtido do contexto
        data: new Date().toISOString(),
        ativo: 'S', // Indica que está ativo
        ligado: 'N', // Estado inicial: desligado
        manutencao: 'N', // Indica que não está em manutenção
        tensaoRede: '0', // Tensão inicial
      };

      const response = await fetch(`https://api.ads10.com.br/api/postefotocelula`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`, // Apenas Authorization aqui
        },
        body: JSON.stringify(payload),
      });

      console.log('atribuirPoste - Status da resposta:', response.status);

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout();
        return;
      }

      if (!response.ok) {
        let errorMessage = 'Erro ao atribuir o dispositivo ao poste.';
        try {
          const errorData = await response.json();
          console.log('atribuirPoste - Dados de erro:', errorData);
          errorMessage = errorData.message || errorMessage;
        } catch (e) {
          const errorText = await response.text();
          console.log('atribuirPoste - Texto de erro:', errorText);
          errorMessage = errorText || errorMessage;
        }
        throw new Error(errorMessage);
      }

      toast.success('Dispositivo atribuído ao poste com sucesso!');
      refetch(); // Recarregar os dados para refletir a mudança
      closeAtrelarModal();
    } catch (error) {
      console.error('atribuirPoste - Erro:', error);
      toast.error(error.message || 'Ocorreu um erro ao atribuir o dispositivo ao poste.');
      setError(error.message || 'Erro ao atribuir o dispositivo ao poste.');
    } finally {
      setIsLoading(false);
    }
  };

  // Função para criar o ícone Wi-Fi como marcador para dispositivos sem poste
  const createWifiIcon = () => {
    const wifiIconMarkup = renderToStaticMarkup(<IoMdWifi size={20} color="#007BFF" />);
    return {
      url: `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(wifiIconMarkup)}`,
      scaledSize: new window.google.maps.Size(20, 20), // Reduz o tamanho para 20x20
      labelOrigin: new window.google.maps.Point(10, -5),
    };
  };

  // Função para determinar o tipo de marcador
  const getMarkerType = (marker) => {
    const tensao = parseFloat(marker.tensaoRede);

    // Prioridade 1: Verificar se há alerta em aberto
    if (marker.hasOpenAlert) {
      return 'alerta';
    }

    // Prioridade 2: Verificar se é um poste sem fotocélula
    if (!Array.isArray(marker.fotocelulas) || marker.fotocelulas.length === 0) {
      return 'semFotocelula';
    }

    // Prioridade 3: Verificar por subtensão e sobretensão
    if (tensao && tensao < 220 * 0.9) {
      return 'subTensao';
    } else if (tensao && tensao > 220 * 1.1) {
      return 'sobreTensao';
    }

    // Prioridade 4: Verificar o status do poste diretamente
    const statusNormalized = marker.status.trim().toUpperCase();
    if (statusNormalized === '(LIGADO)') {
      return 'acesa';
    } else if (statusNormalized === '(DESLIGADO)') {
      return 'apagada';
    } else {
      return 'apagada'; // padrão
    }
  };

  // Função para obter o ícone do marcador
  const getMarkerIcon = (marker) => {
    const type = getMarkerType(marker);

    const iconUrl = (() => {
      switch (type) {
        case 'semFotocelula':
          return semFotocelulaIcon;
        case 'subTensao':
          return subtensaoIcon;
        case 'sobreTensao':
          return sobretensaoIcon;
        case 'alerta':
          return alertaIcon;
        case 'acesa':
          return acesaIcon;
        case 'apagada':
          return apagadaIcon;
        default:
          return apagadaIcon;
      }
    })();

    return {
      url: iconUrl,
      scaledSize: getMarkerSizeByZoom(zoom),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(16, 16),
      labelOrigin: new window.google.maps.Point(11, -10),
    };
  };

  // Função para calcular o tamanho do marcador baseado no zoom
  const getMarkerSizeByZoom = (zoomLevel) => {
    const baseSize = 30;
    const maxZoom = 22;
    const minZoom = 10;
    const scaleFactor = Math.min(
      Math.max((zoomLevel - minZoom) / (maxZoom - minZoom), 0.5),
      1.5
    );
    return new window.google.maps.Size(baseSize * scaleFactor, baseSize * scaleFactor);
  };

  // Função para ordenar os marcadores
  const getOrderedMarkers = () => {
    return [...filteredMarkers].sort((a, b) => {
      const zIndexA = markerZIndex[getMarkerType(a)] || 0;
      const zIndexB = markerZIndex[getMarkerType(b)] || 0;
      return zIndexA - zIndexB;
    });
  };

  // Função memoizada para processar os marcadores e alertas
  const processMarkersAndAlerts = useCallback(() => {
    setIsLoading(true);

    // Inicializa as contagens para todos os tipos de marcadores
    const counts = {
      acesa: 0,
      apagada: 0,
      semFotocelula: 0,
      alerta: 0,
      subTensao: 0,
      sobreTensao: 0,
      fotocelulasAtivas: 0,
      semPoste: 0, // Adicionando `semPoste` para dispositivos sem poste
    };

    const openAlertSituations = ['1', '2'];
    const microAlertsTemp = [];
    const allMarkersTemp = [];

    // Sets para garantir que cada poste seja contado apenas uma vez
    const postsWithSubtensao = new Set();
    const postsWithSobretensao = new Set();

    // Itera sobre `postesDetalhados` para processar os postes e suas fotocélulas
    postesDetalhados.forEach((poste) => {
      if (!poste.geoLocalizacao || !poste.geoLocalizacao.includes(',')) {
        console.warn(`Poste com ID ${poste.id} não possui geoLocalizacao válida.`);
        return;
      }

      const [lat, lng] = poste.geoLocalizacao.split(',').map((coord) => parseFloat(coord.trim()));
      const markerAlerts = poste.alertas || [];
      const hasOpenAlert = markerAlerts.some((alerta) =>
        openAlertSituations.includes(alerta.alertaSituacao)
      );

      const fotocelulas = Array.isArray(poste.fotocelulas) ? poste.fotocelulas : [];
      let fotocelulasAtivas = 0;

      fotocelulas.forEach((fotocelula) => {
        const statusFotocelula = fotocelula.ligado;
        const dateString = fotocelula.dataUltimaLeitura;
        let leituraHour = null;
        let leituraTimestamp = null;

        if (dateString && dateString !== '0000-00-00 00:00:00') {
          const isoDateString = dateString.replace(' ', 'T');
          const date = new Date(isoDateString);
          if (!isNaN(date.getTime())) {
            leituraTimestamp = date;
            leituraHour = date.getHours();
          }
        }

        // Processa subtensão e sobretensão
        const tensao = parseFloat(fotocelula.tensaoRede);
        if (tensao && tensao < 220 * 0.9) {
          // Adiciona o poste ao Set de Subtensão
          postsWithSubtensao.add(poste.codigoPoste || poste.id);
          microAlertsTemp.push({
            id: `${poste.id}-${fotocelula.fotocelulaId}-subtensao`,
            alertType: 'Subtensão detectada',
            posteId: poste.id,
            fotocelulaId: fotocelula.fotocelulaId,
            lastReadingDateTime: leituraTimestamp,
          });
        }

        if (tensao && tensao > 220 * 1.1) {
          // Adiciona o poste ao Set de Sobretensão
          postsWithSobretensao.add(poste.codigoPoste || poste.id);
          microAlertsTemp.push({
            id: `${poste.id}-${fotocelula.fotocelulaId}-sobretensao`,
            alertType: 'Sobretensão detectada',
            posteId: poste.id,
            fotocelulaId: fotocelula.fotocelulaId,
            lastReadingDateTime: leituraTimestamp,
          });
        }

        // Processamento de alertas de horário
        if (statusFotocelula === 'L' && leituraHour !== null && leituraHour >= 6 && leituraHour < 18) {
          microAlertsTemp.push({
            id: `${poste.id}-${fotocelula.fotocelulaId}-ligado-dia`,
            alertType: 'Fotocélula ligada durante o dia',
            posteId: poste.id,
            fotocelulaId: fotocelula.fotocelulaId,
            lastReadingDateTime: leituraTimestamp,
          });
        }

        if (statusFotocelula !== 'L' && leituraHour !== null && (leituraHour >= 18 || leituraHour < 6)) {
          microAlertsTemp.push({
            id: `${poste.id}-${fotocelula.fotocelulaId}-desligado-noite`,
            alertType: 'Fotocélula desligada durante a noite',
            posteId: poste.id,
            fotocelulaId: fotocelula.fotocelulaId,
            lastReadingDateTime: leituraTimestamp,
          });
        }

        // Contagem de fotocélulas ativas
        if (statusFotocelula === 'L') {
          fotocelulasAtivas++;
        }
      });

      counts.fotocelulasAtivas += fotocelulasAtivas;

      // Determina o status do poste com base nas fotocélulas
      const statusNormalized = (() => {
        if (fotocelulasAtivas > 0) {
          return '(LIGADO)';
        } else if (fotocelulas.length > 0) {
          return '(DESLIGADO)';
        } else {
          return '(DESCONHECIDO)';
        }
      })();

      const marker = {
        idPoste: poste.id,
        codigoPoste: poste.codigoPoste,
        name: poste.codigoPoste,
        lat,
        lng,
        status: statusNormalized,
        tensaoRede: fotocelulas[0]?.tensaoRede || '0',
        tipo: poste.tipoPoste,
        fotocelulas: fotocelulas,
        fotocelulasCount: fotocelulas.length,
        hasOpenAlert: hasOpenAlert,
        fotocelulasAtivas: fotocelulas.filter((f) => f.ligado === 'L'),
        posteData: poste,
        alerts: markerAlerts,
        consumo: poste.consumo || 'Não disponível',
        potencia: poste.potencia && poste.potencia.trim() !== '' ? poste.potencia : 'Não disponível',
      };

      // Adiciona o marcador à lista de todos os marcadores
      allMarkersTemp.push(marker);

      // Incrementa a contagem com base no tipo do marcador
      const type = getMarkerType(marker);
      if (counts.hasOwnProperty(type)) {
        counts[type]++;
      }
    });

    // Atualiza a contagem de `semPoste` com base em `fotocelulasSemPoste`
    const semPosteCount = fotocelulasSemPoste?.filter(
      (device) => device.latitude !== '0' && device.longitude !== '0'
    ).length || 0;

    // Define o estado `markerCounts`, incluindo `semPoste`
    setMarkerCounts({
      ...counts,
      subTensao: postsWithSubtensao.size,       // Atualizado para contar postes únicos
      sobreTensao: postsWithSobretensao.size,   // Atualizado para contar postes únicos
      semPoste: semPosteCount,
    });

    // Atualiza os alertas e a lista de todos os marcadores
    setAlerts(microAlertsTemp);
    setAllMarkers(allMarkersTemp);

    // Filtra os marcadores para renderização com base em `mapBounds`
    const filtered = allMarkersTemp.filter((marker) => {
      if (!mapBounds) return true;
      const position = new window.google.maps.LatLng(marker.lat, marker.lng);
      return mapBounds.contains(position);
    });

    setFilteredMarkers(filtered);
    setIsLoading(false);

    // **Logs de Depuração: Postes com Subtensão e Sobretensão**
    console.log('=== Postes com Subtensão ===');
    if (postsWithSubtensao.size > 0) {
      console.table(Array.from(postsWithSubtensao));
    } else {
      console.log('Nenhum poste com Subtensão detectado.');
    }

    console.log('=== Postes com Sobretensão ===');
    if (postsWithSobretensao.size > 0) {
      console.table(Array.from(postsWithSobretensao));
    } else {
      console.log('Nenhum poste com Sobretensão detectado.');
    }

    // **Logs de Depuração das Contagens Atualizadas**
    console.log('Contagens Atualizadas:', {
      ...counts,
      subTensao: postsWithSubtensao.size,
      sobreTensao: postsWithSobretensao.size,
      semPoste: semPosteCount,
    });
  }, [postesDetalhados, fotocelulasSemPoste, mapBounds]);

  useEffect(() => {
    // Chama processMarkersAndAlerts somente quando:
    // - Postes foram carregados
    // - Dispositivos sem poste foram carregados
    if (postesDetalhados.length > 0 && !isDevicesLoading) {
      processMarkersAndAlerts();
    }
  }, [postesDetalhados, isDevicesLoading, mapBounds, processMarkersAndAlerts]);

  // Atualiza 'timeLeft' a cada segundo
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  // Recarrega os dados a cada 3 minutos
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
      setTimeLeft(180);
    }, 180000);

    return () => {
      clearInterval(intervalId);
    };
  }, [refetch]);

  // Handle zoom changes
  const handleZoomChanged = () => {
    if (mapRef.current) {
      const newZoom = mapRef.current.getZoom();
      setZoom(newZoom);
    }
  };

  // Função para capturar os bounds visíveis do mapa
  const handleOnIdle = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      setMapBounds(bounds);
    }
  };

  const handleMapLoad = (map) => {
    mapRef.current = map;
    map.addListener('zoom_changed', handleZoomChanged);
  };

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
    setIsModalOpen(true);
  };

  // Função para centralizar o mapa no marcador e tocar a animação
  const focusOnMarker = (markerId) => {
    const targetMarker = allMarkers.find(
      (marker) =>
        marker.idPoste.toString() === markerId.toString() ||
        marker.codigoPoste.toString().toLowerCase() === markerId.toLowerCase()
    );

    if (targetMarker && targetMarker.lat && targetMarker.lng && mapRef.current) {
      const latLng = new window.google.maps.LatLng(targetMarker.lat, targetMarker.lng);
      mapRef.current.panTo(latLng);
      mapRef.current.setZoom(18);
      highlightMarker(targetMarker.idPoste);
    } else {
      toast.warn('Poste não encontrado ou localização indisponível.');
    }
  };

  const handleAlertClick = (alert) => {
    focusOnMarker(alert.posteId);
  };

  // Função para remover o alerta da lista
  const handleCloseAlert = (alertId) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== alertId));
  };

  const highlightMarker = (markerId) => {
    setHighlightedMarker(markerId);
    setTimeout(() => setHighlightedMarker(null), 2000);
  };

  const shouldShowMarker = (marker) => {
    const type = getMarkerType(marker);
    return visibleMarkers[type];
  };

  const toggleMarkerVisibility = (type) => {
    setVisibleMarkers((prev) => ({ ...prev, [type]: !prev[type] }));
  };

  const handleSearch = () => {
    const cleanedSearchInput = searchInput.replace(/[^0-9A-Za-z]/g, '');
    focusOnMarker(cleanedSearchInput);
  };

  // Função para alternar a visibilidade das labels
  const toggleLabels = () => {
    setShowPostIds((prevShowPostIds) => !prevShowPostIds);
  };

  // Função para focar na localização do usuário
  const focusOnUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLoc = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setUserLocation(userLoc);
          if (mapRef.current) {
            mapRef.current.panTo(userLoc);
            mapRef.current.setZoom(18);
          }
          setLocationError(null);
        },
        (error) => {
          console.error('Erro ao obter localização do usuário:', error);
          setLocationError('Não foi possível obter sua localização.');
          toast.error('Não foi possível obter sua localização.');
        }
      );
    } else {
      toast.error('Geolocalização não é suportada pelo seu navegador.');
      setLocationError('Geolocalização não é suportada pelo seu navegador.');
    }
  };

  // Função para alternar tipo de mapa
  const toggleSatelliteView = () => {
    setMapType((prevMapType) => (prevMapType === 'roadmap' ? 'satellite' : 'roadmap'));
  };

  // Função para alternar inclinação
  const toggleTilt = () => {
    setTilt((prevTilt) => {
      const newTilt = prevTilt === 0 ? 45 : 0;
      if (mapRef.current) {
        mapRef.current.setTilt(newTilt);
      }
      return newTilt;
    });
  };

  return (
    <div className={styles.mapPage}>
      {/* Exibe o overlay de carregamento se qualquer estado de carregamento estiver ativo */}
      {(isDevicesLoading || isLoading || isDataLoading) && <LoadingOverlay />}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={getMapCenterByCityId(cidadeId)}
        zoom={zoom}
        mapTypeId={mapType}
        tilt={tilt}
        options={{
          styles: mapType === 'roadmap' ? lightMapStyles : darkMapStyles,
          disableDefaultUI: true,
          zoomControl: true,
        }}
        onLoad={handleMapLoad}
        onIdle={handleOnIdle}
      >
        {/* Renderiza os marcadores para dispositivos sem poste */}
        {fotocelulasSemPoste
          .filter((device) => device.latitude !== '0' && device.longitude !== '0')
          .map((device) =>
            visibleMarkers.semPoste ? (
              <Marker
                key={`fotocelula-sem-poste-${device.fotocelulaId}`}
                position={{ lat: parseFloat(device.latitude), lng: parseFloat(device.longitude) }}
                icon={createWifiIcon()}
                title={`Dispositivo sem Poste: ${device.descricao}`}
                onClick={() =>
                  openAtrelarModal({
                    fotocelulaId: device.fotocelulaId,
                    deviceEUI: device.deviceEUI,
                    descricao: device.descricao,
                    latitude: device.latitude,
                    longitude: device.longitude,
                    ultimaAtualizacao: device.deviceAtualizacao || 'Não disponível',
                  })
                }
              />
            ) : null
          )}

        {/* Renderiza os outros marcadores e funcionalidades do mapa */}
        {getOrderedMarkers().map(
          (marker) =>
            shouldShowMarker(marker) && (
              <Marker
                key={`${marker.idPoste}-${marker.codigoPoste}`}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={getMarkerIcon(marker)}
                zIndex={markerZIndex[getMarkerType(marker)]}
                animation={
                  highlightedMarker === marker.idPoste
                    ? window.google.maps.Animation.BOUNCE
                    : null
                }
                onClick={() => handleMarkerClick(marker)}
                title={`Código do Poste: ${marker.codigoPoste}`}
                label={
                  showPostIds && zoom >= MIN_ZOOM_FOR_LABELS
                    ? {
                        text:
                          marker.codigoPoste ||
                          marker.idPoste.toString().padStart(9, '0'),
                        color: 'black',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }
                    : undefined
                }
              />
            )
        )}

        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
              path:
                'M256 0C156.698 0 76 80.698 76 180c0 43.34 15.458 83.02 41.118 114.346L256 512l138.882-217.654C420.542 263.02 436 223.34 436 180 436 80.698 355.302 0 256 0z',
              fillColor: '#4285F4',
              fillOpacity: 1,
              strokeColor: '#FFFFFF',
              strokeWeight: 2,
              scale: 0.07,
              anchor: new window.google.maps.Point(256, 512),
            }}
            zIndex={markerZIndex['userLocation']}
            title="Minha Localização"
          />
        )}
      </GoogleMap>

      {/* Legend */}
      <Legend
        markerCounts={markerCounts}
        toggleMarkerVisibility={toggleMarkerVisibility}
        visibleMarkers={visibleMarkers}
      />

      {/* Company Logos */}
      <CompanyLogos />

      {/* Barra de Ferramentas */}
      <SearchBar
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        handleSearch={handleSearch}
        toggleLabels={toggleLabels}
        toggleSatelliteView={toggleSatelliteView}
        focusOnUserLocation={focusOnUserLocation}
        toggleTilt={toggleTilt}
        labelsVisible={showPostIds}
      />

      {/* Modal de Detalhes do Marcador */}
      {selectedMarker && (
        <MarkerModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          markerData={selectedMarker}
          cidadeId={cidadeId}
        />
      )}

      {/* MicroAlertWindow */}
      <MicroAlertWindow
        alerts={alerts}
        onAlertClick={handleAlertClick}
        onCloseAlert={handleCloseAlert}
      />

      {/* Barra de Carregamento de Atualização */}
      <div className={styles.loadingBarContainer}>
        <div
          className={styles.loadingBar}
          style={{ width: `${(180 - timeLeft) / 1.8}%` }}
        ></div>
        <div className={styles.loadingText}>
          Atualização em {Math.floor(timeLeft / 60)}:
          {(timeLeft % 60).toString().padStart(2, '0')}
        </div>
      </div>

      {/* Erro de Localização */}
      {locationError && <div className={styles.locationError}>{locationError}</div>}

      {/* Modal de Associação */}
      <AtrelarModal
        isOpen={isAtrelarModalOpen}
        onClose={closeAtrelarModal}
        markerData={selectedDeviceData}
        atribuirPoste={atribuirPoste}
        postesDropdown={postesDetalhados}
        markerType="dispositivo"
        error={error}
        setError={setError}
      />
    </div>
  );
};

export default Mapa;
