// src/pages/Postes/SubComponents/ConfiguracaoFisica.jsx

import React from 'react';
import {
  FaWrench,
  FaRuler,
  FaCogs,
  FaRulerVertical,
  FaSyncAlt,
} from 'react-icons/fa';
import styles from '../PosteModal.module.css';

/**
 * Componente ConfiguracaoFisica
 * @param {object} props - Props do componente
 * @param {object} props.formData - Dados do formulário
 * @param {function} props.handleChange - Função para lidar com mudanças nos campos
 * @param {array} props.tiposPoste - Lista de tipos de poste
 * @param {boolean} props.isTipoPosteLoading - Estado de carregamento dos tipos de poste
 * @param {string|null} props.tipoPosteError - Mensagem de erro ao buscar tipos de poste
 * @param {function} props.refetchTipoPoste - Função para refazer a busca de tipos de poste
 * @param {array} props.formatosPoste - Lista de formatos de poste
 * @param {boolean} props.isFormatoPosteLoading - Estado de carregamento dos formatos de poste
 * @param {string|null} props.formatoPosteError - Mensagem de erro ao buscar formatos de poste
 * @param {function} props.refetchFormatoPoste - Função para refazer a busca de formatos de poste
 * @param {array} props.formatosTampo - Lista de formatos de tampo
 * @param {boolean} props.isFormatTampoLoading - Estado de carregamento dos formatos de tampo
 * @param {string|null} props.formatoTampoError - Mensagem de erro ao buscar formatos de tampo
 * @param {function} props.refetchFormatTampo - Função para refazer a busca de formatos de tampo
 */
const ConfiguracaoFisica = ({
  formData,
  handleChange,
  tiposPoste,
  isTipoPosteLoading,
  tipoPosteError,
  refetchTipoPoste,
  formatosPoste,
  isFormatoPosteLoading,
  formatoPosteError,
  refetchFormatoPoste,
  formatosTampo,
  isFormatTampoLoading,
  formatoTampoError,
  refetchFormatTampo,
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Configuração Física</legend>
      <div className={styles.formGroup}>
        {/* Tipo Poste */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaWrench /> Tipo Poste
          </label>
          {isTipoPosteLoading ? (
            <p>Carregando tipos de poste...</p>
          ) : tipoPosteError ? (
            <div className={styles.errorContainer}>
              <p className={styles.errorText}>{tipoPosteError}</p>
              <button
                type="button"
                className={styles.retryButton}
                onClick={refetchTipoPoste}
              >
                <FaSyncAlt /> Tentar Novamente
              </button>
            </div>
          ) : tiposPoste && tiposPoste.length > 0 ? (
            <select
              className={styles.input}
              name="tipoPoste"
              value={formData.tipoPoste}
              onChange={handleChange}
              required
            >
              <option value="">Selecione</option>
              {tiposPoste.map((tipo) => (
                <option key={tipo.tp_id} value={tipo.tp_id}>
                  {tipo.tp_nome}
                </option>
              ))}
            </select>
          ) : (
            <p>Nenhum tipo de poste disponível.</p>
          )}
        </div>

        {/* Formato Poste */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaRuler /> Formato Poste
          </label>
          {isFormatoPosteLoading ? (
            <p>Carregando formatos de poste...</p>
          ) : formatoPosteError ? (
            <div className={styles.errorContainer}>
              <p className={styles.errorText}>{formatoPosteError}</p>
              <button
                type="button"
                className={styles.retryButton}
                onClick={refetchFormatoPoste}
              >
                <FaSyncAlt /> Tentar Novamente
              </button>
            </div>
          ) : formatosPoste && formatosPoste.length > 0 ? (
            <select
              className={styles.input}
              name="formatoPoste"
              value={formData.formatoPoste}
              onChange={handleChange}
              required
            >
              <option value="">Selecione</option>
              {formatosPoste.map((formato) => (
                <option key={formato.fp_id} value={formato.fp_id}>
                  {formato.fp_nome}
                </option>
              ))}
            </select>
          ) : (
            <p>Nenhum formato de poste disponível.</p>
          )}
        </div>

        {/* Formato Tampo */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaCogs /> Formato Tampo
          </label>
          {isFormatTampoLoading ? (
            <p>Carregando formatos de tampo...</p>
          ) : formatoTampoError ? (
            <div className={styles.errorContainer}>
              <p className={styles.errorText}>{formatoTampoError}</p>
              <button
                type="button"
                className={styles.retryButton}
                onClick={refetchFormatTampo}
              >
                <FaSyncAlt /> Tentar Novamente
              </button>
            </div>
          ) : formatosTampo && formatosTampo.length > 0 ? (
            <select
              className={styles.input}
              name="formatoTampo"
              value={formData.formatoTampo}
              onChange={handleChange}
              required
            >
              <option value="">Selecione</option>
              {formatosTampo.map((ft) => (
                <option key={ft.ft_id} value={ft.ft_id}>
                  {ft.ft_nome}
                </option>
              ))}
            </select>
          ) : (
            <p>Nenhum formato de tampo disponível.</p>
          )}
        </div>

        {/* Comprimento */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaRulerVertical /> Comprimento (cm)
          </label>
          <input
            className={styles.input}
            type="number"
            name="comprimento"
            value={formData.comprimento}
            onChange={handleChange}
            min="0"
            step="1" // Garante que apenas inteiros sejam inseridos
            required
          />
        </div>
      </div>
    </fieldset>
  );
};

export default ConfiguracaoFisica;
