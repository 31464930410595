import React, { useState, useEffect } from 'react';
import { FaPlus, FaEdit, FaTrash, FaSortUp, FaSortDown } from 'react-icons/fa';
import ConfigurationProfileModal from '../../components/ConfigurationProfileModal/ConfigurationProfileModal';
import styles from './PerfisConfiguracao.module.css';
import plansData from '../../data/plans.json';

const PerfisConfiguracao = () => {
  const [plans, setPlans] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  useEffect(() => {
    // Load plans from JSON
    setPlans(plansData);
  }, []);

  const handleSavePlan = (plan) => {
    if (plan.id) {
      setPlans(plans.map(p => (p.id === plan.id ? plan : p)));
    } else {
      setPlans([...plans, { ...plan, id: `plan${plans.length + 1}` }]);
    }
  };

  const handleEditPlan = (plan) => {
    setCurrentPlan(plan);
    setModalIsOpen(true);
  };

  const handleDeletePlan = (planId) => {
    setPlans(plans.filter(plan => plan.id !== planId));
  };

  const handleAddNew = () => {
    setCurrentPlan(null);
    setModalIsOpen(true);
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedPlans = () => {
    const sorted = [...plans];
    if (sortConfig.key) {
      sorted.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sorted;
  };

  return (
    <div className={styles.configurationProfilesPage}>
      <h1 className={styles.header}>Perfis de Configuração</h1>
      <div className={styles.controlsTop}>
        <button className={styles.addButton} onClick={handleAddNew}>
          <FaPlus /> Adicionar Novo Perfil
        </button>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th onClick={() => requestSort('name')}>
              Nome {sortConfig.key === 'name' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('startTime')}>
              Hora de Acender {sortConfig.key === 'startTime' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('endTime')}>
              Hora de Apagar {sortConfig.key === 'endTime' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th onClick={() => requestSort('dimValue')}>
              Dimerização {sortConfig.key === 'dimValue' && (sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />)}
            </th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {sortedPlans().map(plan => (
            <tr key={plan.id}>
              <td>{plan.name}</td>
              <td>{plan.startTime}</td>
              <td>{plan.endTime}</td>
              <td>{plan.dimValue}%</td>
              <td>
                <button className={styles.actionButton} onClick={() => handleEditPlan(plan)}>
                  <FaEdit />
                </button>
                <button className={`${styles.actionButton} ${styles.deleteButton}`} onClick={() => handleDeletePlan(plan.id)}>
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ConfigurationProfileModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        onSave={handleSavePlan}
        initialData={currentPlan}
      />
    </div>
  );
};

export default PerfisConfiguracao;
