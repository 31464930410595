// src/pages/Relatorios/UltimasLeiturasDispositivos.jsx

import React, { useState, useEffect } from 'react';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';

const UltimasLeiturasDispositivos = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [leiturasDispositivos, setLeiturasDispositivos] = useState([]);

  useEffect(() => {
    // Extrair as últimas leituras dos dispositivos (fotocélulas)
    const leituras = postesDetalhados
      .flatMap((poste) =>
        (poste.fotocelulas || []).map((fotocelula) => ({
          descricao: fotocelula.descricao,
          dataAtualizacao: fotocelula.deviceAtualizacao,
          latitude: fotocelula.latitude || 'N/A',
          longitude: fotocelula.longitude || 'N/A',
          corrente: fotocelula.corrente || 'N/A',
          potenciaFotocelula: fotocelula.potencia || 'N/A',
          tensaoRede: fotocelula.tensaoRede || 'N/A',
          tempoLampadaLigada: fotocelula.tempolampadaligada || 'N/A',
          modoOperacao: fotocelula.modofotocelula === 'S' ? 'Automático' : 'Manual',
          pa: fotocelula.pa || 'N/A',
          pr: fotocelula.pr || 'N/A',
          pf: fotocelula.pf || 'N/A',
          ativo: fotocelula.ativo === 'S' ? 'Ativo' : 'Inativo',
          numeroPoste: poste.codigoPoste || 'N/A',
          potenciaPoste: poste.potencia || 'N/A',
          consumoPoste: poste.consumo || 'N/A', // Inclui o consumo do poste
        }))
      )
      .filter((fotocelula) => fotocelula.dataAtualizacao); // Apenas dispositivos com atualizações

    setLeiturasDispositivos(leituras);
  }, [postesDetalhados]);

  const handleExportToExcel = () => {
    const exportData = leiturasDispositivos.map((leitura) => ({
      'Descrição': leitura.descricao,
      'Número do Poste': leitura.numeroPoste,
      'Potência do Poste': leitura.potenciaPoste,
      'Consumo do Poste': leitura.consumoPoste,
      'Data da Última Atualização': leitura.dataAtualizacao,
      'Latitude': leitura.latitude,
      'Longitude': leitura.longitude,
      'Corrente': leitura.corrente,
      'Potência da Fotocélula': leitura.potenciaFotocelula,
      'Tensão da Rede': leitura.tensaoRede,
      'Tempo de Lâmpada Ligada': leitura.tempoLampadaLigada,
      'Modo de Operação': leitura.modoOperacao,
      'Potência Aparente (PA)': leitura.pa,
      'Potência Reativa (PR)': leitura.pr,
      'Fator de Potência (PF)': leitura.pf,
      'Status de Ativação': leitura.ativo,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Últimas Leituras');
    XLSX.writeFile(workbook, 'ultimas_leituras_dispositivos.xlsx');
  };

  return (
    <div className={styles.container}>
      <h2>Relatório de Últimas Leituras dos Dispositivos</h2>
      {isLoading && <p>Carregando dados...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {!isLoading && leiturasDispositivos.length > 0 && (
        <>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            Exportar para Excel
          </button>
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Número do Poste</th>
                  <th>Potência do Poste</th>
                  <th>Consumo do Poste</th>
                  <th>Data da Última Atualização</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                  <th>Corrente</th>
                  <th>Potência da Fotocélula</th>
                  <th>Tensão da Rede</th>
                  <th>Tempo de Lâmpada Ligada</th>
                  <th>Modo de Operação</th>
                  <th>PA</th>
                  <th>PR</th>
                  <th>PF</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {leiturasDispositivos.map((leitura, index) => (
                  <tr key={index}>
                    <td>{leitura.descricao}</td>
                    <td>{leitura.numeroPoste}</td>
                    <td>{leitura.potenciaPoste}</td>
                    <td>{leitura.consumoPoste}</td>
                    <td>{leitura.dataAtualizacao}</td>
                    <td>{leitura.latitude}</td>
                    <td>{leitura.longitude}</td>
                    <td>{leitura.corrente}</td>
                    <td>{leitura.potenciaFotocelula}</td>
                    <td>{leitura.tensaoRede}</td>
                    <td>{leitura.tempoLampadaLigada}</td>
                    <td>{leitura.modoOperacao}</td>
                    <td>{leitura.pa}</td>
                    <td>{leitura.pr}</td>
                    <td>{leitura.pf}</td>
                    <td>{leitura.ativo}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      {!isLoading && leiturasDispositivos.length === 0 && (
        <p>Nenhuma leitura disponível.</p>
      )}
    </div>
  );
};

export default UltimasLeiturasDispositivos;
