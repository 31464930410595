// src/pages/Postes/SubComponents/IdentificacaoLocalizacao.jsx

import React, { useContext, useEffect, useState } from 'react';
import {
  FaIdCard,
  FaCode,
  FaMapMarkerAlt,
  FaGlobe,
  FaMapSigns,
  FaCity,
  FaMap,
  FaLink, // Ícone para o botão de formatação
} from 'react-icons/fa';
import styles from '../PosteModal.module.css';

// Importar o toast do react-toastify
import { toast } from 'react-toastify';

// Importar a função de configuração para obter a sigla da cidade
import { getCityAbbreviationById } from '../../../config';

// Importar AuthContext para obter o cidadeId
import { AuthContext } from '../../../context/AuthContext';

const IdentificacaoLocalizacao = ({
  formData,
  handleChange,
  handleGetUserLocation,
  countries,
  isCountriesLoading,
  countriesError,
  states,
  isStatesLoading,
  statesError,
  cities,
  isCitiesLoading,
  citiesError,
  regioes,
  isRegiaoLoading,
  regiaoError,
}) => {
  const { cidadeId } = useContext(AuthContext); // Obter `cidadeId` do contexto

  // Estado local para gerenciar o valor do Código Poste e erros de validação
  const [codigoPoste, setCodigoPoste] = useState(formData.codigoPoste || '');
  const [codigoPosteError, setCodigoPosteError] = useState('');

  // Atualizar o estado local quando formData.codigoPoste mudar
  useEffect(() => {
    setCodigoPoste(formData.codigoPoste || '');
  }, [formData.codigoPoste]);

  // Função para validar o formato do Código Poste
  const validateCodigoPoste = (value) => {
    const sigla = getCityAbbreviationById(cidadeId);
    // Regex para "CEA000000123" (sem espaço)
    const regex = new RegExp(`^${sigla}\\d{9}$`);
    if (!regex.test(value)) {
      return { valid: false, message: `Formato inválido. Exemplo: ${sigla}000000123` };
    }

    // Verificar se os nove dígitos não são todos zeros
    const numericPart = value.slice(sigla.length);
    if (/^0{9}$/.test(numericPart)) {
      return { valid: false, message: 'Os nove dígitos não podem ser todos zeros.' };
    }

    return { valid: true, message: '' };
  };

  // Função para lidar com a formatação do Código Poste
  const formatCodigoPoste = () => {
    const sigla = getCityAbbreviationById(cidadeId);
    if (sigla === 'N/A') {
      toast.error('Sigla da cidade não encontrada. Verifique o `cidadeId`.');
      return;
    }

    const numericPart = codigoPoste.replace(/\D/g, ''); // Remove tudo que não é dígito
    if (numericPart.length > 9) {
      toast.error('O número do Código Poste não pode exceder 9 dígitos.');
      return;
    }

    const paddedNumber = numericPart.padStart(9, '0'); // Preenche com zeros à esquerda se necessário
    const formattedCodigo = `${sigla}${paddedNumber}`; // Sem espaço
    setCodigoPoste(formattedCodigo);
    handleChange({
      target: {
        name: 'codigoPoste',
        value: formattedCodigo,
      },
    });
    setCodigoPosteError('');
    toast.success('Código Poste formatado com sucesso.');
  };

  // Função para lidar com a mudança no input
  const handleInputChange = (e) => {
    const { value } = e.target;
    setCodigoPoste(value);
    handleChange(e);

    // Validação em tempo real
    if (value.trim() === '') {
      setCodigoPosteError('Código Poste é obrigatório.');
    } else {
      const validation = validateCodigoPoste(value.trim());
      if (!validation.valid) {
        setCodigoPosteError(validation.message);
      } else {
        setCodigoPosteError('');
      }
    }
  };

  return (
    <fieldset className={styles.fieldset}>
      <legend>Identificação</legend>
      <div className={styles.formGroup}>
        {/* ID - Não Editável */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaIdCard /> ID
          </label>
          <input
            className={styles.input}
            type="text"
            name="idPoste"
            value={formData.idPoste}
            readOnly
          />
        </div>

        {/* Código Poste - Editável e Obrigatório */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaCode /> Código Poste<span className={styles.required}>*</span>
          </label>
          <div className={styles.inputWithButton}>
            <input
              className={`${styles.input} ${codigoPosteError ? styles.inputError : ''}`}
              type="text"
              name="codigoPoste"
              value={codigoPoste}
              onChange={handleInputChange}
              required
              placeholder="CEA000000123"
            />
            <button
              type="button"
              className={styles.formatButton}
              onClick={formatCodigoPoste}
              title="Formatar Código Poste"
            >
              <FaLink />
            </button>
          </div>
          {codigoPosteError && <p className={styles.errorMessage}>{codigoPosteError}</p>}
        </div>

        {/* Removidos os campos Status e Etiqueta Poste */}
      </div>

      <legend>Localização</legend>
      <div className={styles.formGroup}>
        {/* Geo Localização */}
        <div className={styles.colSpan2}>
          <label className={styles.label}>
            <FaMapMarkerAlt /> Geo Localização
          </label>
          <input
            className={styles.input}
            type="text"
            name="geoLocalizacao"
            value={formData.geoLocalizacao}
            onChange={handleChange}
            required
            placeholder="latitude, longitude"
          />
        </div>

        {/* Botão para obter geolocalização */}
        <div
          className={styles.colSpan1}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <button
            type="button"
            className={styles.button}
            onClick={handleGetUserLocation}
          >
            Obter Geolocalização
          </button>
        </div>

        {/* País */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaGlobe /> País
          </label>
          {isCountriesLoading ? (
            <p>Carregando países...</p>
          ) : countriesError ? (
            <p className={styles.errorMessage}>{countriesError}</p>
          ) : (
            <select
              className={styles.input}
              name="pais"
              value={formData.pais}
              onChange={handleChange}
              required
            >
              <option value="">Selecione um país</option>
              {countries.map((country) => (
                <option key={country.id} value={String(country.id)}>
                  {country.nome_pt || country.nome}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Estado */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaMapSigns /> Estado
          </label>
          {isStatesLoading ? (
            <p>Carregando estados...</p>
          ) : statesError ? (
            <p className={styles.errorMessage}>{statesError}</p>
          ) : (
            <select
              className={styles.input}
              name="estado"
              value={formData.estado}
              onChange={handleChange}
              required
              disabled={!formData.pais} /* Desabilita se nenhum país estiver selecionado */
            >
              <option value="">Selecione um estado</option>
              {states.map((state) => (
                <option key={state.id} value={String(state.id)}>
                  {state.nome} ({state.uf})
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Cidade */}
        <div className={styles.colSpan1}>
          <label className={styles.label}>
            <FaCity /> Cidade
          </label>
          {isCitiesLoading ? (
            <p>Carregando cidades...</p>
          ) : citiesError ? (
            <p className={styles.errorMessage}>{citiesError}</p>
          ) : (
            <select
              className={styles.input}
              name="cidade"
              value={formData.cidade}
              onChange={handleChange}
              required
              disabled={!formData.estado} /* Desabilita se nenhum estado estiver selecionado */
            >
              <option value="">Selecione uma cidade</option>
              {cities.map((city) => (
                <option key={city.id} value={String(city.id)}>
                  {city.nome}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Região */}
        <div className={styles.colSpan3}>
          <label className={styles.label}>
            <FaMap /> Região
          </label>
          {isRegiaoLoading ? (
            <p>Carregando regiões...</p>
          ) : regiaoError ? (
            <p className={styles.errorMessage}>{regiaoError}</p>
          ) : (
            <select
              className={styles.input}
              name="regiao"
              value={formData.regiao}
              onChange={handleChange}
              required
              disabled={!formData.cidade} /* Desabilita se nenhuma cidade estiver selecionada */
            >
              <option value="">Selecione uma região</option>
              {regioes.map((regiao) => (
                <option key={regiao.regiaoId} value={String(regiao.regiaoId)}>
                  {regiao.regiaoNome}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
    </fieldset>
  );
};

export default IdentificacaoLocalizacao;
