// src/pages/OrdensServicos/EditServiceOrderModal.jsx

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styles from './EditServiceOrderModal.module.css';
import { toast } from 'react-toastify'; // Importando toast para notificações
import useAlertaSituacao from '../../hooks/useAlertaSituacao'; // Importando o hook

Modal.setAppElement('#root');

const EditServiceOrderModal = ({ isOpen, onRequestClose, order, alerta, onSave, isViewOnly }) => {
  const [status, setStatus] = useState(''); // Estado para o status
  const [isAlertInfoVisible, setIsAlertInfoVisible] = useState(false); // Estado para controlar a visibilidade

  const { situacoes, isLoading: situacoesLoading, error: situacoesError } = useAlertaSituacao(); // Usando o hook

  useEffect(() => {
    if (order) {
      // Se for modo de visualização, inicializa o status com o valor atual; caso contrário, vazio
      setStatus(isViewOnly ? order.ordemServico?.osSituacaoId.toString() || '' : '');
      setIsAlertInfoVisible(false); // Resetar a visibilidade ao abrir o modal
    }
  }, [order, isOpen, isViewOnly]);

  const handleSave = () => {
    if (status === '') {
      toast.error('Por favor, selecione um status válido.');
      return;
    }

    // Verifica se o status selecionado é válido (3 ou 4)
    const validStatusIds = ['3', '4'];
    if (!validStatusIds.includes(status)) {
      toast.error('Selecione um status válido: Cancelada ou Finalizada.');
      return;
    }

    // Atualiza a OS com o novo status dentro de ordemServico
    const updatedData = { 
      ...order, 
      ordemServico: { 
        ...order.ordemServico, 
        osSituacaoId: parseInt(status),
        // Mantém o osObs inalterado, pois o campo foi removido da edição
      }
    };
    
    console.log('Dados atualizados para salvar:', updatedData);
    onSave(updatedData);
  };

  const toggleAlertInfo = () => {
    setIsAlertInfoVisible((prevState) => !prevState);
  };

  // Função para mapear o ID da situação para o nome usando as situações dinâmicas
  const getSituacaoNome = (situacaoId) => {
    return situacoes[situacaoId] || 'Desconhecido';
  };

  // Tratamento de carregamento e erro
  if (situacoesLoading) {
    return <div>Carregando...</div>; // Ou um componente de loading específico
  }

  if (situacoesError) {
    return <div className={styles.error}>Erro ao carregar as situações: {situacoesError}</div>;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isViewOnly ? "Visualizar Ordem de Serviço" : "Editar Ordem de Serviço"}
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <h2>{isViewOnly ? "Visualizar Ordem de Serviço" : "Editar Ordem de Serviço"}</h2>

      {/* Botão para expandir/colapsar informações do alerta */}
      <button className={styles.toggleButton} onClick={toggleAlertInfo}>
        {isAlertInfoVisible ? (
          <>
            <FaChevronUp /> Ocultar Informações do Alerta
          </>
        ) : (
          <>
            <FaChevronDown /> Exibir Informações do Alerta
          </>
        )}
      </button>

      {/* Seção de Informações do Alerta */}
      {isAlertInfoVisible && (
        <div className={styles.infoSection}>
          <h3>Informações do Alerta</h3>
          <p><strong>ID do Alerta:</strong> {alerta.alertaId}</p>
          <p><strong>Motivo:</strong> {alerta.alertaMotivo}</p>
          <p><strong>Poste ID:</strong> {alerta.alertaPosteId}</p>
          <p><strong>Cidade:</strong> {alerta.alertaCidade}</p>
          <p><strong>Telefone:</strong> {alerta.alertaTelefone}</p>
          <p><strong>Origem:</strong> {alerta.alertaOrigem}</p>
          <p><strong>Prestador:</strong> {alerta.prestadorNome}</p> {/* Exibe o nome do prestador */}
        </div>
      )}

      {/* Seção de Informações da OS */}
      <div className={styles.infoSection}>
        <h3>Informações da Ordem de Serviço</h3>
        <p><strong>ID da OS:</strong> {order.ordemServico?.osId || 'Não Disponível'}</p>
        <p><strong>Poste ID:</strong> {order.ordemServico?.osPosteId || 'Não Disponível'}</p>
        <p><strong>Cidade ID:</strong> {order.ordemServico?.osCidadeId || 'Não Disponível'}</p>
        <p><strong>Data de Abertura:</strong> {order.ordemServico?.osDataAbertura ? new Date(order.ordemServico.osDataAbertura).toLocaleDateString() : 'Não Disponível'}</p>
        <p><strong>Hora de Abertura:</strong> {order.ordemServico?.osHoraAbertura || 'Não Disponível'}</p>
        <p><strong>Prestador:</strong> {alerta.prestadorNome || 'Desconhecido'}</p> {/* Exibe o nome do prestador */}
        <p><strong>Status Atual:</strong> {getSituacaoNome(order.ordemServico?.osSituacaoId)}</p>
        <p><strong>Observações:</strong> {order.ordemServico?.osObs || 'Nenhuma Observação'}</p>
      </div>

      {/* Formulário de Edição (oculto em modo de visualização) */}
      {!isViewOnly && (
        <form onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
          <div className={styles.formGroup}>
            <label>Status:</label>
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="">Selecione</option> {/* Opção padrão */}
              {Object.entries(situacoes).map(([id, nome]) => (
                (id === '3' || id === '4') && (
                  <option key={id} value={id}>
                    {nome}
                  </option>
                )
              ))}
            </select>
          </div>
          <button type="submit" className={styles.saveButton}>Salvar</button>
        </form>
      )}
    </Modal>
  );
};

export default EditServiceOrderModal;
