import React, { useState, useEffect } from 'react';
import { FaIdCard, FaCog, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import styles from './TipoPostesModal.module.css';

Modal.setAppElement('#root');

const TipoPostesModal = ({ isOpen, onRequestClose, onSubmit, initialData }) => {
  const [formData, setFormData] = useState({
    tp_id: '',
    tp_nome: '',
  });

  useEffect(() => {
    if (initialData) {
      const formattedData = {
        ...initialData,
        tp_id: initialData.tp_id,
      };
      setFormData(formattedData);
    }else{
      setFormData({
        tp_id: '',
        tp_nome: '',
      });
    }
  }, [initialData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ ...formData });
    onRequestClose();
  };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Informações de Tipo Poste"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <button className={styles.closeModalButton} onClick={onRequestClose}>
        <FaTimes />
      </button>
      <div className={styles.postePage}>
        <h1 className={styles.header}>Especificações</h1>
        <form onSubmit={handleSubmit}>
          <fieldset className={styles.fieldset}>
            <div className={styles.formGroup}>
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaIdCard /> ID</label>
                <input
                  className={styles.input}
                  type="text"
                  name="tp_id"
                  value={formData.tp_id}
                  onChange={handleChange}
                  readOnly
                />
              </div>
              <div className={styles.colSpan2}>
                <label className={styles.label}><FaCog /> Tipo</label>
                <input
                  className={styles.input}
                  type="text"
                  name="tp_nome"
                  value={formData.tp_nome}
                  onChange={handleChange}
                />
              </div>
        
            </div>
          </fieldset>

          <button className={styles.button} type="submit">Salvar</button>
        </form>

      </div>
    </Modal>
  );
};

export default TipoPostesModal;
