import React, { useEffect, useState } from 'react';
import usePosteDataComAlertas from '../../hooks/usePosteDataComAlertas';
import styles from './Relatorios.module.css';
import * as XLSX from 'xlsx';

const RelatorioInventarioPostes = () => {
  const { postesDetalhados, isLoading, error } = usePosteDataComAlertas();
  const [filteredPostes, setFilteredPostes] = useState([]);

  useEffect(() => {
    setFilteredPostes(postesDetalhados);
  }, [postesDetalhados]);

  const handleExportToExcel = () => {
    const exportData = filteredPostes.map((poste) => ({
      'ID do Poste': poste.id,
      'Código do Poste': poste.codigoPoste,
      'Geo-localização': poste.geoLocalizacao || 'N/A',
      'Tipo de Poste': poste.tipoPoste || 'Desconhecido',
      'Status de Ativação': poste.ativo === 'S' ? 'Ativo' : 'Inativo',
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventário de Postes');
    XLSX.writeFile(workbook, 'inventario_postes.xlsx');
  };

  return (
    <div className={styles.container}>
      <h2>Relatório de Inventário de Postes</h2>
      {isLoading && <p>Carregando...</p>}
      {error && <p className={styles.errorMessage}>{error}</p>}
      {!isLoading && filteredPostes.length > 0 && (
        <>
          <button onClick={handleExportToExcel} className={styles.exportButton}>
            Exportar para Excel
          </button>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>Código</th>
                <th>Geo-localização</th>
                <th>Tipo</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredPostes.map((poste) => (
                <tr key={poste.id}>
                  <td>{poste.id}</td>
                  <td>{poste.codigoPoste}</td>
                  <td>{poste.geoLocalizacao || 'N/A'}</td>
                  <td>{poste.tipoPoste || 'Desconhecido'}</td>
                  <td>{poste.ativo === 'S' ? 'Ativo' : 'Inativo'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
      {!isLoading && filteredPostes.length === 0 && (
        <p>Nenhum dado disponível para exibição.</p>
      )}
    </div>
  );
};

export default RelatorioInventarioPostes;
