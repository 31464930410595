import React from 'react';
import Modal from 'react-modal';
import styles from './ConfirmDeleteLuminariaModal.module.css';

const ConfirmDeleteLuminariaModal = ({ isOpen, onRequestClose, onConfirmDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Confirmar Exclusão"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2>Tem certeza que quer excluir esta luminária?</h2>
      <div className={styles.buttonContainer}>
        <button onClick={onConfirmDelete} className={styles.confirmButton}>Sim</button>
        <button onClick={onRequestClose} className={styles.closeModalButton}>Não</button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteLuminariaModal;
