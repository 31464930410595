// src/pages/Postes/SubComponents/Componentes.jsx

import React from 'react';
import { FaPlus, FaTrash, FaSave, FaCogs, FaLayerGroup, FaCalendar, FaClock } from 'react-icons/fa';
import styles from '../PosteModal.module.css';

const Componentes = ({
  grupos,
  isGruposLoading,
  gruposError,
  componentes,
  isComponentesLoading,
  componentesError,
  savedComponentes,
  unsavedComponentes,
  handleAddComponent,
  handleComponentChange,
  handleSaveComponent,
  handleRemoveComponent,
  animateSaveButtons,
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Componentes</legend>

      {/* Componentes Salvos */}
      {savedComponentes.length > 0 && (
        <div>
          <h4>Componentes Salvos</h4>
          {savedComponentes.map((componente) => (
            <div key={componente.posteestruturaId} className={styles.itemContainer}>
              {/* Grupo */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLayerGroup /> Grupo</label>
                <input
                  className={styles.input}
                  type="text"
                  name="grupoNome"
                  value={componente.grupoNome}
                  readOnly
                />
              </div>

              {/* Componente */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCogs /> Componente</label>
                <input
                  className={styles.input}
                  type="text"
                  name="componenteDescricao"
                  value={componente.componenteDescricao}
                  readOnly
                />
              </div>

              {/* Tipo de Instalação */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCogs /> Tipo de Instalação</label>
                <input
                  className={styles.input}
                  type="text"
                  name="tipoInstalacao"
                  value={componente.tipoInstalacao}
                  readOnly
                />
              </div>

              {/* Quantidade */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLayerGroup /> Quantidade</label>
                <input
                  className={styles.input}
                  type="number"
                  name="quantidade"
                  value={componente.quantidade}
                  readOnly
                />
              </div>

              {/* Data */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCalendar /> Data</label>
                <input
                  className={styles.input}
                  type="text"
                  name="data"
                  value={componente.data}
                  readOnly
                />
              </div>

              {/* Hora */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaClock /> Hora</label>
                <input
                  className={styles.input}
                  type="text"
                  name="hora"
                  value={componente.hora}
                  readOnly
                />
              </div>

              {/* Botão para Remover Componente Salvo */}
              <button
                type="button"
                className={styles.removeButton}
                onClick={() => handleRemoveComponent(componente, true)}
                title="Remover Componente Salvo"
              >
                <FaTrash />
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Componentes Não Salvos */}
      {unsavedComponentes.length > 0 && (
        <div>
          <h4>Componentes Não Salvos</h4>
          {unsavedComponentes.map((componente, index) => (
            <div key={index} className={styles.itemContainer}>
              {/* Grupo */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLayerGroup /> Grupo</label>
                <select
                  className={styles.input}
                  name="grupoId"
                  value={componente.grupoId}
                  onChange={(e) => handleComponentChange(index, e)}
                  required
                >
                  <option value="">Selecione um grupo</option>
                  {isGruposLoading ? (
                    <option value="">Carregando grupos...</option>
                  ) : gruposError ? (
                    <option value="">Erro ao carregar grupos</option>
                  ) : (
                    grupos.map((grupo) => (
                      <option key={grupo.pgId} value={grupo.pgId}>
                        {grupo.pgNome}
                      </option>
                    ))
                  )}
                </select>
              </div>

              {/* Componente */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCogs /> Componente</label>
                <select
                  className={styles.input}
                  name="componenteId"
                  value={componente.componenteId}
                  onChange={(e) => handleComponentChange(index, e)}
                  required
                  disabled={!componente.grupoId}
                >
                  <option value="">Selecione um componente</option>
                  {isComponentesLoading ? (
                    <option value="">Carregando componentes...</option>
                  ) : componentesError ? (
                    <option value="">Erro ao carregar componentes</option>
                  ) : (
                    componentes
                      .filter((comp) => comp.produtoGrupo === componente.grupoId)
                      .map((comp) => (
                        <option key={comp.produtoId} value={comp.produtoId}>
                          {comp.produtoDescricao}
                        </option>
                      ))
                  )}
                </select>
              </div>

              {/* Tipo de Instalação */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCogs /> Tipo de Instalação</label>
                <select
                  className={styles.input}
                  name="tipoInstalacao"
                  value={componente.tipoInstalacao}
                  onChange={(e) => handleComponentChange(index, e)}
                  required
                >
                  <option value="">Selecione o tipo de instalação</option>
                  <option value="Instalação">Instalação</option>
                  <option value="Alteração">Alteração</option>
                  {/* Adicione mais opções conforme necessário */}
                </select>
              </div>

              {/* Quantidade */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaLayerGroup /> Quantidade</label>
                <input
                  className={styles.input}
                  type="number"
                  name="quantidade"
                  value={componente.quantidade}
                  onChange={(e) => handleComponentChange(index, e)}
                  required
                  min="1"
                />
              </div>

              {/* Data */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaCalendar /> Data</label>
                <input
                  className={styles.input}
                  type="text"
                  name="data"
                  value={componente.data}
                  readOnly
                />
              </div>

              {/* Hora */}
              <div className={styles.colSpan1}>
                <label className={styles.label}><FaClock /> Hora</label>
                <input
                  className={styles.input}
                  type="text"
                  name="hora"
                  value={componente.hora}
                  readOnly
                />
              </div>

              {/* Botões de Ação */}
              <div className={styles.buttonContainer}>
                <button
                  type="button"
                  className={`${styles.saveButton} ${animateSaveButtons[index] ? styles.animateSaveButton : ''}`}
                  onClick={() => handleSaveComponent(index)}
                  title="Salvar Componente"
                >
                  <FaSave />
                </button>
                <button
                  type="button"
                  className={styles.removeButton}
                  onClick={() => handleRemoveComponent(componente)}
                  title="Remover Componente"
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Botão para Adicionar Novo Componente */}
      <button
        type="button"
        className={styles.button}
        onClick={handleAddComponent}
      >
        <FaPlus /> Adicionar Componente
      </button>
    </fieldset>
  );
};

export default Componentes;
