// src/pages/Postes/SubComponents/OutrosDetalhes.jsx

import React from 'react';
import { FaBolt, FaLightbulb, FaClipboardList } from 'react-icons/fa';
import styles from '../PosteModal.module.css';

const OutrosDetalhes = ({ formData, handleChange }) => {
  return (
    <fieldset className={styles.fieldset}>
      <legend>Outros Detalhes</legend>
      <div className={styles.formGroup}>
        {/* Barramento */}
        <div className={styles.colSpan1}>
          <label className={styles.label}><FaBolt /> Barramento</label>
          <input
            className={styles.input}
            type="text"
            name="barramento"
            value={formData.barramento}
            onChange={handleChange}
            required
          />
        </div>

        {/* Cabo de Alimentação */}
        <div className={styles.colSpan2}>
          <label className={styles.label}><FaBolt /> Cabo de Alimentação</label>
          <input
            className={styles.input}
            type="text"
            name="caboAlimentacao"
            value={formData.caboAlimentacao}
            onChange={handleChange}
            required
          />
        </div>

        {/* Lâmpada Medida */}
        <div className={styles.colSpan1}>
          <label className={styles.label}><FaLightbulb /> Lâmpada Medida</label>
          <select
            className={styles.input}
            name="lampadaMedida"
            value={formData.lampadaMedida}
            onChange={handleChange}
            required
          >
            <option value="">Selecione</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </select>
        </div>

        {/* Rede Alta Tensão */}
        <div className={styles.colSpan1}>
          <label className={styles.label}><FaBolt /> Rede Alta Tensão</label>
          <select
            className={styles.input}
            name="redeAltaTensao"
            value={formData.redeAltaTensao}
            onChange={handleChange}
            required
          >
            <option value="">Selecione</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </select>
        </div>

        {/* Número Medidor Lâmpada */}
        <div className={styles.colSpan1}>
          <label className={styles.label}><FaClipboardList /> Nº Medidor Lâmpada</label>
          <input
            className={styles.input}
            type="text"
            name="numeroMedidorLampada"
            value={formData.numeroMedidorLampada}
            onChange={handleChange}
            required
          />
        </div>

        {/* Comando */}
        <div className={styles.colSpan1}>
          <label className={styles.label}><FaClipboardList /> Comando</label>
          <select
            className={styles.input}
            name="comando"
            value={formData.comando}
            onChange={handleChange}
            required
          >
            <option value="">Selecione</option>
            <option value="1">Sim</option>
            <option value="0">Não</option>
          </select>
        </div>
      </div>
    </fieldset>
  );
};

export default OutrosDetalhes;
