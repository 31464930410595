import { useState, useEffect, useCallback, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { toast } from 'react-toastify';

/**
 * Hook customizado para buscar a lista de grupos que são utilizados nas luminárias.
 * @param {boolean} isOpen - Indica se o modal está aberto.
 * @returns {object} - Contém a lista de grupos, estado de carregamento, erro e função para refetch.
 */
const useGrupos = (isOpen) => {
  const { token, logout } = useContext(AuthContext); // Obtém token e logout do contexto
  const [grupos, setGrupos] = useState([]);
  const [isGruposLoading, setIsGruposLoading] = useState(false);
  const [gruposError, setGruposError] = useState(null);

  const fetchGrupos = useCallback(async () => {
    if (!isOpen) return;

    setIsGruposLoading(true);
    setGruposError(null);

    try {
      if (!token) throw new Error('Token de autenticação não encontrado');

      const response = await fetch('https://api.ads10.com.br/api/prodgrupo', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
        toast.error('Sessão expirada. Faça login novamente.');
        logout(); // Logout ao expirar sessão
        return;
      }

      if (!response.ok) {
        throw new Error(`Erro ao buscar grupos: ${response.statusText}`);
      }

      const data = await response.json();
      // Filtrar grupos onde "pgAtualizaCapaPoste" é "s"
      const gruposFiltrados = data.filter((grupo) => grupo.pgAtualizaCapaPoste === 's');
      setGrupos(gruposFiltrados);

    } catch (error) {
      console.error('Erro ao buscar grupos:', error);
      setGruposError('Falha ao carregar a lista de grupos. Verifique sua conexão ou tente novamente mais tarde.');
      toast.error('Erro ao carregar grupos. Por favor, tente novamente.');
    } finally {
      setIsGruposLoading(false);
    }
  }, [isOpen, token, logout]);

  useEffect(() => {
    fetchGrupos();
  }, [fetchGrupos]);

  return { grupos, isGruposLoading, gruposError, refetch: fetchGrupos };
};

export default useGrupos;
