// src/pages/AtrelarDispositivo/AtrelarModal.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AtrelarModal.module.css';
import { MdClose, MdDelete } from 'react-icons/md'; // Importando ícone de lixeira

const AtrelarModal = ({
  isOpen,
  onClose,
  markerData,
  atribuirPoste,
  removerDispositivo,
  postesDropdown,
  markerType,
  error,
  setError,
}) => {
  const [selectedPosteId, setSelectedPosteId] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para armazenar o termo de busca do poste

  if (!isOpen) return null;

  const handleAtribuir = async () => {
    if (!selectedPosteId) {
      setError('Por favor, selecione um poste para atribuir.');
      return;
    }
    setLoading(true);
    await atribuirPoste(selectedPosteId, markerData.deviceId);
    setLoading(false);
  };

  const handleRemover = async (deviceId) => {
    // Adicionando confirmação antes de remover o dispositivo
    const confirmRemoval = window.confirm(
      `Você realmente deseja remover o dispositivo ${deviceId}?`
    );

    if (!confirmRemoval) {
      return; // Não faz nada se o usuário cancelar
    }

    setLoading(true);
    await removerDispositivo(deviceId, markerData.id);
    setLoading(false);
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  // Filtrar os postes de acordo com o termo de busca
  const filteredPostes = postesDropdown.filter(
    (poste) =>
      poste.ativo === 'S' &&
      (poste.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        poste.codigoPoste?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        poste.id.toString().includes(searchTerm))
  );

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button
          className={styles.closeButton}
          onClick={handleClose}
          aria-label="Fechar Modal"
        >
          <MdClose size={24} />
        </button>
        <h2>
          {markerType === 'poste'
            ? 'Dispositivos Atrelados ao Poste'
            : 'Atribuir Poste ao Dispositivo'}
        </h2>

        <div className={styles.markerInfo}>
          {markerType === 'poste' ? (
            <>
              <p>
                <strong>ID do Poste:</strong> {markerData.id || markerData.codigoPoste}
              </p>
              <p>
                <strong>Código do Poste:</strong> {markerData.codigoPoste || 'N/A'}
              </p>
              <p>
                <strong>Nome:</strong> {markerData.name || 'N/A'}
              </p>
              <p>
                <strong>Localização:</strong> Latitude {markerData.lat}, Longitude {markerData.lng}
              </p>
              <div className={styles.deviceList}>
                {markerData.fotocelulas && markerData.fotocelulas.length > 0 ? (
                  markerData.fotocelulas.map((dispositivo) => (
                    <div key={dispositivo.deviceId} className={styles.deviceItem}>
                      <span>{dispositivo.deviceId}</span>
                      <button
                        className={styles.deleteButton}
                        onClick={() => handleRemover(dispositivo.deviceId)}
                        aria-label={`Remover dispositivo ${dispositivo.deviceId}`}
                      >
                        <MdDelete size={24} />
                      </button>
                    </div>
                  ))
                ) : (
                  <p>Nenhum dispositivo atrelado</p>
                )}
              </div>
            </>
          ) : (
            <>
              <p>
                <strong>ID do Dispositivo:</strong> {markerData.deviceId}
              </p>
              <p>
                <strong>Latitude:</strong> {markerData.Lat}
              </p>
              <p>
                <strong>Longitude:</strong> {markerData.Lng}
              </p>
              <p>
                <strong>Última Atualização:</strong> {markerData.recifeDate}
              </p>
              <div className={styles.formGroup}>
                <label htmlFor="posteSelect">Pesquisar e Selecionar Poste:</label>
                <input
                  type="text"
                  id="posteSelect"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setSelectedPosteId(''); // Limpar a seleção quando mudar a busca
                  }}
                  placeholder="Digite para pesquisar e selecione um poste"
                  className={styles.searchInput}
                  list="postesList"
                />
                <datalist id="postesList">
                  {filteredPostes.map((poste) => (
                    <option key={poste.id} value={poste.id}>
                      {poste.name || poste.codigoPoste || poste.id}
                    </option>
                  ))}
                </datalist>
              </div>
            </>
          )}
        </div>

        {error && <p className={styles.errorMessage}>{error}</p>}

        <div className={styles.actionButtons}>
          {markerType === 'dispositivo' && (
            <button
              onClick={handleAtribuir}
              disabled={loading}
              className={styles.actionButton}
            >
              {loading ? 'Atribuindo...' : 'Atribuir Poste'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

AtrelarModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  markerData: PropTypes.object.isRequired,
  atribuirPoste: PropTypes.func.isRequired,
  removerDispositivo: PropTypes.func.isRequired,
  postesDropdown: PropTypes.array.isRequired,
  markerType: PropTypes.string.isRequired,
  error: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

AtrelarModal.defaultProps = {
  error: null,
};

export default AtrelarModal;
